import React, { Component } from 'react'
import axios from 'axios'
import EnvConfiguration from './Environment/EnvConfiguration';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'
import GetBalance from './GetBalance';
import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import i18next from 'i18next';
import { Spinner } from './Component/Spinner/spinner';
import { userLogin } from './actions/actions';

class AccountData extends Component {

  constructor(props) {
    super(props)
    this.state = {
      responseOperative: [],
      actualSavingTarget: '',
      isloading: true,
      data: [],
      nickName: '',
      accCallList: [],
      getBalDetails: [],
      enterAccNo: '',
      accNo: ''
    }
    this.envConfig = new EnvConfiguration();
  }

  async componentDidMount() {
    this.setState({
      data: this.props.reducer.accCallList,
      isloading: false
    })
  }

  viewData = (AcctNo, ModuleType) => {
    const singleData = this.props.reducer.accCallList.filter((data) => data.AcctNo === AcctNo);
    this.props.dispatch(userLogin("accDetailRed", singleData))
  }

  gettingAccountsDetails = (e) => {
    if (this.state.data != null) {
      return (
        this.props.reducer.accCallList.map((data, index) => {
          const AcctNo = data.AcctNo
          const ModuleType = data.ModuleType
          return (
            <tr className="tblrow" key={index}>
              <td>
                <div className="custom-control custom-switch">
                  <GetBalance acc={data.AcctNo} Mtype={data.ModuleType} />
                </div>
              </td>
              <td>
                <Link to="/BalanceStatment" value="view" onClick={() => this.viewData(AcctNo, ModuleType)}>{i18next.t('view')}</Link>
              </td>
              <td>{data.AcctNo}</td>
              <td>{data.Acctlabel}</td>
            </tr>

          )
        })

      )
    }
  }

  render() {

    const defaultLabelStyle = {
      fill: '#FFFFFF',
      fontSize: '0.9rem',
    };

    if (this.state.isloading === true) {
      return <Spinner />;
    }
    else {
      return (
        <div className="animated fadeIn">
          {/* <SetTheme /> */}
          {this.state.actualSavingTarget ?
            <div className="head-box card-header h5 box1 ">
              <div className="row m-1">
                <div className="col-lg-8 col-md-8 col-sm-8 p-0">
                  <div className="inline headerBox" style={{ display: "table-row" }}>
                    <i className="fas fa-book-open mr-4"></i>SAVING TARGET
                  </div>
                </div>

              </div>
            </div>

            : null}

          {this.state.responseOperative ? <div className="full_section_dash">
            <div className="h5 mb-2 card-header"> <FontAwesomeIcon icon={faChartLine} className="mr-2" />
              {i18next.t('dshb')}
            </div>

            <table className="table  table-bordered  table-md table-striped mini-table table-responsive table-hover ">
              <thead className="table-heading ">
                <tr>
                  <th style={{ width: "25%" }}>{i18next.t('ab')}</th>
                  <th style={{ width: "25%" }}>{i18next.t('ad')}</th>
                  <th style={{ width: "25%" }}>{i18next.t('an')}</th>
                  <th style={{ width: "25%" }}>{i18next.t('aty')}</th>
                </tr>
              </thead>
              <tbody>
                {this.gettingAccountsDetails()}
              </tbody>
            </table>
          </div> : null}
        </div>
      )
    }
  }
}

function mapStateToProps(state) {
  return state
}

export default withRouter(connect(mapStateToProps)(AccountData))
