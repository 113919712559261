import React, { Component } from 'react';
import './layout.css';
import Header_Common from '../Header_Common';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import i18next from 'i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

export class Faq extends Component {
    render() {
        return (
            <div className="">
                <Header_Common />
                <div className="border new_section m-2 0 py-4   ">
                    <p>{i18next.t('Faq')}</p>
                    <div className="accordion-section">
                        <div id="accordionFaq">
                            <div className="card">
                                <div className="card-header" id="headingOneFaq">
                                    <h5 className="mb-0">
                                        <button className="new_btn_style" data-toggle="collapse" data-target="#collapseOneFaq" aria-expanded="true" aria-controls="collapseOneFaq">
                                            {i18next.t('question1')}
                                        </button>
                                    </h5>
                                </div>

                                <div id="collapseOneFaq" className="collapse show" aria-labelledby="headingOneFaq" data-parent="#accordionFaq">
                                    <div className="card-body">
                                        {i18next.t('ans1')}
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-header" id="headingThreeFaq">
                                    <h5 className="mb-0">
                                        <button className="new_btn_style collapsed" data-toggle="collapse" data-target="#collapseThreeFaq" aria-expanded="false" aria-controls="collapseThreeFaq">
                                            {i18next.t('question2')}
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseThreeFaq" className="collapse" aria-labelledby="headingThreeFaq" data-parent="#accordionFaq">
                                    <div className="card-body">
                                        {i18next.t('ans2')}
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-header" id="headingFourFaq">
                                    <h5 className="mb-0">
                                        <button className="new_btn_style collapsed" data-toggle="collapse" data-target="#collapseFourFaq" aria-expanded="false" aria-controls="collapseFourFaq">
                                            {i18next.t('question3')}
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseFourFaq" className="collapse" aria-labelledby="headingFourFaq" data-parent="#accordionFaq">
                                    <div className="card-body">
                                        {i18next.t('ans3')}
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-header" id="headingFiveFaq">
                                    <h5 className="mb-0">
                                        <button className="new_btn_style collapsed" data-toggle="collapse" data-target="#collapseFiveFaq" aria-expanded="false" aria-controls="collapseFiveFaq">
                                            {i18next.t('question4')}
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseFiveFaq" className="collapse" aria-labelledby="headingFiveFaq" data-parent="#accordionFaq">
                                    <div className="card-body">
                                        {i18next.t('ans4')}
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-header" id="headingSixFaq">
                                    <h5 className="mb-0">
                                        <button className="new_btn_style collapsed" data-toggle="collapse" data-target="#collapseSixFaq" aria-expanded="false" aria-controls="collapseSixFaq">
                                            {i18next.t('question5')}
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseSixFaq" className="collapse" aria-labelledby="headingSixFaq" data-parent="#accordionFaq">
                                    <div className="card-body">
                                        {i18next.t('ans5')}
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-header" id="headingSevenFaq">
                                    <h5 className="mb-0">
                                        <button className="new_btn_style collapsed" data-toggle="collapse" data-target="#collapseSevenFaq" aria-expanded="false" aria-controls="collapseSevenFaq">
                                            {i18next.t('question6')}
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseSevenFaq" className="collapse" aria-labelledby="headingSevenFaq" data-parent="#accordionFaq">
                                    <div className="card-body">
                                        {i18next.t('ans6')}
                                    </div>
                                </div>
                            </div>

                            {/* <div className="card">
                                <div className="card-header" id="headingEightFaq">
                                    <h5 className="mb-0">
                                        <button className="new_btn_style collapsed" data-toggle="collapse" data-target="#collapseEightFaq" aria-expanded="false" aria-controls="collapseEightFaq">
                                            {i18next.t('question8')}
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseEightFaq" className="collapse" aria-labelledby="headingEightFaq" data-parent="#accordionFaq">
                                    <div className="card-body">
                                        {i18next.t('ans8')}
                                    </div>
                                </div>
                            </div> */}

                            {/* <div className="card">
                                <div className="card-header" id="headingNineFaq">
                                    <h5 className="mb-0">
                                        <button className="new_btn_style collapsed" data-toggle="collapse" data-target="#collapseNineFaq" aria-expanded="false" aria-controls="collapseNineFaq">
                                            {i18next.t('question9')}
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseNineFaq" className="collapse" aria-labelledby="headingNineFaq" data-parent="#accordionFaq">
                                    <div className="card-body">
                                        {i18next.t('ans9')}
                                    </div>
                                </div>
                            </div> */}

                        </div>
                    </div>
                    {/* <div className="btns p-0 ">
                        <Link to="/Dashboard"> <input type="button" className="btn clickbtn " value={i18next.t('back')} />  </Link>
                    </div> */}
                </div>
            </div>
        );
    }
}

// export default Faq;
function mapStateToProps(state) {
    return state
}

export default withRouter(connect(mapStateToProps)(Faq))