import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect, Link } from 'react-router-dom';
import './newStyle.scss';
import Header_Common from '../Component/Header_Common';
import { userLogin, publicIp } from '../actions/actions';
import i18next from 'i18next';
import ImageLoader from '../Loader/Loader';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert';
import { trackPromise } from 'react-promise-tracker';
import SessionAPIReq from '../utils/SessionAPIReq';
import LoginBg from '../images/login-bg.png';
import circleLogo from '../images/circle-logo.png';
import Stepper from 'react-stepper-horizontal';
import Globe from '../ChangeLang/Globe';
import * as Validator from '../Validator/Validator';
import { ToastContainer } from "react-toastify";

class userReg extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      OldPin: '',
      NewPin: '',
      ConNewPin: '',
      dataError: {
        OldPin: '',
        NewPin: '',
        ConNewPin: '',
      },
    }
    this.oldpin = React.createRef();
    this.newpin = React.createRef();
    this.conpin = React.createRef();
  }
  handleAll = (e) => {
    var specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (e.target.name === "OldPin" || "NewPin" || "ConNewPin") {
      if (!specialChars.test(e.nativeEvent.data) && (!isNaN(parseInt(e.nativeEvent.data)) || e.nativeEvent.data === null)) {
        this.setState({
          [e.target.name]: e.target.value
        })
      }
      else {
        this.setState({
          [e.nativeEvent.data]: ""
        })
      }
    }
    else {
      this.setState({
        [e.target.name]: e.target.value,
      })
    }
  }

  // api for change M-pin
  changeMPin = async (e) => {
    e.preventDefault();
    Validator.add(this.oldpin, i18next.t('loginRequired'))
    Validator.add(this.newpin, i18next.t('newLoginRed'))
    Validator.add(this.conpin, i18next.t('confirmLoginReq'))
    Validator.vision()
    let asd = Validator.errorFoc()
    if (asd) {
      if (this.state.NewPin === this.state.ConNewPin) {
        try {
          const SessionData = {
            MobileNo: this.props.reducer.mobileNo,
            ChannelId: this.props.reducer.channelId,
            Cuid: this.props.reducer.bankCode
          }
          const data = {
            MobileNumber: this.props.reducer.mobileNo,
            BankCode: this.props.reducer.bankCode,
            OldPin: this.state.OldPin,
            NewPin: this.state.ConNewPin,
            ChannelId: this.props.reducer.channelId,
          };

          const APIurl = process.env.REACT_APP_DefaultUrl + 'MChangePin?token=TOK';
          ;
          var returnRegData = await trackPromise(SessionAPIReq(JSON.stringify(SessionData), JSON.stringify(data), APIurl));
          if (returnRegData.ResponseCode === "000") {
            this.props.dispatch(userLogin("Stepper", (3)));
            this.props.history.push('/Lurp')
          }
          else if (returnRegData.ResponseCode === "100") {
            swal(returnRegData.Response)
            this.props.history.push('/')
          }
          else {
            swal(returnRegData.Response)
          }
        }
        catch (e) {
          swal({ icon: "error", text: i18next.t('failure'), button: i18next.t("OK") });
        }
      }
      else {
        swal({ icon: "error", text: i18next.t('matchError'), button: i18next.t("OK") });
      }
    }
    // else {
    //   swal(i18next.t('passLengthError'))
    // }
  }

  //validation 
  validate = (propname) => {
    let UpdatedDataError = { ...this.state.dataError };

    if (propname === "OldPin") {
      if ((this.state.OldPin === "") || (this.state.OldPin === null)) {
        UpdatedDataError["OldPin"] = <strong> {"*" + i18next.t('loginRequired')}</strong>;
      }
      else {
        UpdatedDataError["OldPin"] = "";
      }
    }

    else if (propname === "NewPin") {
      if ((this.state.NewPin === "") || (this.state.NewPin === null)) {
        UpdatedDataError["NewPin"] = <strong>{"*" + i18next.t('newLoginRed')}</strong>;
      }
      else {
        UpdatedDataError["NewPin"] = "";
      }
    }

    else if (propname === "ConNewPin") {
      if ((this.state.ConNewPin === "") || (this.state.ConNewPin === null)) {
        UpdatedDataError["ConNewPin"] = <strong>{"*" + i18next.t('confirmLoginReq')}</strong>;
      }
      else {
        UpdatedDataError["ConNewPin"] = "";
      }
    }
    this.setState({ dataError: UpdatedDataError });
  }

  // maxLength control
  maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(0, object.target.maxLength)
    }
  }

  render() {
    return (
      <>
        <div className="login-main">
          <img src={LoginBg} alt="test" className='img-fluid w-100'></img>
          <div className="wrapper-main row w-100 h-100">
            <div className="col -6">
              <div className="d-flex justify-content-center">
                <img src={circleLogo} alt="logo" className='login-logo-img' />
              </div>
            </div>
            <div className="col-6">
              <div className='mainStuff my-5'>
                <div className='d-flex justify-content-end '>
                  <Globe />
                </div>
                <div className="d-flex p-2 justify-content-center align-middle">
                  <div className="login-container w-100">
                    <div className="login-wrapper">
                      <form className="m_height" autoComplete="off">
                        <div className='form-group form1 heading'>
                          <h2>{i18next.t('clp')}</h2>
                        </div>
                        <Stepper steps={[{ title: '' }, { title: '' }, { title: '' }, { title: '' }]} activeStep={this.props.reducer.Stepper} />
                        <div className="s_gap">
                          <div className="form-group form1 py-2">
                            <label>{i18next.t('clps')}</label>
                            <div className="data_no ">
                              <input type="password" name="OldPin" autoComplete="off" ref={this.oldpin} onChange={this.handleAll}
                                placeholder={i18next.t('clps')} className="form-control" maxLength="4" value={this.state.OldPin}
                                onBlur={(propname) => this.validate("OldPin")}

                              />
                              {/* <span style={{ color: "red", float: "left " }}>{this.state.dataError.OldPin}</span> */}
                            </div>
                          </div>
                        </div>


                        <div className="s_gap">
                          <div className="form-group form1 py-2">
                            <label>{i18next.t('nlp')}</label>
                            <div className="data_no ">
                              <input type="password" name="NewPin" autoComplete="off" ref={this.newpin} onChange={this.handleAll}
                                placeholder={i18next.t('nlp')} className="form-control" maxLength="4" value={this.state.NewPin}
                                onBlur={(propname) => this.validate("NewPin")}

                              />
                              {/* <span style={{ color: "red", float: "left " }}>{this.state.dataError.NewPin}</span> */}
                            </div>
                          </div>
                        </div>

                        <div className="s_gap">
                          <div className="form-group form1 py-2">
                            <label>{i18next.t('colps')}</label>
                            <div className="data_no ">
                              <input type="password" name="ConNewPin" autoComplete="off" ref={this.conpin} onChange={this.handleAll}
                                placeholder={i18next.t('colps')} className="form-control" maxLength="4" value={this.state.ConNewPin}
                                onBlur={(propname) => this.validate("ConNewPin")}

                              />
                              {/* <span style={{ color: "red", float: "left " }}>{this.state.dataError.ConNewPin}</span> */}
                            </div>
                          </div>
                        </div>

                        <div className="s_gap">
                          {this.state.isloading === true ?
                            <ImageLoader />
                            : <input type="button" onClick={this.changeMPin} className="btn log_btn" value={i18next.t('sb')} />
                          }
                          {/* <input type="button" onClick={this.changeMPin} className="btn log_btn" value={i18next.t('sb')}/> */}
                        </div>

                      </form>
                      <div className="form-group form1 pb-4 last-anchor">
                        <Link to="/Login" className="a_line" style={{ fontWeight: "500" }} >{i18next.t('arl')}</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>


          </div>
        </div>
        <ToastContainer />

      </>

    )

  }
}



// export default userReg; 
function mapStateToProps(state) {
  return state
}
export default withRouter(connect(mapStateToProps)(userReg))




























