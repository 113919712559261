import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import '../src/i18n';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from '../src/store';
import { Spinner } from './Component/Spinner/spinner';



ReactDOM.render(

    <Provider store={store}>
        <App />
        <Spinner />
    </Provider>, document.getElementById('root')

);

serviceWorker.unregister();
