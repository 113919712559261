import React, { useState } from 'react';
import { BrowserRouter as Router, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { useTranslation } from "react-i18next";

function Globe(props) {
  const { i18n } = useTranslation();
  let history = useHistory();
  const [language, setLanguage] = useState(i18n.language);
  const lang = useSelector((state) => state.reducer);

  const handleLangChange = (evt, option) => {


    evt.preventDefault();
    setLanguage(i18n.language);
    let asd = ""
    if (language === "es") {
      asd = "en"
    }
    else {
      asd = "es"
    }

    setLanguage(asd);
    i18n.changeLanguage(asd);
    window.location.href = "#Login"
  };

  return (
    <div>
      {
        props.menu === "Dashboard" ?
          <button type="button" className="btn btn-primary btn-sm lan-menu-button float-right" onClick={handleLangChange}>  {language === "es" ? "ENG" : "ESP"}</button>
          :
          <button className="fas fa-globe shawn-btn" onClick={handleLangChange}>  {language === "es" ? "ENG" : "ESP"}</button>
      }
    </div >
  )
}

const mapStateToProps = state => {
  return state
};

export default withRouter(connect(mapStateToProps)(Globe))