import { USER_LOGIN, PUBLIC_IP } from '../constant';

const initialState = {
    username: '',
    accountlist: [],
    languageChange: "en",
    ipAdd: '',
    welcomeUser: '',
    mobileNo: '',
    bankCode: '75004',
    channelId: 'INET',
    accCallList: [],
    getCooperativeList: null,
    moduleType: [],
    cuid: '75004',
    acctNo: '',
    customerNumber: '',
    payDataReducer: [],
    accDetailRed: '',

    BalRequestType: 'Bal',
    MiniRequestType: 'Mini',
    AstatementRequestType: 'Astatement',
    FundTransferRequestType: 'Fundtransfer',

    RegTransactionType: "100",
    BalTransactionType: "101",
    MiniTransactionType: "102",
    AstatementTransactionType: "103",

    Stepper: 0
}

export const boloReducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_LOGIN:
            state[action.key] = action.value
            return Object.assign({}, state)

        case PUBLIC_IP:
            state[action.key] = action.value
            return Object.assign({}, state)

        default:
            return state;
    }
}