import React, { Component } from 'react'
import axios from 'axios'
import { BrowserRouter as Router, Switch, Route, Redirect, Link } from 'react-router-dom'
import i18next from 'i18next';

export class MenuSearch extends Component {
  render() {
    return (
      <div className="my-1">
        <h4 className="menu_header">{i18next.t('Menu')}</h4>
      </div>
    );
  }
}
export default MenuSearch;