import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import EnvConfiguration from '../Environment/EnvConfiguration';
import i18next from 'i18next';
import swal from 'sweetalert';
import { Link } from 'react-router-dom';
import { trackPromise } from 'react-promise-tracker';
import SessionAPIReq from '../utils/SessionAPIReq';

export class Manual extends Component {
  constructor(props) {
    super(props);
    this.state = {
      BenPayeeName: '',
      BenPayeeAccno: '',
      BenModuleName: '',
      BenCedulaId: '',
      TMT: '',
      TNP: '',
      TNRK: '',
      RemAccNo: '',
      viewType: true,
      dataAccList: [],
      dataCuid: '',
      modTy: '',
      coCuid: '',
      RemModType: [],
      recursoView: false,
      getCode: '',
      ogRec: '',
      dsRec: '',
      transactionType: "111",
      currCode: "BOB",
      dataError: {
        RemAccNo: "",
        BenPayeeName: "",
        ogRec: "",
        dsRec: "",
        TMT: "",
        TNP: "",

      },
    }
    this.intrafundTransfer = React.createRef()
    this.envConfig = new EnvConfiguration();
  }
  componentDidMount = async (e) => {
    this.intrafundTransfer.current.focus()
    this.setState({
      dataAccList: this.props.reducer.accCallList,
      dataCuid: this.props.reducer.cuid,
    })
    { this.getMoney() }
  }

  // fund transfer view
  ftwithinView = () => (
    this.setState({
      viewType: true,
      RemAccNo: '',
      BenPayeeName: '',
      BenPayeeAccno: '',
      BenModuleName: '',
      BenCedulaId: '',
      TMT: '',
      TNP: '',
      TNRK: '',
      modTy: '',
      coCuid: '',
      ogRec: '',
      dsRec: '',
      transactionType: "111"
    })
  )

  // fund transfer other bank view
  ftOtherView = () => (
    this.setState({
      viewType: false,
      RemAccNo: '',
      BenPayeeName: '',
      BenPayeeAccno: '',
      BenModuleName: '',
      BenCedulaId: '',
      TMT: '',
      TNP: '',
      TNRK: '',
      modTy: '',
      coCuid: '',
      ogRec: '',
      dsRec: '',
      transactionType: "113"
    })
  )

  // handle Change
  allHandleChange = (e) => {
    if (e.target.name === "BenPayeeName") {
      if (isNaN(parseInt(e.nativeEvent.data))) {
        this.setState({
          [e.target.name]: e.target.value
        })
      }
      else {
        this.setState({
          [e.nativeEvent.data]: ""
        })
      }
    }

    else if (e.target.name === "BenCedulaId" || e.target.name === "BenPayeeAccno") {
      if (!isNaN(parseInt(e.nativeEvent.data)) || e.nativeEvent.data === null) {
        this.setState({
          [e.target.name]: e.target.value
        })
      }
      else {
        this.setState({
          [e.nativeEvent.data]: ""
        })
      }
    }
    else {
      this.setState({
        [e.target.name]: e.target.value

      })
    }
  }

  newAccMod = (e) => {
    let RemModType = '';
    RemModType = this.props.reducer.accCallList.filter((data) => data.AcctNo === e.target.value)[0].ModuleType;
    this.setState({
      [e.target.name]: e.target.value,
      RemModType: RemModType,
    })
  }
  // clear
  handleClearAll = () => {
    this.setState({
      recursoView: false,
      RemAccNo: '',
      BenPayeeName: '',
      BenPayeeAccno: '',
      BenModuleName: '',
      BenCedulaId: '',
      TMT: '',
      TNP: '',
      TNRK: '',
      modTy: '',
      coCuid: '',
      ogRec: '',
      dsRec: '',
      dataError: []
    })
  }
  // getting Acct no
  GetAccountNumber = (e) => {
    return (
      this.props.reducer.accCallList.filter((data) => data.ModuleType === "5").map((data, index) => {
        return (
          <option key={index} value={data.AcctNo} id="opt">{data.AcctNo}</option>
        )
      })
    )
  }

  // getting Acct Type bolivia
  GetCoopListBolivia = () => {
    if (this.props.reducer.getCooperativeList !== null) {
      if (this.props.reducer.getCooperativeList.GetCooperativeList.length > 0) {
        return (
          this.props.reducer.getCooperativeList.GetCooperativeList.filter((data) => data.Cuid === this.props.reducer.cuid)
            .map((data, index) => {

              return (
                <option key={index} value={data.Cuid} id="opt">{data.Cooperatives.toUpperCase()} </option>
              )
            })
        )
      }
    }
  }

  // getting Acct Type  other
  GetCoopListOther = () => {
    return (
      this.props.reducer.getCooperativeList.GetCooperativeList.filter((data) => data.Cuid !== this.props.reducer.cuid)
        .map((data, index) => {

          return (
            <option key={index} value={data.Cuid} id="opt">{data.Cooperatives.toUpperCase()} </option>
          )
        })
    )
  }

  // getting ModuleType
  GetModuleType = () => {
    return (
      this.props.reducer.moduleType.map((data, index) => {
        return (
          <option key={index} value={data.ModuleType} id="opt" >{data.ModuleName}</option>
        )
      })
    )
  }
  // moneylaundering API
  getMoney = async (e) => {
    let code = "7";
    if (this.state.currCode === "USD") {
      code = "6";
    }
    const SessionData = {
      MobileNo: this.props.reducer.mobileNo,
      ChannelId: this.props.reducer.channelId,
      Cuid: this.props.reducer.bankCode
    }

    const data = {
      LoginMobileno: this.props.reducer.mobileNo,
      LoginChannelId: this.props.reducer.channelId,
      LoginCuid: this.props.reducer.cuid,
      Code: code
    };

    const APIurl = process.env.REACT_APP_DefaultUrl + 'GetMoneyLaunderingAmount?token=TOK';

    var returnData = await trackPromise(SessionAPIReq(JSON.stringify(SessionData), JSON.stringify(data), APIurl));
    if (returnData.ResponseCode === "000") {
      const newCode = JSON.parse(returnData.Response)
      this.setState({
        getCode: newCode
      })
    }
    else if (returnData.ResponseCode === "100") {
      swal(returnData.ResponseMessage)
    }
  }

  // recurso handleEvent
  recursoHandleChange = (e) => {
    var specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

    let recursoType = false;
    let newRecurso = "";

    if (e.target.value > this.state.getCode) {
      recursoType = true;
    }
    else if (e.target.value < this.state.getCode) {
      newRecurso = ""
    }
    if (e.target.name === "TMT" || "TNP") {
      if (!specialChars.test(e.nativeEvent.data) && (!isNaN(parseInt(e.nativeEvent.data)) || e.nativeEvent.data === null)) {
        this.setState({
          [e.target.name]: e.target.value
        })
      }
      else {
        this.setState({
          [e.nativeEvent.data]: ""
        })
      }
    }
    else {
      this.setState({
        [e.target.name]: e.target.value,
        recursoView: recursoType,
        ogRec: newRecurso,
        dsRec: newRecurso,
      })
    }
  }

  // currency Code
  getCurrencyCode = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    }, () => { this.getMoney() });
  }

  // Api Manual
  continueOtherHandleChange = async (e) => {
    let UpdatedDataError = { ...this.state.dataError };
    let validation = 0;
    let Message = "";

    if ((this.state.RemAccNo === "") || (this.state.RemAccNo === null)) {
      UpdatedDataError.RemAccNo = "*" + i18next.t('acctReq');
      validation = validation + 1;
      Message = Message + validation + ". " + i18next.t('acctReq') + "\n";
    }
    if ((this.state.coCuid === "") || (this.state.coCuid === null)) {
      UpdatedDataError.coCuid = "*" + i18next.t('benReq');
      validation = validation + 1;
      Message = Message + validation + ". " + i18next.t('benReq') + "\n";
    }
    if ((this.state.BenPayeeAccno === "") || (this.state.BenPayeeAccno === null)) {
      UpdatedDataError.BenPayeeAccno = "*" + i18next.t('benNumber');
      validation = validation + 1;
      Message = Message + validation + ". " + i18next.t('benNumber') + "\n";
    }
    if ((this.state.modTy === "") || (this.state.modTy === null)) {
      UpdatedDataError.modTy = "*" + i18next.t('modType');
      validation = validation + 1;
      Message = Message + validation + ". " + i18next.t('modType') + "\n";
    }
    if ((this.state.TMT === "") || (this.state.TMT === null)) {
      UpdatedDataError.TMT = "*" + i18next.t('amtRequired');
      validation = validation + 1;
      Message = Message + validation + ". " + i18next.t('amtRequired') + "\n";
    }
    if ((this.state.TNP === "") || (this.state.TNP === null)) {
      UpdatedDataError.TNP = "*" + i18next.t('transRequired');
      validation = validation + 1;
      Message = Message + validation + ". " + i18next.t('transRequired') + "\n";
    }

    if (validation > 0) {
      this.setState({ dataError: UpdatedDataError });
      swal({ icon: "error", text: i18next.t('error') + " \n\n" + Message, button: i18next.t("ok") });
    }

    else {
      const SessionData = {
        MobileNo: this.props.reducer.mobileNo,
        ChannelId: this.props.reducer.channelId,
        Cuid: this.props.reducer.bankCode
      };

      let transactionType = 0;
      if (this.props.reducer.cuid === this.state.coCuid) {
        transactionType = '111'
      }
      else {
        transactionType = '113'
      }

      const newData = {
        MobileNumber: this.props.reducer.mobileNo,
        ChannelId: this.props.reducer.channelId,
        Cuid: this.props.reducer.cuid,
        TransactionType: transactionType,
        Stan: "0",
        DebitMobileNo: this.props.reducer.mobileNo,
        DebitChannelId: this.props.reducer.channelId,
        DebitCuid: this.props.reducer.cuid,
        DebitAccountNumber: this.state.RemAccNo,
        DebitModuleType: "5",
        DebitName: this.props.reducer.welcomeUser,
        DebitNationalId: this.props.reducer.customerNumber,
        CreditChannelId: this.props.reducer.channelId,
        CreditCuid: this.state.coCuid,
        CreditAccountNumber: this.state.BenPayeeAccno,
        CreditModuleType: this.state.modTy,
        CreditName: this.state.BenPayeeName,
        CreditNationalId: this.state.BenCedulaId,
        NoofEMIs: "1",
        PaymentType: this.props.reducer.channelId,
        Amount: this.state.TMT,
        Narration: this.state.TNRK,
        TID: this.props.reducer.ipAdd,
        OTP: this.state.TNP,
        TranCode: "1",
        Currencycode: this.state.currCode,
        OrigenRecurso: this.state.ogRec,
        DestinoRecurso: this.state.dsRec,
      }
      const urlData = {
        Id: 0,
        LogInMobileNumber: this.props.reducer.mobileNo,
        LogInChannelId: this.props.reducer.channelId,
        LogInCuid: this.props.reducer.cuid,
        RequestType: "Fundtransfer",
        RequestMessage: JSON.stringify(newData),
        ResponseMessage: "",

      };

      const APIurl = process.env.REACT_APP_DefaultUrl + 'ProcessMBRequest?token=TOK';
      var returnAccountDetails = await trackPromise(SessionAPIReq(JSON.stringify(SessionData), JSON.stringify(urlData), APIurl));

      var NewDetailsList = JSON.parse(returnAccountDetails.Response)

      if (returnAccountDetails.ResponseCode === "000") {
        // swal(NewDetailsList.TransactionDetail)
        swal({
          icon: "success",
          text: NewDetailsList.TransactionDetail,
          buton: "Ok"
        })
        this.setState({
          RemAccNo: '',
          BenPayeeName: '',
          BenPayeeAccno: '',
          BenModuleName: '',
          BenCedulaId: '',
          TMT: '',
          TNP: '',
          TNRK: '',
          modTy: '',
          coCuid: '',
          ogRec: '',
          dsRec: '',

        })
      }
      else if (returnAccountDetails.ResponseCode === "900" || returnAccountDetails.ResponseCode === "E01") {
        // swal(NewDetailsList.ErrorDescription)
        swal({
          icon: "error",
          text: NewDetailsList.ErrorDescription,
          buton: "Ok"
        })
      }
      else {
        // swal(NewDetailsList.TransactionDetail)
        swal({
          icon: "error",
          text: NewDetailsList.TransactionDetail,
          buton: "Ok"
        })
      }
    }
  }

  validate = (propname) => {
    var specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    let UpdatedDataError = { ...this.state.dataError };
    if (propname === "RemAccNo") {
      if ((this.state.RemAccNo === "") || (this.state.RemAccNo === null)) {
        UpdatedDataError["RemAccNo"] = <strong> {"*" + i18next.t('acctReq')}</strong>;
      }
      else {
        UpdatedDataError["RemAccNo"] = "";
      }
    }

    else if (propname === "coCuid") {
      if ((this.state.coCuid === "") || (this.state.coCuid === null)) {
        UpdatedDataError["coCuid"] = <strong>{"*" + i18next.t('benReq')}</strong>;
      }
      else {
        UpdatedDataError["coCuid"] = "";
      }
    }

    else if (propname === "BenPayeeAccno") {
      if ((this.state.BenPayeeAccno === "") || (this.state.BenPayeeAccno === null)) {
        UpdatedDataError["BenPayeeAccno"] = <strong>{"*" + i18next.t('benNumber')}</strong>;
      }
      else {
        UpdatedDataError["BenPayeeAccno"] = "";
      }
    }

    else if (propname === "modTy") {
      if ((this.state.modTy === "") || (this.state.modTy === null)) {
        UpdatedDataError["modTy"] = <strong>{"*" + i18next.t('modType')}</strong>;
      }
      else {
        UpdatedDataError["modTy"] = "";
      }
    }
    else if (propname === "TMT") {
      if ((this.state.TMT === "") || (this.state.TMT === null)) {
        UpdatedDataError["TMT"] = <strong>{"*" + i18next.t('amtRequired')}</strong>;
      }
      else if ((specialChars.test(this.state.TMT))) {
        UpdatedDataError["TMT"] = <strong>{"*" + i18next.t('amtinvalido')}</strong>;
      }
      else {
        UpdatedDataError["TMT"] = "";
      }
    }

    else if (propname === "TNP") {
      if ((this.state.TNP === "") || (this.state.TNP === null)) {
        UpdatedDataError["TNP"] = <strong>{"*" + i18next.t('transRequired')}</strong>;
      }
      else {
        UpdatedDataError["TNP"] = "";
      }
    }
    this.setState({ dataError: UpdatedDataError });
  }
  render() {
    return (
      <div className="container">
        <div className="h5 mb-2 card-header">
          <i className="fas fa-receipt mr-2"></i> {i18next.t('Manual')}
        </div>
        <div className="card-body col-lg-12 col-md-12 col-sm-12 col-12 border">
          <input ref={this.intrafundTransfer} type="button" value={i18next.t('intraft')} className="form-inline  btn text-light mybtndgn mr-4 mt-2 col-lg-6 col-md-6 col-sm-6 col-6 "
            onClick={this.ftwithinView} />
          <input type="button" value={i18next.t('interft')} className="form-inline  btn text-light mybtndgn mt-2 col-lg-6 col-md-6 col-sm-6 col-6"
            onClick={this.ftOtherView} />
        </div>
        <br />

        <div className="row ">
          <section className="form-data col-lg-12 col-md-12 col-sm-12 ">
            <div className="card">
              <form autoComplete="off" readonly onfocus="this.removeAttribute('readonly');">
                <div className="h5 mb-2 card-header ">
                  <i class="fa fa-exchange" aria-hidden="true"></i>
                  {this.state.viewType === true ? i18next.t('intraft') : i18next.t('interft')}
                </div>
                <div className="form-group row nb  ">
                  <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                    <div className="form-group row nb ">
                      <label class=" col-md-6 col-sm-6 col-6 col-form-label text-left mb-2" >{i18next.t('faccn')}:-</label>
                      <div className=" col-md-6 col-sm-6 col-6">
                        <div className="row ">
                          <div>
                            <select id="select" className="form-control selectwidth" name="RemAccNo" value={this.state.RemAccNo}
                              onChange={this.newAccMod}
                              onBlur={(propname) => this.validate("RemAccNo")} error={this.state.dataError.RemAccNo ? true : false}
                            >
                              <option value="select">--{i18next.t('sel')}--</option>
                              {this.GetAccountNumber()}
                            </select>
                            <span style={{ color: "red", float: "left " }}>{this.state.dataError.RemAccNo}</span>
                          </div>
                        </div>
                      </div>

                      <label class=" col-md-6 col-sm-6 col-6 col-form-label text-left mb-2">{i18next.t('bena')}</label>

                      <div className=" col-md-6 col-sm-6 col-6">
                        <div className="row ">
                          <div>
                            <input className="form-control selectwidth text-left" name="BenPayeeName" value={this.state.BenPayeeName}
                              onChange={this.allHandleChange} type="text" placeholder={i18next.t('bena')}
                              onBlur={(propname) => this.validate("BenPayeeName")} error={this.state.dataError.BenPayeeName ? true : false}
                            />
                            <span style={{ color: "red", float: "left " }}>{this.state.dataError.BenPayeeName}</span>
                          </div>
                        </div>
                      </div>

                      <label class=" col-md-6 col-sm-6 col-6 col-form-label text-left mb-2" >{i18next.t('bbn')}:-</label>
                      <div className=" col-md-6 col-sm-6 col-6">
                        <div className="row ">
                          <div>
                            <select id="select2" className="form-control selectwidth" name="coCuid" value={this.state.coCuid}
                              onChange={this.allHandleChange}
                              onBlur={(propname) => this.validate("coCuid")} error={this.state.dataError.coCuid ? true : false}
                            >
                              <option value="select">--{i18next.t('sel')}--</option>
                              {this.state.viewType ? this.GetCoopListBolivia() : this.GetCoopListOther()}
                            </select>
                            <span style={{ color: "red", float: "left " }}>{this.state.dataError.coCuid}</span>
                          </div>
                        </div>
                      </div>

                      <label class=" col-md-6 col-sm-6 col-6 col-form-label text-left mb-2">{i18next.t('becid')}:-</label>
                      <div className=" col-md-6 col-sm-6 col-6">
                        <div className="row ">
                          <div>
                            <input className="form-control selectwidth text-left" name="BenCedulaId" value={this.state.BenCedulaId} maxLength="7"
                              onChange={this.allHandleChange} type="text" placeholder={i18next.t('becid')}
                            />
                          </div>
                        </div>
                      </div>

                      <label class=" col-md-6 col-sm-6 col-6 col-form-label text-left mb-2">{i18next.t('bano')}:-</label>
                      <div className=" col-md-6 col-sm-6 col-6">
                        <div className="row ">
                          <div>
                            <input className="form-control selectwidth text-left" name="BenPayeeAccno" value={this.state.BenPayeeAccno} maxLength="15"
                              onChange={this.allHandleChange} type="text" placeholder={i18next.t('bano')}
                              onBlur={(propname) => this.validate("BenPayeeAccno")} error={this.state.dataError.BenPayeeAccno ? true : false}
                            />
                            <span style={{ color: "red", float: "left " }}>{this.state.dataError.BenPayeeAccno}</span>
                          </div>
                        </div>
                      </div>

                      <label class=" col-md-6 col-sm-6 col-6 col-form-label text-left mb-2">{i18next.t('bmt')}:-</label>
                      <div className=" col-md-6 col-sm-6 col-6">
                        <div className="row ">
                          <div>
                            <select id="select3" className=" form-control select selectwidth " name="modTy" value={this.state.modTy}
                              onChange={this.allHandleChange}
                              onBlur={(propname) => this.validate("modTy")} error={this.state.dataError.modTy ? true : false}
                            >
                              <option value="select">--{i18next.t('sel')}--</option>
                              {this.GetModuleType()}
                            </select>
                            <span style={{ color: "red", float: "left " }}>{this.state.dataError.modTy}</span>
                          </div>
                        </div>
                      </div>
                      {/*  currencyCode */}

                      <label class=" col-md-6 col-sm-6 col-6 col-form-label text-left mb-2" >{i18next.t('cc')}:-</label>
                      <div className=" col-md-6 col-sm-6 col-6">
                        <div className="row ">
                          <div>
                            <select id="select4" className="form-control selectwidth" name="currCode" value={this.state.currCode}
                              onChange={this.getCurrencyCode}>
                              {/* <option value="select">Select</option> */}
                              <option value="BOB">BOB</option>
                              <option value="USD">USD</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <label class=" col-md-6 col-sm-6 col-6 col-form-label text-left mb-2">{i18next.t('tramt')}:-</label>
                      <div className=" col-md-6 col-sm-6 col-6">
                        <div className="row ">
                          {this.state.currCode === "USD" ?
                            <div className="row">
                              <label class=" col-form-label text-left col-lg-2 col-md-2 col-sm-2 col-2"> $ </label>
                              <input className="form-control selectwidth text-left col-lg-10 col-md-10 col-sm-10 col-10" type="text" name="TMT" value={this.state.TMT}
                                onChange={this.recursoHandleChange} placeholder={i18next.t('tramt')} autoComplete="new-amount"
                                onBlur={(propname) => this.validate("TMT")} error={this.state.dataError.TMT ? true : false}
                              />
                              <span style={{ color: "red", float: "left " }}>{this.state.dataError.TMT}</span>
                            </div>

                            : <div className="row">
                              <input className="form-control selectwidth text-left col-lg-12 col-md-12 col-sm-12 col-12" type="text" name="TMT" value={this.state.TMT}
                                onChange={this.recursoHandleChange} placeholder={i18next.t('tramt')} autoComplete="new-amount"
                                onBlur={(propname) => this.validate("TMT")} error={this.state.dataError.TMT ? true : false}
                              />
                              <span style={{ color: "red", float: "left " }}>{this.state.dataError.TMT}</span>
                            </div>
                          }

                        </div>
                      </div>

                      {/* recurso */}
                      {this.state.recursoView ?
                        <div className="form-group row nb ">
                          <label className=" col-md-6 col-sm-6 col-6 col-form-label text-left mb-2">{i18next.t('ogr')}:-</label>
                          <div className=" col-md-6 col-sm-6 col-6">
                            <div className="row ">
                              <div>
                                <input className="form-control selectwidth text-left" type="text" name="ogRec" value={this.state.ogRec}
                                  onChange={this.allHandleChange} placeholder="Origin Recurso" autoComplete="new-amount" />
                              </div>
                            </div>
                          </div>

                          <label className=" col-md-6 col-sm-6 col-6 col-form-label text-left mb-2">{i18next.t('dsr')}:-</label>
                          <div className=" col-md-6 col-sm-6 col-6">
                            <div className="row ">
                              <div>
                                <input className="form-control selectwidth text-left" type="text" name="dsRec" value={this.state.dsRec}
                                  onChange={this.allHandleChange} placeholder="Destination Recurso" autoComplete="new-amount" />
                              </div>
                            </div>
                          </div>
                        </div>
                        : null
                      }

                      <label class=" col-md-6 col-sm-6 col-6 col-form-label text-left mb-2">{i18next.t('trpn')}:-</label>
                      <div className=" col-md-6 col-sm-6 col-6">
                        <div className="row ">
                          <div>
                            <input className="form-control selectwidth text-left" type="password" maxLength="4" name="TNP" value={this.state.TNP}
                              onChange={this.recursoHandleChange} placeholder={i18next.t('trpn')} autoComplete="new-password"
                              onBlur={(propname) => this.validate("TNP")} error={this.state.dataError.TNP ? true : false}
                            />
                            <span style={{ color: "red", float: "left " }}>{this.state.dataError.TNP}</span>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                  {/* <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                    <div className="form-group row nb ">
                      <label class=" col-md-2 col-sm-5 col-6 col-form-label text-left mb-2">{i18next.t('or')}</label>
                      <div class="col-lg-6 col-md-8  col-sm-6 col-6 tbldesign my-1 ">
                        {this.state.viewType ?
                          <Link to="/BoliviaBankPayee" className="btn add_new text-xs" >{i18next.t('addnew')}</Link>
                          :
                          <Link to="/OtherAddPayee" className="btn add_new text-xs" >{i18next.t('addnew')}</Link>}
                      </div>
                    </div>
                  </div> */}

                  <div className="col-md-12 col-sm-12 form-group  nb">
                    <div className="row">
                      <div class="input-group row">
                        <div class="input-group-prepend  col-md-3 col-sm-6 col-12 ">
                          <label class=" col-form-label text-left   mb-2">{i18next.t('trk')}:-</label>
                        </div>
                        <textarea class="form-control  col-md-6 col-sm-6 col-12  text-left size-xs" name="TNRK"
                          value={this.state.TNRK} onChange={this.allHandleChange} placeholder={i18next.t('trk')}
                          aria-label="With textarea">
                        </textarea>
                      </div>
                    </div>

                  </div>
                </div>

                <div className="card-body">
                  <input type="button" value={i18next.t('cntie')} className="btn clickbtn mr-2 " onClick={this.continueOtherHandleChange} />
                  <input type="button" value={i18next.t('clr')} onClick={this.handleClearAll} className="btn clickbtn ml-2" />
                </div>
              </form>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state
}
export default withRouter(connect(mapStateToProps)(Manual))