import React from 'react';
import axios from 'axios';
import {trackPromise} from 'react-promise-tracker';
import EncryptString from './Encrypt';
import DecryptString from './Decrypt';
import fetchHashToken from './HashToken';
import swal from 'sweetalert';

async function SessionAPIReq (SessionBody, ApiBody, URL){
    
    let token = fetchHashToken(SessionBody,"");
    const Sessionurl = process.env.REACT_APP_DefaultUrl + '/GenerateSession?token=TOK'+ token;
    let encryptedData = EncryptString(SessionBody,token);
    
    
    let SessionKey = "";
    let decryptedAPIData;
    let newFormatData;
    let responseData;
    await trackPromise (axios.post(Sessionurl,encryptedData,
    {
        headers:{
            'Content-Type':'text/plain'
        }
    }
    )).then(async response=>{
        
        let decryptedData = DecryptString(response.data,token)
        
        let decryptedJSON = JSON.parse(decryptedData);
        if (decryptedJSON.ResponseCode === "000")
        {
            let decryptedRes = JSON.parse(decryptedJSON.Response);
            SessionKey = decryptedRes.SessionKey;
            let apitoken = fetchHashToken(ApiBody,SessionKey);
            URL = URL + apitoken;
        
            let encryptedData = EncryptString(ApiBody,apitoken);
        
            await trackPromise (axios.post(URL,encryptedData,{
                headers:{
                    'Content-Type':'text/plain'
                }
            })).then(response=>{
                decryptedAPIData = DecryptString(response.data,apitoken)
                newFormatData = JSON.parse(decryptedAPIData);
                responseData = newFormatData.Response;
                // swal(responseData.ErrorDescription)
            })
        }
    });

    return newFormatData
}
export default SessionAPIReq;
