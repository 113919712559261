import React, { Component } from 'react';
import './withinoutside.css';
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUniversity } from "@fortawesome/free-solid-svg-icons";
import i18next from 'i18next';
import { connect } from 'react-redux'
import { BrowserRouter as Router, Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { trackPromise } from 'react-promise-tracker';
import swal from 'sweetalert';
import { userLogin } from '../actions/actions';
import SessionAPIReq from '../utils/SessionAPIReq';


// bolivia bank user 
export class WithinBank extends Component {
  constructor(props) {
    super(props)
    this.state = {
      displayPayee: [],
      data: [],
      nickName: '',
      acctNo: '',
      modTy: '',
      coop: '',

    }
  }

  componentDidMount = async (e) => {
    { this.displayHandleChange() }
  }

  toggleBox = () => {
    this.setState({ nickName: '' });
  };

  payList = (Name) => {
    const singleList = this.state.displayPayee.filter((data) => data.Nickname === Name);
    this.props.dispatch(userLogin("payDataReducer", singleList))
  }



  // Api for displaying added payee
  displayHandleChange = async (e) => {
    const SessionData = {
      MobileNo: this.props.reducer.mobileNo,
      ChannelId: this.props.reducer.channelId,
      Cuid: this.props.reducer.bankCode
    }
    // const url =  process.env.REACT_APP_DefaultUrl + '/Payee?token={token}';
    const data = {
      Nickname: "",
      AccountNo: "",
      ModuleType: "",
      Cuid: this.props.reducer.cuid,
      LoginMobileNo: this.props.reducer.mobileNo,
      loginCuid: this.props.reducer.cuid,
      Channelid: this.props.reducer.channelId,
      TransactionMode: "NA",
      CedulaId: "0",
      ProcessFlag: "4",
      Successflag: "",
    };
    const APIurl = process.env.REACT_APP_DefaultUrl + 'Payee?token=TOK';
    var returnData = await trackPromise(SessionAPIReq(JSON.stringify(SessionData), JSON.stringify(data), APIurl));
    if (returnData.ResponseCode === "000") {
      let addedData = JSON.parse(returnData.Response)
      var newData = addedData.Payees.filter((data) => data.Cuid === this.props.reducer.cuid)
      this.setState({ displayPayee: newData })
    }
    else if (returnData.ResponseCode === "100") {
      const addedData = JSON.parse(returnData.Response)

      swal(addedData.processflag)
    }
    else {
      swal(returnData.ResponseMessage)
    }

  }


  // api for deleting payee
  deleteHandleChange = async (name, accno, modname) => {

    swal(i18next.t('note'), {
      buttons: {
        yes: {
          text: i18next.t('yes'),
          value: "yes"
        },
        no: {
          text: i18next.t('no'),
          value: "no"
        }
      }
    }).then(async (value) => {
      if (value === "yes") {
        const SessionData = {
          MobileNo: this.props.reducer.mobileNo,
          ChannelId: this.props.reducer.channelId,
          Cuid: this.props.reducer.bankCode
        }
        // const url =  process.env.REACT_APP_DefaultUrl + '/Payee?token={token}';
        const data = {
          Nickname: name,
          AccountNo: accno,
          ModuleType: modname,
          Cuid: this.props.reducer.cuid,
          LoginMobileNo: this.props.reducer.mobileNo,
          loginCuid: this.props.reducer.cuid,
          Channelid: this.props.reducer.channelId,
          TransactionMode: "NA",
          CedulaId: "0",
          ProcessFlag: "3",
          Successflag: "",
        };

        const APIurl = process.env.REACT_APP_DefaultUrl + 'Payee?token=TOK';
        var returnData = await trackPromise(SessionAPIReq(JSON.stringify(SessionData), JSON.stringify(data), APIurl));
        if (returnData.ResponseCode === "000") {
          const payDelete = JSON.parse(returnData.Response)

          swal({
            icon: "success",
            text: payDelete.processflag,
            buton: "Ok"
          })

          this.displayHandleChange()
        }
        else if (returnData.ResponseCode === "100") {
          const payDelete = JSON.parse(returnData.Response)
          swal({
            icon: "error",
            text: payDelete.processflag,
            buton: "Ok"
          })
        }
        else {

          swal({
            icon: "error",
            text: returnData.ResponseMessage,
            buton: "Ok"
          })
        }

      }
      return false;
    });
  }

  // for displaying added payee
  gettingDetails = (e) => {

    if (this.state.data != null)

      return (this.state.displayPayee.map((data, index) => {
        const ModuleType = data.ModuleType;

        let modName = "Cuenta";
        if (this.props.reducer.moduleType.filter((x) => x.ModuleType === data.ModuleType).length > 0) {
          modName = this.props.reducer.moduleType.filter((x) => x.ModuleType === data.ModuleType)[0].ModuleName;
        }

        const Name = data.Nickname
        const Account_No = data.AccountNo
        const Action = ""
        const Pay = ""

        return (
          <tr className="tblrow" key={index}>
            <td>{modName}</td>
            <td>{Name}</td>
            <td>{Account_No}</td>
            <td>{Action}
              <ul className="pay_manage">
                <li>
                  <FontAwesomeIcon icon={faEye} onClick={() => this.setdataonclick(data.Nickname)} />
                </li>
                <li>
                  <button type="button" class="btn btn_modal" id="deleteData" onClick={() => this.deleteHandleChange(Name, Account_No, ModuleType)}  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </li>
              </ul>
            </td>
            <td>{Pay}
              <Link to="/FundTransUSP" className="btn inside_yellow ml-1" value="Pay" onClick={() => this.payList(Name)} > {i18next.t('pay')}
              </Link>
            </td>

          </tr>
        )
      })
      )
  }
  setdataonclick = (name) => {
    this.setState({ nickName: name });
  }

  // for viewing info
  displayDataHandle = () => {

    const newName = this.state.displayPayee.filter((data) => data.Nickname === this.state.nickName);
    return (
      <div className=" form-data ">
        <div className="row form-group border">
          <div className="col-md-6 col-sm-12 text-left border-right">
            <div className="row">
              <label className="col-lg-6 col-md-6  col-sm-6 col-6 font-weight-bold my-1 text-left">{i18next.t('accno')}</label>
              <div className="col-lg-6 col-md-6  col-sm-6 col-6  tbldesign my-1 text-left">{newName[0].AccountNo}</div>
            </div>

            <div className="row">
              <label className="col-lg-6 col-md-6  col-sm-6 col-6  font-weight-bold my-1 text-left">{i18next.t('name')}</label>
              <div className="col-lg-6 col-md-6  col-sm-6 col-6  tbldesign my-1 text-left">{newName[0].Nickname}</div>
            </div>

            {/* <div className="row">
                            <label className="col-lg-6 col-md-6  col-sm-6 col-6  font-weight-bold my-1 text-left">{i18next.t('channel')}</label>
                            <div className="col-lg-6 col-md-6  col-sm-6 col-6  tbldesign my-1 text-left">{newName[0].Channelid}</div>
                        </div> */}

            <div className="row">
              <label className="col-lg-6 col-md-6  col-sm-6 col-6  font-weight-bold my-1 text-left">{i18next.t('cuid')}</label>
              <div className="col-lg-6 col-md-6  col-sm-6 col-6  tbldesign my-1 text-left">{this.props.reducer.getCooperativeList.GetCooperativeList.filter((data) => data.Cuid === newName[0].Cuid)[0].Cooperatives}</div>
            </div>

            {/* <div className="row">
                            <label className="col-lg-6 col-md-6  col-sm-6 col-6  font-weight-bold my-1 text-left">{i18next.t('mob')}</label>
                            <div className="col-lg-6 col-md-6  col-sm-6 col-6  tbldesign my-1 text-left">{newName[0].LoginMobileNo}</div>
                        </div> */}
          </div>

          <div className="col-md-6 col-sm-12">
            <div className="row">
              <label className="col-lg-6 col-md-6  col-sm-6 col-6  font-weight-bold my-1 text-left">{i18next.t('aty')}</label>
              <div className="col-lg-6 col-md-6  col-sm-6 col-6  tbldesign my-1 text-left">{this.props.reducer.moduleType.filter((data) => data.ModuleType === newName[0].ModuleType)[0].ModuleName}</div>
            </div>

            <div className="row">
              <label className="col-lg-6 col-md-6  col-sm-6 col-6  font-weight-bold my-1 text-left">{i18next.t('CedulaId')}</label>
              <div className="col-lg-6 col-md-6  col-sm-6 col-6  tbldesign my-1 text-left">{newName[0].CedulaId}</div>
            </div>

            {/* <div className="row">
                            <label className="col-lg-6 col-md-6  col-sm-6 col-6  font-weight-bold my-1 text-left">{i18next.t('TransMode')}</label>
                            <div className="col-lg-6 col-md-6  col-sm-6 col-6  tbldesign my-1 text-left">{newName[0].TransactionMode}</div>
                        </div>
                        
                        <div className="row">
                            <label className="col-lg-6 col-md-6  col-sm-6 col-6  font-weight-bold my-1 text-left">{i18next.t('loginCuid')}</label>
                            <div className="col-lg-6 col-md-6  col-sm-6 col-6  tbldesign my-1 text-left">{newName[0].loginCuid}</div>
                        </div> */}
          </div>
        </div>
        <div className="btns  mt-2 mb-2">
          <input type="button" className="btn clickbtn" onClick={this.toggleBox} value={i18next.t("exit")} />
        </div>
      </div>
    )
  }

  render(Nickname) {
    return (
      <div className="full_section_dash">
        <section className="form-data">

          <form>
            <div className="form-group  row nb ">
              <div className="h5 mb-2 card-header"><FontAwesomeIcon icon={faUniversity} className="mr-2" />
                {i18next.t('within')}
              </div>
            </div>
          </form>

        </section>
        <table className="table  table-bordered  table-md table-striped mini-table table-responsive table-hover ">
          <thead className="table-heading ">
            <tr>
              <th style={{ width: "20%" }}>{i18next.t('modName')}</th>
              <th style={{ width: "20%" }}>{i18next.t('nickName')}</th>
              <th style={{ width: "20%" }}>{i18next.t('accno')}</th>
              <th style={{ width: "20%" }}>{i18next.t('action')}</th>
              <th style={{ width: "20%" }}>{i18next.t('transfer')}</th>
            </tr>
          </thead>
          <tbody>
            {this.gettingDetails()}
          </tbody>
        </table>
        <div className="btns  mt-2 mb-2">
          {/* <Link to="/ManagePayee"> <input type="button" className="btn clickbtn "  value={i18next.t('back')}/>  </Link> */}
          <Link to="/ManagePayee" className="btn clickbtn  "> {i18next.t('back')} </Link>

        </div>

        <div className=" acc-dtls">
          {(this.state.nickName === "") ? null : this.displayDataHandle()}
        </div>
      </div>
    );
  };
}

function mapStateToProps(state) {
  return state
}
export default withRouter(connect(mapStateToProps)(WithinBank))