import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect, Link } from 'react-router-dom';
import './newStyle.scss';
import Header_Common from '../Component/Header_Common';
import i18next from 'i18next';
import ImageLoader from '../Loader/Loader';
import { connect } from 'react-redux';
import { userLogin } from '../actions/actions';
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert';
import { trackPromise } from 'react-promise-tracker';
import SessionAPIReq from '../utils/SessionAPIReq';
import Globe from '../ChangeLang/Globe';
import LoginBg from '../images/login-bg.png';
import circleLogo from '../images/circle-logo.png';
import Stepper from 'react-stepper-horizontal';
import Swal from 'sweetalert2';
import * as Validator from '../Validator/Validator';
import { ToastContainer } from "react-toastify";

class LastUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      OldTpin: '',
      NewTpin: '',
      ConTpin: '',
      dataError: {
        OldTpin: '',
        NewTpin: '',
        ConTpin: '',
      },
    }
    this.oldTpin = React.createRef()
    this.newTpin = React.createRef()
    this.conTpin = React.createRef()
  }
  handleAll = (e) => {
    var specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (e.target.name === "OldTpin" || "NewTpin" || "ConTpin") {
      if (!specialChars.test(e.nativeEvent.data) && (!isNaN(parseInt(e.nativeEvent.data)) || e.nativeEvent.data === null)) {
        this.setState({
          [e.target.name]: e.target.value
        })
      }
      else {
        this.setState({
          [e.nativeEvent.data]: ""
        })
      }
    }
    else {
      this.setState({
        [e.target.name]: e.target.value,
      })
    }
  }

  // api change T-pin

  changeTpin = async (e) => {
    e.preventDefault();
    Validator.add(this.oldTpin, i18next.t('transReq'))
    Validator.add(this.newTpin, i18next.t('newTransReq'))
    Validator.add(this.conTpin, i18next.t('confirmTransReq'))
    Validator.vision()
    let asd = Validator.errorFoc()
    if (asd) {
      if (this.state.NewTpin === this.state.ConTpin) {
        try {

          const SessionData = {
            MobileNo: this.props.reducer.mobileNo,
            ChannelId: this.props.reducer.channelId,
            Cuid: this.props.reducer.bankCode
          }
          // const url = process.env.REACT_APP_DefaultUrl + '/TChangePin?token={token}'; 
          const data = {
            MobileNumber: this.props.reducer.mobileNo,
            BankCode: this.props.reducer.bankCode,
            OldPin: this.state.OldTpin,
            NewPin: this.state.NewTpin,
            ChannelId: this.props.reducer.channelId,
          };

          const APIurl = process.env.REACT_APP_DefaultUrl + 'TChangePin?token=TOK';

          var returnRegData = await trackPromise(SessionAPIReq(JSON.stringify(SessionData), JSON.stringify(data), APIurl));
          if (returnRegData.ResponseCode === "000") {
            this.props.dispatch(userLogin("Stepper", (+1)));
            Swal.fire({
              icon: "Success",
              text: i18next.t("User Registered Successfully"),
              button: i18next.t("ok"),
              confirmButtonColor: "#2e5f8c",
              allowOutsideClick: false,
              allowEscapeKey: false
            }).then((result) => {

              if (result.isConfirmed) {
                this.props.history.push('/Login')
              }

            })

          }
          else {
            swal(returnRegData.ResponseMessage)
            this.props.history.push('/')

          }
        }
        catch (e) {
          swal({ text: i18next.t('failure'), button: i18next.t("OK") });
          this.props.history.push('/');
        }
      }
      else {
        swal({ icon: "error", text: i18next.t('failure'), button: i18next.t("OK") });
        this.props.history.push('/');
      }
    }
  }

  handleSubmit = async (e) => {
    this.props.dispatch(userLogin("accountlist", this.state.accountlist)) //accountlist
  }

  //validation 
  validate = (propname) => {

    let UpdatedDataError = { ...this.state.dataError };

    if (propname === "OldTpin") {
      if ((this.state.OldTpin === "") || (this.state.OldTpin === null)) {
        UpdatedDataError["OldTpin"] = <strong> {"*" + i18next.t('transReq')}</strong>;
      }
      else {
        UpdatedDataError["OldTpin"] = "";
      }
    }

    else if (propname === "NewTpin") {
      if ((this.state.NewTpin === "") || (this.state.NewTpin === null)) {
        UpdatedDataError["NewTpin"] = <strong>{"*" + i18next.t('newTransReq')}</strong>;
      }
      else {
        UpdatedDataError["NewTpin"] = "";
      }
    }

    else if (propname === "ConTpin") {
      if ((this.state.ConTpin === "") || (this.state.ConTpin === null)) {
        UpdatedDataError["ConTpin"] = <strong>{"*" + i18next.t('confirmTransReq')}</strong>;
      }
      else {
        UpdatedDataError["ConTpin"] = "";
      }
    }

    this.setState({ dataError: UpdatedDataError });
  }


  // maxLength control
  maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(0, object.target.maxLength)
    }
  }
  render() {
    return (
      <>
        <div className="login-main">
          <img src={LoginBg} alt="test" className='img-fluid w-100'></img>
          <div className="wrapper-main row w-100 h-100">
            <div className="col -6">
              <div className="d-flex justify-content-center">
                <img src={circleLogo} alt="logo" className='login-logo-img' />
              </div>
            </div>
            <div className="col-6">
              <div className='mainStuff my-5'>
                <div className='d-flex justify-content-end '>
                  <Globe />
                </div>
                <div className="d-flex p-2 justify-content-center align-middle">
                  <div className="login-container w-100">
                    <div className="login-wrapper">
                      <form className="m_height" autoComplete="off">
                        <div className="form-group form1 heading">
                          <h2>{i18next.t('cutp')}</h2>
                        </div>
                        <Stepper steps={[{ title: '' }, { title: '' }, { title: '' }, { title: '' }]} activeStep={this.props.reducer.Stepper} />
                        <div className="s_gap">
                          <div className="form-group form1 py-2">
                            <label>{i18next.t('cutp')}</label>
                            <div className="data_no ">
                              <input type="password" name="OldTpin" autoComplete="off" ref={this.oldTpin} onChange={this.handleAll}
                                placeholder={i18next.t('cutp')} className="form-control" maxLength="4" value={this.state.OldTpin}
                                onBlur={(propname) => this.validate("OldTpin")}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="s_gap">
                          <div className="form-group form1 py-2">
                            <label>{i18next.t('ntp')}</label>
                            <div className="data_no ">
                              <input type="password" name="NewTpin" autoComplete="off" ref={this.newTpin} onChange={this.handleAll}
                                placeholder={i18next.t('ntp')} className="form-control" maxLength="4" value={this.state.NewTpin}
                                onBlur={(propname) => this.validate("NewTpin")}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="s_gap">
                          <div className="form-group form1 py-2">
                            <label>{i18next.t('ctps')}</label>
                            <div className="data_no ">
                              <input type="password" name="ConTpin" autoComplete="off" ref={this.conTpin} onChange={this.handleAll}
                                placeholder={i18next.t('ctps')} className="form-control" maxLength="4" value={this.state.ConTpin}
                                onBlur={(propname) => this.validate("ConTpin")}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="s_gap">
                          {this.state.isloading === true ?
                            <ImageLoader />
                            : <input type="button" className="btn log_btn" value={i18next.t('sb')} onClick={this.changeTpin} />
                          }
                          {/* <input type="button" className="btn log_btn" value={i18next.t('sb')}  onClick={this.changeTpin}/> */}
                        </div>

                      </form>
                      <div className="form-group form1 pb-4 last-anchor">
                        <Link to="/Login" className="a_line" style={{ fontWeight: "500" }} >{i18next.t('arl')}</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>


          </div>
        </div>
        <ToastContainer />
      </>
    )
  }
}
function mapStateToProps(state) {
  return state
}

export default withRouter(connect(mapStateToProps)(LastUser))



























