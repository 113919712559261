import React, { Component } from 'react';
import './layout.css'
import Header_Common from '../Header_Common';
import i18next from 'i18next';


export class About extends Component {
	render() {
		return (
			<div className="">
				<Header_Common />
				{/* <Yellow_line /> */}
				<div className="border new_section m-2 0">

					<div className="about_section">
						<h3 style={{ color: "green", textAlign: "left", fontSize: "20px" }}>Visión</h3>
						<p style={{ textAlign: "justify" }}>Brindar soluciones de vivienda y financiamiento, mediante la captación de ahorros y
							colocación de préstamos, para mejorar la calidad de vida de nuestros clientes contribuyendo al
							desarrollo urbano, social y económico de la ciudad de Oruro.</p>

						<h3 style={{ color: "green", textAlign: "left", fontSize: "20px", }}>Misión</h3>
						<p style={{ textAlign: "justify" }}>Consolidar la innovación tecnológica digital permanente, brindando un servicio de calidad y
							calidez a nuestros clientes para la sostenibilidad económica financiera de El Progreso Entidad
							Financiera de Vivienda.</p>

						<h3 style={{ color: "green", textAlign: "left", fontSize: "20px" }}>PRINCIPIOS</h3>
						{/* <h3 style={{ color: "green", textAlign: "left", fontSize: "20px" }}>MEJORA CONTINUA</h3> */}

						<p style={{ textAlign: "justify" }}><b> MEJORA CONTINUA.</b> La mejora continua es un conjunto de acciones para obtener un servicio
							que cumpla con todos los requerimientos de nuestros clientes, además de brindar beneficios
							adicionales a la Entidad como es el aumento de la eficiencia a la disminución de costos. </p>

						{/* <h3 style={{ color: "green", textAlign: "left", fontSize: "20px" }}>PROMOVER CREATIVIDAD E INNOVACION.</h3> */}
						<p style={{ textAlign: "justify" }}><b>PROMOVER CREATIVIDAD E INNOVACION.</b> La creatividad se relaciona con el proceso de
							creación de una metodología o servicio, mientras que la innovación permite la implementación
							de dichos procesos en un modelo de negocio adaptable a los cambios a partir de la
							consecución del mismo.</p>

						{/* <h3 style={{ color: "green", textAlign: "left", fontSize: "20px" }}>PENSAMIENTO ESTRATÉGICO</h3> */}
						<p style={{ textAlign: "justify" }}><b>PENSAMIENTO ESTRATÉGICO.</b> Es una forma de tratar con un entorno que cambia
							constantemente. Responder a ése entorno en formas que permitan alcanzar las metas
							propuestas. Intentar, simultáneamente, cambiar dicho entorno para beneficio propio de la
							Entidad.</p>

						<h3 style={{ color: "green", textAlign: "left", fontSize: "20px", }}>VALORES</h3>
						<div type="circle" className='text-left !important'>
							<b style={{ textAlign: "left !important" }}>RESPONSABILIDAD SOCIAL EMPRESARIAL</b>
							<p style={{ textAlign: "justify" }}>
								- Compromiso e Integridad<br />
								- Confidencialidad<br />
								- Confidencialidad<br />
								- Diversidad inclusión y pertenencia<br />
								- Constancia y disciplina<br />
								- Evaluación autocrítica<br />
								- Honestidad y transparencia<br />
							</p>
						</div>
						<h3 style={{ color: "green", textAlign: "left", fontSize: "20px", }}>HORAS DE ATENCION</h3>
						<p style={{ textAlign: "justify" }}>
							<b>Oficina Central:</b> lunes a viernes de 8:00 a 15:00 horas, sábados de 8:30 a 12:30<br />
							<b>Dirección:</b> Calle La Plata No. 6177 esquina Sucre<br />
							<b>Agencia Pagador:</b> lunes a viernes de 8:00 a 15:00 horas, sábados de 8:30 a 12:30<br />
							<b>Dirección: </b>Calle Cochabamba esquina Potosí<br />
							<b>Punto Promocional:</b> lunes a viernes de 8:00 a 15:00 horas, sábados de 8:30 a 12:30<br />
							<b>Dirección:</b> 6 de octubre entre Villarroel y Oblitas<br />
						</p>
					</div>
				</div>
			</div >
		);
	}
}

export default About;