import React, { Component } from 'react';
import UserSideDash from './UserSideNav';
import Footer from '../Footer';
import './userDash.css';
import { BrowserRouter as Router, Switch, Redirect, Route } from 'react-router-dom';
import Urnp from '../../UserRegistration/UserRegistrationNpage';
import Lurp from '../../UserRegistration/LastPageUserReg'
import NewUserRegistration from '../../UserRegistration/NewUserRegistration';
export class userDash extends Component {
    render() {
        return (
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ padding: '0px' }}>
                <Switch>
                    <Route path="/NewUserRegister">
                        <NewUserRegistration />
                    </Route>
                    <Route path="/Urnp">
                        <Urnp />
                    </Route>
                    <Route path="/Lurp">
                        <Lurp />
                    </Route>
                </Switch>
                <Footer />

            </div>
        );
    }
}

export default userDash;