import React, { Component } from 'react';
import { BrowserRouter as Router, Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './newStyle.scss';
import swal from 'sweetalert';
import ImageLoader from '../Loader/Loader';
import i18next from 'i18next';
import { trackPromise } from 'react-promise-tracker';
import { userLogin } from '../actions/actions';
import SessionDirectAPIReq from '../utils/SessionDirectAPIReq';
import SessionAPIReq from '../utils/SessionAPIReq';
import Globe from '../ChangeLang/Globe';
import LoginBg from '../images/login-bg.png';
import circleLogo from '../images/circle-logo.png';
import Stepper from 'react-stepper-horizontal';
import Swal from 'sweetalert2';
import * as Validator from '../Validator/Validator'
import { ToastContainer } from "react-toastify";
class NewUserRegistation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stepper: 0,
      isLoading: false,
      isDataVisible: false,
      socioN: "",
      MobileNo: '',
      tokenRecieved: '',
      pinOtp: '',
      dataError: {
        MobileNo: "",
        socioN: "",
        pinOtp: '',
      },
      urnp: false
    };
    this.mobileNo = React.createRef();
    this.otp = React.createRef();
    this.sub = React.createRef();
  }
  componentDidMount() {
    this.mobileNo.current.focus();
  }
  toggleBox = () => {
    this.setState({
      isDataVisible: false,
    });
  };

  toggleBoxShow = () => {
    this.genToken();
  };

  // focusOnNumber() {
  //   this.NumberEnter.current.focus();
  // }
  // token api
  genToken = async (e) => {
    Validator.add(this.mobileNo, i18next.t('e_id'))
    Validator.vision()
    let asd = Validator.errorFoc()
    // let UpdatedDataError = { ...this.state.dataError };
    // let validation = 0;
    // let Message = "";

    // if ((this.state.MobileNo === "") || (this.state.MobileNo === null)) {

    //   UpdatedDataError.MobileNo = "*" + i18next.t('e_id');
    //   validation = validation + 1;
    //   Message = Message + validation + ". " + i18next.t('e_id') + "\n";
    // }

    if (asd) {
      this.setState({ isDataVisible: true });

      try {
        const SessionData = {
          MobileNo: this.state.MobileNo,
          ChannelId: this.props.reducer.channelId,
          Cuid: this.props.reducer.bankCode,
        }
        // const tokenUrl = process.env.REACT_APP_DefaultUrl + '/GenerateOTP?token={token}'; 
        const tokendata = {
          MobileNumber: this.state.MobileNo,
          Cuid: this.props.reducer.cuid,
          ChannelID: this.props.reducer.channelId,
          Stan: '0'
        };
        const accUrl = process.env.REACT_APP_DefaultUrl + '/GenerateOTP?token=TOK';
        var returnOTPDetails = await trackPromise(SessionAPIReq(JSON.stringify(SessionData), JSON.stringify(tokendata), accUrl));
        this.otp.current.focus();
        this.props.dispatch(userLogin("Stepper", (+1)));
        if (returnOTPDetails.ResponseCode === "000") {
          const tokenget = JSON.parse(returnOTPDetails.Response)
          this.setState({
            tokenRecieved: tokenget.data
          })
        }
        else if (returnOTPDetails.ResponseCode === "100") {
          swal(returnOTPDetails.Response)
        }
        else {
          swal(returnOTPDetails.Response)
        }
      }
      catch (e) {
        swal({ icon: "error", text: i18next.t('failure'), button: i18next.t("OK") });
      }
    }
  };

  //tokenRecieved ValidateRegister API 
  validateRegHandle = async (e) => {
    Validator.add(this.otp, i18next.t('otpRequired'))
    Validator.vision()
    let asd = Validator.errorFoc()
    // let UpdatedDataError = { ...this.state.dataError };
    // let validation = 0;
    // let Message = "";
    // if ((this.state.pinOtp === "") || (this.state.pinOtp === null)) {

    //   UpdatedDataError.pinOtp = "*" + i18next.t('otpRequired');
    //   validation = validation + 1;
    //   Message = Message + validation + ". " + i18next.t('otpRequired') + "\n";
    // }
    // if (validation > 0) {
    //   this.setState({ dataError: UpdatedDataError });
    //   swal({ icon: "error", text: i18next.t('error') + " \n\n" + Message, button: i18next.t("ok") });
    // }
    if (asd) {

      try {
        const valData = {
          MobileNo: this.state.MobileNo,
          BankCode: this.props.reducer.bankCode,
          ChannelId: this.props.reducer.channelId,
          DeviceID: this.props.reducer.ipAdd,
          Imei: this.props.reducer.ipAdd,
          Pin1: this.state.pinOtp,
          Version: ".0.1"
        };

        const accUrl = process.env.REACT_APP_DefaultUrl + '/ValidateandRegister?token=TOK';
        var returnAccountDetails = await trackPromise(SessionDirectAPIReq(JSON.stringify(valData), accUrl));
        if ((returnAccountDetails.ResponseCode === "000") || (returnAccountDetails.ResponseMessage === "Success")) {

          this.props.dispatch(userLogin("mobileNo", this.state.MobileNo));
          this.props.dispatch(userLogin("Stepper", (2)));
          this.setState({
            urnp: true
          })
          // this.props.history.push("/Urnp");
        }
        else if ((returnAccountDetails.ResponseCode === "100") || (returnAccountDetails.ResponseMessage === "Failure")) {
          this.props.dispatch(userLogin("mobileNo", this.state.MobileNo));
          Swal.fire({
            icon: "error",
            text: i18next.t("InvalidOTP"),
            button: i18next.t("ok"),
            confirmButtonColor: "#2e5f8c",
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then((result) => {

            if (result.isConfirmed) {
              this.setState({
                pinOtp: "",
                isDataVisible: false
              })
              this.mobileNo.current.focus()
            }

          })
          this.props.dispatch(userLogin("Stepper", (0)));
        }
        else {
          swal(returnAccountDetails.Response)
        }
      }
      catch (e) {
        swal({ icon: "error", text: i18next.t('failure'), button: i18next.t("OK") });
      }
    }
  }
  handleall = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  //  maxLength function
  maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(0, object.target.maxLength)
    }
  }

  validate = (propname) => {
    let UpdatedDataError = { ...this.state.dataError };
    if (propname === "MobileNo") {
      if ((this.state.MobileNo === "") || (this.state.MobileNo === null)) {
        UpdatedDataError["MobileNo"] = <strong> {"*" + i18next.t('e_id')}</strong>;
      }
      else {
        UpdatedDataError["MobileNo"] = "";
      }
    }

    // else if (propname === "socioN") {
    //   if ((this.state.socioN === "") || (this.state.socioN === null)) {
    //     UpdatedDataError["socioN"] = <strong>{"*" + i18next.t('scioNumber')}</strong>;
    //   }
    //   else {
    //     UpdatedDataError["socioN"] = "";
    //   }
    // }

    else if (propname === "pinOtp") {
      if ((this.state.pinOtp === "") || (this.state.pinOtp === null)) {
        UpdatedDataError["pinOtp"] = <strong>{"*" + i18next.t('otpRequired')}</strong>;
      }
      else {
        UpdatedDataError["pinOtp"] = "";
      }
    }
    this.setState({ dataError: UpdatedDataError });
  }

  render() {
    if (this.state.urnp === true) {
      return <Redirect to="/Urnp" />
    }
    return (
      <>
        <div className="login-main">
          <img src={LoginBg} alt="test" className='img-fluid w-100'></img>
          <div className="wrapper-main row w-100 h-100">
            <div className="col -6">
              <div className="d-flex justify-content-center">
                <img src={circleLogo} alt="logo" className='login-logo-img' />
              </div>
            </div>
            <div className="col-6">
              <div className='mainStuff my-5'>
                <div className='d-flex justify-content-end '>
                  <Globe />
                </div>
                <div className="d-flex p-2 justify-content-center align-middle">
                  <div className="login-container w-100">
                    <div className="login-wrapper">
                      <form className="m_height" autoComplete="off">
                        <div className="form-group form1 heading">
                          <h2>{i18next.t('nur')}</h2>
                        </div>
                        <Stepper steps={[{ title: '' }, { title: '' }, { title: '' }, { title: '' }]} activeStep={this.props.reducer.Stepper} />
                        <div className="form-group form1 py-2">
                          <label>{i18next.t('mob')}</label>
                          <div className="data_no py-1">
                            <input type="number" name="MobileNo" autoComplete="off" id="MobileNo" ref={this.mobileNo}
                              onChange={this.handleall} noValidate
                              placeholder={i18next.t('mob')} className="form-control"
                              maxLength="8" onInput={this.maxLengthCheck} value={this.state.MobileNo}
                              onBlur={(propname) => this.validate("MobileNo")}
                            />
                          </div>
                        </div>
                        <div>
                          {this.state.isDataVisible ?
                            (<div>
                              <input type="button" className="btn log_btn" onClick={this.toggleBox} value={i18next.t('rstp')} />
                              <div className="form-group form1 py-2">
                                <label className="py-2">{i18next.t('e_otp')}</label>
                                <div className="data_no">
                                  <input type="password" className="form-control " id="pinOtp" value={this.state.pinOtp} name="pinOtp" ref={this.otp}
                                    onChange={this.handleall} placeholder={i18next.t('e_otp')} maxLength="6"
                                    onBlur={(propname) => this.validate("pinOtp")}
                                  />
                                  {/* <span style={{ color: "red", float: "left " }}>{this.state.dataError.pinOtp}</span> */}
                                </div>
                                <div className="data_no py-1">
                                  <div>
                                    {this.state.isloading === true ?
                                      <ImageLoader />
                                      : <input type="button" className="btn log_btn" onClick={() => this.validateRegHandle()} value={i18next.t('sb')} ref={this.sub} />
                                    }
                                    {/* <input type="button" className="btn log_btn" onClick={() => this.validateRegHandle()} value={i18next.t('sb')} /> */}
                                  </div>
                                </div>
                              </div>
                            </div>)
                            : (<div>
                              <input type="button" className="btn log_btn" onClick={this.toggleBoxShow} value={i18next.t('gotp')} />
                            </div>)
                          }
                        </div>

                      </form>
                      <div className="form-group form1 pb-4 last-anchor">
                        <Link to="/Login" className="a_line" style={{ fontWeight: "500" }} >{i18next.t('arl')}</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>


          </div>
        </div>
        <ToastContainer />

      </>
    )
  }
}

function mapStateToProps(state) {
  return state
}
export default withRouter(connect(mapStateToProps)(NewUserRegistation))




























