import React, { Component } from 'react'
import PageNotFound from '../PageNotFound';

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false
    }
  }
  static getDerivedStateFromError(error) {
    return { hasError: true }
  }
  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
  }
  render() {
    if (this.state.hasError === true) {
      return (
        <PageNotFound />
      )
    }
    return this.props.children;
  }
}

export default ErrorBoundary