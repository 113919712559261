import React, { Component } from 'react'
import { BrowserRouter as Router, Link } from 'react-router-dom';
import i18next from 'i18next';

class ManagePayee extends Component {

    render() {

        return (
            <div className="container" id="page-content-wrapper">
                <div className="row">
                    <div className="h5 mb-2 card-header">
                        <i className="fas fa-receipt mr-2"></i>
                        {i18next.t('pmgt')}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-md-6 col-sm-12 col-12 p-0">
                        <div className="card ">
                            <div className="card-header  " >
                                {i18next.t('bbp')}
                                {/* Within Bank (Intra) */}
                            </div>

                            <div className="card-body">
                                <Link to="/BoliviaBankPayee"><input type="button" value={i18next.t('ape')} className="form-inline  btn text-light mybtndgn  mr-4 mt-2 col-lg-6 col-md-6 col-sm-6 col-6" /></Link>
                                <Link to="/WithinBank"><input type="button" value={i18next.t('viewpayee')} className="form-inline  btn text-light mybtndgn mt-2 col-lg-6 col-md-6 col-sm-6 col-6" /></Link>
                            </div>

                        </div>
                    </div>
                    {/* <br /> */}
                    {/* other bank payeee */}
                    <div className="col-md-6 col-md-6 col-sm-12 col-12 p-0">
                        <div className="card ">
                            <div className="card-header  " >
                                {i18next.t('obp')}
                            </div>
                            <div className="card-body">
                                <Link to="/OtherAddPayee"><input type="button" value={i18next.t('ape')} className="form-inline  btn text-light mybtndgn mr-4 mt-2 col-lg-6 col-md-6 col-sm-6 col-6" /></Link>
                                <Link to="/OutsideBank"><input type="button" value={i18next.t('viewpayee')} className="form-inline  btn text-light mybtndgn mt-2 col-lg-6 col-md-6 col-sm-6 col-6" /></Link>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ManagePayee;