import React, { Component } from 'react';
import './withinoutside.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import i18next from 'i18next';

export class WithinUsingPayee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      FrmAccNo: '',
      ToAccNo: '',
      GetAccNo: [],
      confirmToAcc: '',
      Amount: '',
      Remark: '',
      UserName: '',
      OTP: '',
      accNo: '',
      IFSC: '',
      ifscflag: false,
      IFSCList_Filled: [],
      Name: '',
      clearErr: '',
    }
  }

  onAccChange = (e) => {
    this.setState({
      accNo: e.target.value
    })
  }

  // getting Acct no
  GetAccountNumber = () => {
    return (
      this.props.reducer.accCallList.map((data, index) => {
        return (
          <option key={index} value={data.AcctNo} id="opt"> {data.AcctNo}</option>
        )
      })
    )
  }

  render() {
    return (
      <div className="row ">
        <section className="form-data  col-lg-12 col-md-12 col-sm-12 ">
          <div className="card">
            <form >
              <div className="h5 mb-2 card-header ">
                <i class="fa fa-exchange" aria-hidden="true"></i>
                {i18next.t('ftbb')}</div>
              <div className="form-group row nb  ">

                <div className="col-lg-6 col-md-6 col-sm-12 col-12">

                  <div className="form-group row nb ">

                    <label className=" col-md-6 col-sm-6 col-6 col-form-label text-left mb-2" >{i18next.t('faccn')}:-</label>
                    <div className=" col-md-6 col-sm-6 col-6">
                      <div className="row ">
                        <div>
                          <select id="select" className="form-control selectwidth" value={this.state.accNo} onChange={this.onAccChange}>
                            <option value="select">--{i18next.t('sel')}--</option>
                            {this.GetAccountNumber()}
                          </select>
                        </div>
                      </div>
                    </div>

                    <label className=" col-md-6 col-sm-6 col-6 col-form-label text-left mb-2">{i18next.t('sebn')}:-</label>
                    <div className=" col-md-6 col-sm-6 col-6">
                      <div className="row ">
                        <div>
                          <select id="select" className="form-control selectwidth" >
                            <option value="select">--{i18next.t('sel')}--</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <label className=" col-md-6 col-sm-6 col-6 col-form-label text-left mb-2">{i18next.t('pbn')}:-</label>
                    <div className=" col-md-6 col-sm-6 col-6">
                      <div className="row ">
                        <div>
                          <input className="form-control selectwidth text-left" type="text" placeholder="Autofill" />
                        </div>
                      </div>
                    </div>

                    <label className=" col-md-6 col-sm-6 col-6 col-form-label text-left mb-2">{i18next.t('tramt')}:-</label>
                    <div className=" col-md-6 col-sm-6 col-6">
                      <div className="row ">
                        <div>
                          <input className="form-control selectwidth text-left" type="text" placeholder={i18next.t('tramt')} />
                        </div>
                      </div>
                    </div>

                    <label className=" col-md-6 col-sm-6 col-6 col-form-label text-left mb-2">{i18next.t('trpn')}:-</label>
                    <div className=" col-md-6 col-sm-6 col-6">
                      <div className="row ">
                        <div>
                          <input className="form-control selectwidth text-left" type="text" placeholder={i18next.t('trpn')} />
                        </div>
                      </div>
                    </div>

                  </div>

                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                  <div className="form-group row nb ">
                    <label className=" col-md-6 col-sm-6 col-6 col-form-label text-left mb-2">{i18next.t('avbl')}:-</label>
                    <div className="col-lg-6 col-md-6  col-sm-6 col-6 tbldesign my-1 text-left">00000</div>

                  </div>
                  <div className="form-group row nb ">
                    <label className=" col-md-2 col-sm-5 col-6 col-form-label text-left mb-2">{i18next.t('or')}</label>
                    <div className="col-lg-3 col-md-3  col-sm-6 col-6 tbldesign my-1 ">
                      <input type="button" value="Add New Payee" className="btn add_new text-xs" />
                    </div>
                  </div>

                </div>
                <div className="col-md-12 col-sm-12 form-group  nb">
                  <div className="row">
                    <div className="input-group row">
                      <div className="input-group-prepend  col-md-3 col-sm-6 col-12 ">
                        <label className=" col-form-label text-left   mb-2">{i18next.t('trk')}:-</label>
                      </div>
                      <textarea className="form-control  col-md-6 col-sm-6 col-12  text-left size-xs" placeholder={i18next.t('trk')} aria-label="With textarea"></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <input type="button" value={i18next.t('cntie')} className="btn clickbtn mr-2  " />
                <input type="button" value={i18next.t('clr')} className="btn clickbtn ml-2" />
              </div>
            </form>
          </div>
        </section>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return state
}
export default withRouter(connect(mapStateToProps)(WithinUsingPayee))