import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import "jspdf-autotable";
import ImageLoader from '../Loader/Loader'
import moment from 'moment';
import { faReceipt } from "@fortawesome/free-solid-svg-icons";
import i18next from 'i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { trackPromise } from 'react-promise-tracker';
import swal from 'sweetalert';
import SessionAPIReq from '../utils/SessionAPIReq';

class AccountStatement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableresponse: [],
      fromDate: new Date(),
      ToDate: new Date(),
      data: [],
      accNo: '',
      showComponent: false,
      isloading: false,
      Amount: '',
      Emailld: '',
      cblBalance: '',
      isViewType: false,
      dataError: {
        accNo: "",
        fromDate: "",
        ToDate: '',
      },
    }
  }
  viewHandle = () => {
    this.setState({ isViewType: true })
  }
  Clear = () => {
    this.setState({
      isViewType: false,
      tableresponse: [], Amount: '', accNo: '', ToDate: '', fromDate: '', showComponent: false, isloading: '', data: []
    })
  }
  onHandleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  GetAccountNumber = (e) => {
    return (this.props.reducer.accCallList.map((data, index) => {
      return (
        <option key={index} value={data.AcctNo} id="opt">{data.AcctNo}</option>
      )
    })
    )
  }
  refreshPage = () => {
    window.location.href = ""
  }
  gettingDetails = (e) => {

    if (this.state.data != null) {
      var i = 0;
      return this.state.data.map((data, index) => {
        i++;
        const Srno = i;
        const Trans_Date = data.TransactionDate
        let Dep_Amt = "0";
        let With_Amt = "0";

        if (data.AmountFlag === "D") {
          With_Amt = data.Amount;
        }
        else {
          Dep_Amt = data.Amount;
        }
        const Bal = data.Balance; // + " " + ((data.BalanceFlag === "C") ? "CR" : "Dr");
        const CC = ((data.Currencycode === "BOB") ? "Bs" : ((data.Currencycode === "USD") ? "$us" : data.Currencycode));
        const Narration = data.Narration;

        return (
          <tr className="tblrow" key={index}>
            <td >{Srno}</td>
            <td>{Trans_Date}</td>
            <td>{Dep_Amt}</td>
            <td>{With_Amt}</td>
            <td>{Bal}</td>
            <td>{CC}</td>
            <td>{Narration}</td>
          </tr>
        )
      })
    }
  }

  renderOnSubmit = async () => {
    let UpdatedDataError = { ...this.state.dataError };
    let validation = 0;
    let Message = "";
    var today = new Date();
    var todayDate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    // this.dateValidation()

    var fromDate = moment(this.state.fromDate).format('YYYY-MM-DD'),
      ToDate = moment(this.state.ToDate).format('YYYY-MM-DD')
    if (moment(fromDate).isAfter(ToDate)) {
      swal(i18next.t('dateError'))
      return;
    }

    if (moment(fromDate).isAfter(todayDate) || moment(ToDate).isAfter(todayDate)) {
      swal(i18next.t('dateErr'))
      return;
    }

    if ((this.state.accNo === "") || (this.state.accNo === null)) {
      UpdatedDataError.accNo = "*" + i18next.t('acctReq');
      validation = validation + 1;
      Message = Message + validation + ". " + i18next.t('acctReq') + "\n";
    }

    if (validation > 0) {
      this.setState({ dataError: UpdatedDataError });
      swal({ icon: "error", text: i18next.t('error') + " \n\n" + Message, button: i18next.t("ok") });
    }
    else {

      const SessionData = {
        MobileNo: this.props.reducer.mobileNo,
        ChannelId: this.props.reducer.channelId,
        Cuid: this.props.reducer.bankCode
      };

      let Accdata = [];
      Accdata = this.props.reducer.accCallList.filter((data => data.AcctNo === this.state.accNo));

      if (Accdata.length > 0) {
        const InnerRequestJSON = {
          MobileNumber: this.props.reducer.mobileNo,
          Cuid: this.props.reducer.cuid,
          ChannelId: this.props.reducer.channelId,
          TransactionType: this.props.reducer.AstatementTransactionType,
          Stan: '0',
          AccountNumber: this.state.accNo,
          ModuleType: Accdata[0].ModuleType,
          FromDate: this.state.fromDate,
          ToDate: this.state.ToDate
        }

        const OuterRequestJSON = {
          Id: "0",
          LogInMobileNumber: this.props.reducer.mobileNo,
          LogInChannelId: this.props.reducer.channelId,
          LogInCuid: this.props.reducer.cuid,
          RequestType: this.props.reducer.AstatementRequestType,
          RequestMessage: JSON.stringify(InnerRequestJSON),
          ResponseMessage: "",
        }

        const APIurl = process.env.REACT_APP_DefaultUrl + 'ProcessMBRequest?token=TOK';
        var returnAccountDetails = await trackPromise(SessionAPIReq(JSON.stringify(SessionData), JSON.stringify(OuterRequestJSON), APIurl));
        if (returnAccountDetails.ResponseCode === "000") {
          const InnerJsonResponse = JSON.parse(returnAccountDetails.Response);
          this.setState({
            isViewType: true,
            data: InnerJsonResponse.DetailedTransactionList
          });
        }
        else {
          if (returnAccountDetails.ResponseCode === "E01") {
            const InnerJsonResponse = JSON.parse(returnAccountDetails.Response);
            if (InnerJsonResponse.SuccessFlag === "false") {
              // swal(InnerJsonResponse.ErrorDescription);
              swal({
                icon: "error",
                text: InnerJsonResponse.ErrorDescription,
                buton: "Ok"
              })
            }
            else {
              // swal(returnAccountDetails.Response);
              swal({
                icon: "error",
                text: returnAccountDetails.Response,
                buton: "Ok"
              })
            }
          }
          // else {
          //   swal(returnAccountDetails.Response)
          // }
        }
      }
      else {
        if (this.state.data.length > 0) {
          this.setState({ data: [] });
        }
      }
    }
  }

  validate = (propname) => {

    let UpdatedDataError = { ...this.state.dataError };

    if (propname === "accNo") {
      if ((this.state.accNo === "") || (this.state.accNo === null)) {
        UpdatedDataError["accNo"] = <strong> {"*" + i18next.t('acctReq')}</strong>;
      }
      else {
        UpdatedDataError["accNo"] = "";
      }
    }
    this.setState({ dataError: UpdatedDataError });
  }
  render() {

    if (this.state.isloading === true) {
      return <ImageLoader />;
    }
    else {
      return (
        <div>

          <div className="h5 mb-2 card-header"><FontAwesomeIcon icon={faReceipt} className="mr-2" />{i18next.t('acctd')}</div>
          <div className="addpayee form-data col-lg-12 col-md-12 col-sm-12 col-12 border">
            <form >
              <div className="form-group  row ">
                <label className=" col-md-4 col-sm-6 col-6 col-form-label text-left">{i18next.t('an')}:</label>
                <div className="col-md-8 col-sm-6 col-6">
                  <select id="select" className="form-control selectwidth" value={this.state.accNo} onChange={this.onHandleChange} name="accNo"
                    onBlur={(propname) => this.validate("accNo")} error={this.state.dataError.accNo ? (true).toString() : undefined}
                  >
                    <option value="select">--{i18next.t('sel')}--</option>
                    {this.GetAccountNumber()}
                  </select>
                  <span style={{ color: "red", float: "left " }}>{this.state.dataError.accNo}</span>
                </div>
              </div>

              <div className="form-group row ">
                <label className=" col-md-4 col-sm-6 col-6 col-form-label text-left">{i18next.t('fd')}</label>
                <div className=" col-md-4 col-sm-6 col-6">
                  <div className="row">
                    <input type="date" className="form-control selectwidth   text-left" id="txt_FromDate" name="fromDate"
                      onChange={this.onHandleChange}
                      value={this.state.fromDate}
                      max={moment().format("YYYY-MM-DD")} required />
                  </div>
                </div>
              </div>

              <div className="form-group row ">
                <label className="col-md-4 col-form-label col-sm-6 col-6 text-left">{i18next.t('tod')}</label>
                <div className=" col-md-4 col-sm-6 col-6">
                  <div className="row">
                    <input type="date" className="form-control selectwidth  text-left" id="txt_ToDate" name="ToDate"
                      onChange={this.onHandleChange}
                      value={this.state.ToDate} max={moment().format("YYYY-MM-DD")} required />
                  </div>

                </div>
              </div>

              <div className="mb-2 text-center col-lg-6 col-md-6 col-sm-12 col-12 ">
                <div className="form-group nb">
                  <input type="button" className="btn clickbtn col-md-4 col-5" id="btn_Submit" value={i18next.t('sb')} onClick={() => this.renderOnSubmit()} />
                  <input type="button" name="btn" className="btn clickbtn ml-2 col-md-4 col-5" value={i18next.t('clr')} onClick={this.Clear} />
                </div>
              </div>
            </form>
          </div>
          {this.state.isViewType ?
            <div>
              <table className="table  table-bordered  table-md table-striped mini-table table-responsive table-hover mt-2 ">
                <thead className="table-heading ">
                  <tr>
                    <th >{i18next.t('sr')}</th>
                    <th >{i18next.t('td')}</th>
                    <th >{i18next.t('damt')}</th>
                    <th >{i18next.t('wamt')}</th>
                    <th >{i18next.t('bal')}</th>
                    <th >{i18next.t('cc')}</th>
                    <th >{i18next.t('narrate')}</th>
                  </tr>
                </thead>
                <tbody>
                  {this.gettingDetails()}
                </tbody>
              </table>
            </div>
            : null}
        </div>
      )
    }
  }
}

function mapStateToProps(state) {
  return state
}

export default withRouter(connect(mapStateToProps)(AccountStatement))