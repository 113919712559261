import React, { Component } from 'react';
import i18next from 'i18next';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';

class Footer extends Component {
  openNav() {
    document.getElementById("mySidenav").style.width = "250px";
    document.getElementById("main").style.marginLeft = "250px";
  }

  closeNav() {
    document.getElementById("mySidenav").style.width = "0";
    document.getElementById("main").style.marginLeft = "0";
  }
  render() {
    return (
      <div className="footer myElFoooter">
        <footer className="mainfooter" role="contentinfo">
          <div className="container">
            <div className="row">
              <div className="col-md-12 copy">
                <p className="text-center">&copy;{i18next.t('copyright')} </p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    )
  }
}
// export default Footer;
function mapStateToProps(state) {
  return state
}

export default withRouter(connect(mapStateToProps)(Footer))