import React, { Component } from 'react'
import img from './images/robo.png'
class PageNotFound extends Component {
	render() {
		return (
			<div className="container">

				<div className="number">

					<div className="content">

						<div className="num">404
							<img src={img} className="robo" />
						</div>
						<div className="main-text">Oops! Page not found</div>
						<div className="text">Sorry, but the page you are looking for is not found</div>

					</div>

				</div>
			</div>
		)
	}
}
export default PageNotFound;