import React, { Component } from 'react';
import EnvConfiguration from '../Environment/EnvConfiguration';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'
import { faReceipt } from "@fortawesome/free-solid-svg-icons";
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import i18next from 'i18next';
import { trackPromise } from 'react-promise-tracker';
import swal from 'sweetalert';
import { userLogin } from '../actions/actions';
import SessionAPIReq from '../utils/SessionAPIReq';

class BalanceStatement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            GetAccNo: [],
            accCallList: [],
            isloading: false,
            flag: '',
            accNo: '',
            custNo: '',
            getBalDetails: null,
            ModuleType: '',
            isViewType: false
        }
        this.envConfig = new EnvConfiguration();
    }

    componentDidMount = async (e, AcctNo) => {
        if (this.props.reducer.accDetailRed.length > 0) {
            let newData = [];
            newData = this.props.reducer.accDetailRed;
            this.setState({
                accNo: newData[0].AcctNo,
                ModuleType: newData[0].ModuleType
            }, () => {
                this.props.dispatch(userLogin("accDetailRed", []));
                this.getBalance()
            })
        }
        else {

        }
    }

    // api for balance
    onAccChange = async (e) => {
        this.setState({
            accNo: e.target.value
        });
    }
    refreshPage = () => {
        window.location.href = "#Login"
    }

    getBalance = async () => {
        const SessionData = {
            MobileNo: this.props.reducer.mobileNo,
            ChannelId: this.props.reducer.channelId,
            Cuid: this.props.reducer.bankCode
        };

        // const bsUrl = process.env.REACT_APP_DefaultUrl + '/ProcessMBRequest?token={token}';
        const loneModule = this.props.reducer.accCallList.filter((data => data.AcctNo === this.state.accNo))
        if (loneModule.length > 0) {
            const a = {
                MobileNumber: this.props.reducer.mobileNo,
                CUID: this.props.reducer.cuid,
                ChannelId: this.props.reducer.channelId,
                TransactionType: this.props.reducer.BalTransactionType,
                AccountNumber: this.state.accNo,
                ModuleType: loneModule[0].ModuleType,
                STAN: "0",
            };

            const bsData = {
                Id: 0,
                LogInMobileNumber: this.props.reducer.mobileNo,
                LogInChannelId: this.props.reducer.channelId,
                LogInCuid: this.props.reducer.cuid,
                RequestType: this.props.reducer.BalRequestType,
                RequestMessage: JSON.stringify(a),
                ResponseMessage: "",

            };
            const APIurl = process.env.REACT_APP_DefaultUrl + 'ProcessMBRequest?token=TOK';
            var returnAccountDetails = await trackPromise(SessionAPIReq(JSON.stringify(SessionData), JSON.stringify(bsData), APIurl));

            if (returnAccountDetails.ResponseCode === "000") {
                const bsdetails = JSON.parse(returnAccountDetails.Response);
                this.setState({
                    isViewType: true,
                    getBalDetails: bsdetails
                });
            }
            else {
                this.setState({
                    getBalDetails: null
                });

                if (returnAccountDetails.ResponseCode === "E01") {
                    const InnerJsonResponse = JSON.parse(returnAccountDetails.Response);
                    if (InnerJsonResponse.SuccessFlag === "false") {
                        // swal(InnerJsonResponse.ErrorDescription);
                        swal({
                            icon: "error",
                            text: InnerJsonResponse.ErrorDescription,
                            buton: "Ok"
                        })
                    }
                    else {
                        // swal(returnAccountDetails.ErrorDescription);
                        swal({
                            icon: "error",
                            text: returnAccountDetails.ErrorDescription,
                            buton: "Ok"
                        })
                    }
                }

            }
        }
        else {
            if (this.state.getBalDetails != null) {
                this.setState({
                    getBalDetails: null
                });
            }
        }
    }
    GetAccountNumber = (e) => {
        return (this.props.reducer.accCallList.map((data, index) => {
            return (
                <option key={index} value={data.AcctNo} id="opt">{data.AcctNo}</option>
            )
        }))
    }
    gettingDetails() {

        if ((this.state.accNo != null) && (this.state.getBalDetails != null)) {
            const userName = this.props.reducer.welcomeUser;
            const custNo = this.props.reducer.customerNumber;
            const { AccountLabel, AccountNumber, Balance, BalanceAvalaible, CurrencyCode,
                HaveObligation, OverdueObligation, PeriodObligation,
                TotalTodayObligation, MobileNumber } = this.state.getBalDetails;

            return (
                <div className=" form-data ">
                    <div className="row form-group border">
                        <div className="col-md-6 col-sm-12 text-left border-right">
                            <div className="row">
                                <label className="col-lg-6 col-md-6  col-sm-6 col-6 font-weight-bold my-1 text-left"> {i18next.t('cname')} :</label>
                                <div className="col-lg-6 col-md-6  col-sm-6 col-6  tbldesign my-1 text-left">{userName}</div>
                            </div>

                            <div className="row">
                                <label className="col-lg-6 col-md-6  col-sm-6 col-6  font-weight-bold my-1 text-left">{i18next.t('cno')} :</label>
                                <div className="col-lg-6 col-md-6  col-sm-6 col-6  tbldesign my-1 text-left">{custNo}</div>
                            </div>

                            <div className="row">
                                <label className="col-lg-6 col-md-6  col-sm-6 col-6  font-weight-bold my-1 text-left"> {i18next.t('an')} :</label>
                                <div className="col-lg-6 col-md-6  col-sm-6 col-6  tbldesign my-1 text-left">{AccountNumber}</div>
                            </div>

                            <div className="row">
                                <label className="col-lg-6 col-md-6  col-sm-6 col-6  font-weight-bold my-1 text-left">{i18next.t('cc')} :</label>
                                <div className="col-lg-6 col-md-6  col-sm-6 col-6  tbldesign my-1 text-left">{CurrencyCode}</div>
                            </div>

                            <div className="row">
                                <label className="col-lg-6 col-md-6  col-sm-6 col-6  font-weight-bold my-1 text-left">{i18next.t('MobNo')} :</label>
                                <div className="col-lg-6 col-md-6  col-sm-6 col-6  tbldesign my-1 text-left">{MobileNumber}</div>
                            </div>

                        </div>

                        <div className="col-md-6 col-sm-12">

                            <div className="row">
                                <label className="col-lg-6 col-md-6  col-sm-6 col-6  font-weight-bold my-1 text-left">{i18next.t('bal')}:</label>
                                <div className="col-lg-6 col-md-6  col-sm-6 col-6  tbldesign my-1 text-left">{Balance}</div>
                            </div>

                            <div className="row">
                                <label className="col-lg-6 col-md-6  col-sm-6 col-6  font-weight-bold my-1 text-left">{i18next.t('bval')}:</label>
                                <div className="col-lg-6 col-md-6  col-sm-6 col-6  tbldesign my-1 text-left">{BalanceAvalaible}</div>
                            </div>


                            <div className="row">
                                <label className="col-lg-6 col-md-6  col-sm-6 col-6  font-weight-bold my-1 text-left">{i18next.t('hob')}:</label>
                                <div className="col-lg-6 col-md-6  col-sm-6 col-6  tbldesign my-1 text-left">{HaveObligation}</div>
                            </div>

                            <div className="row">
                                <label className="col-lg-6 col-md-6  col-sm-6 col-6  font-weight-bold my-1 text-left">{i18next.t('overd')} :</label>
                                <div className="col-lg-6 col-md-6  col-sm-6 col-6  tbldesign my-1 text-left">{OverdueObligation}</div>
                            </div>

                            <div className="row">
                                <label className="col-lg-6 col-md-6  col-sm-6 col-6  font-weight-bold my-1 text-left">{i18next.t('pobg')}  :</label>
                                <div className="col-lg-6 col-md-6  col-sm-6 col-6  tbldesign my-1 text-left">{PeriodObligation}</div>
                            </div>

                            <div className="row">
                                <label className="col-lg-6 col-md-6  col-sm-6 col-6  font-weight-bold my-1 text-left">{i18next.t('tobg')} :</label>
                                <div className="col-lg-6 col-md-6  col-sm-6 col-6  tbldesign my-1 text-left">{TotalTodayObligation}</div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            swal({ text: i18next.t('notFound'), button: i18next.t("OK") });
        }
    }
    clearScreen = () => {
        this.setState({
            isViewType: false,
            accNo: 'select'
        })
    }
    render() {
        return (
            <>
                <section className="form-data">
                    <form>
                        <div className="form-group  row nb ">
                            <div className="h5 mb-2 card-header"><FontAwesomeIcon icon={faReceipt} className="mr-2" />
                                {i18next.t('bs')}
                            </div>
                            <div className="row col-lg-12 col-md-12 col-sm-12 col-12 p-0" >
                                <div className="row border col-lg-6 col-md-6 col-sm-12 col-12 p-2">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12  ">
                                        <div className="row d-flex justify-content-start  ">
                                            <label className=" col-md-6 col-sm-6 col-6  col-form-label text-left">{i18next.t('accno')}:-</label>
                                            <div className=" col-md-6 col-sm-6 col-6 ">
                                                <select id="select" className="form-control selectwidth" value={this.state.accNo} onChange={this.onAccChange}>
                                                    <option value="select">--{i18next.t('sel')}--</option>
                                                    {this.GetAccountNumber()}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row text-center col-lg-6 col-md-6 col-sm-12 col-12 p-2">
                                    <div className="text-center col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="form-group nb">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <input type="button" className="btn clickbtn mr-1 " id="btn_Submit" value={i18next.t('sb')} onClick={() => this.getBalance()} />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <input type="button" className="btn clickbtn " id="btn_Submit" value={i18next.t('clr')} onClick={this.clearScreen} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    {this.state.isViewType ?
                        <div className=" acc-dtls">
                            {((this.state.accNo != null) && (this.state.getBalDetails != null)) ? this.gettingDetails() : null}
                        </div>
                        :
                        null
                    }


                </section>
            </>
        )
    }
}

function mapStateToProps(state) {
    return state
}

export default withRouter(connect(mapStateToProps)(BalanceStatement))
