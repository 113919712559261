import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import './main.scss';
import i18next from 'i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux'
import Yellow_line from './UserDashboard/Yellow_line';

const Header_Common = () => {

  const [userName, setUserName] = useState("");
  const uLogIn = useSelector((state) => state.reducer);




  function dataType(val) {
    if (val === "" || val === undefined || val === null) {
      return ""
    }
    else {
      return val
    }
  }
  useEffect(() => {
    dispName(uLogIn.welcomeUser)
  }, [])

  function dispName(usr) {
    let usrName = usr.toLowerCase();
    let nameArr = ""
    for (let i = 0; i < usrName.length; i++) {
      if (i === 0 || usrName.charAt(i - 1) === " ") {
        let asd = (usrName.charAt(i)).toUpperCase();

        nameArr = nameArr + asd;
      }

      else {
        nameArr = nameArr + usrName.charAt(i);
      }
      if (nameArr.length === usrName.length) {
        setUserName(nameArr);
      }
    }
  }
  return (
    <div className="">
      <header>
        <div className="top-header">
          <nav className="navbar navbar-expand-lg blueClass">

            <a className="navbar-brand wlc" href="#">
              {i18next.t('wlc') + "      " + userName}
            </a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
              aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon" ></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav ml-auto">

                <li className="nav-item">
                  <Link to="/Faq" activeclassname="active" className="nav-link" >
                    <span className="fas fa-question mr-2"></span>
                    {i18next.t('Faq')} <span className="sr-only">(current)</span>
                  </Link>
                </li>

                <li className="nav-item">

                  <Link activeclassname="active" className="nav-link" to="/About">
                    <span className="fa fa-users mr-2"></span>
                    {i18next.t('aboutus')}
                  </Link>

                </li>

                <li className="nav-item">
                  <a activeclassname="active" className="nav-link" target="_blank" href="https://www.elprogresoefv.com.bo/">
                    <span className="fa fa-phone mr-2 rotatatory"></span>
                    {i18next.t('contactus')}</a>
                </li>

                {dataType(uLogIn.mobileNo) !== "" ?
                  (
                    <li className="nav-item">
                      <Link to="/" activeclassname="active" className="nav-link" >
                        <span className="fas fa-sign-out-alt mr-2" aria-hidden="true"></span>
                        {i18next.t('Logout')} <span className="sr-only">(current)</span>
                      </Link>
                    </li>
                  )
                  :
                  ""
                }
              </ul>
            </div>

          </nav>
        </div>
      </header>
      <Yellow_line />
      {/* {dataType(uLogIn.mobileNo) !== "" ?
        (<Yellow_line />) :
        ""
      } */}

    </div>
  )
}
const mapStateToProps = state => {
  return state
};

export default withRouter(connect(mapStateToProps)(Header_Common))

