import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "jspdf-autotable";
import { faReceipt } from "@fortawesome/free-solid-svg-icons";
import i18next from 'i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BrowserRouter as Router, Link } from 'react-router-dom';
import swal from 'sweetalert';
import SessionAPIReq from '../utils/SessionAPIReq';

class Ministatement extends Component {
    constructor(props) {
        super(props)
        this.state = {
            GetAccNo: [],
            accNo: '',
            data: [],
            isViewType: false,
            clearScr: false
        }
    }
    viewHandle = () => {
        this.setState({ isViewType: true })
    }
    onAccChange = async (e) => {
        this.setState({
            accNo: e.target.value
        });
    }
    submitHandle = async () => {
        // {this.viewHandle()}
        const SessionData = {
            MobileNo: this.props.reducer.mobileNo,
            ChannelId: this.props.reducer.channelId,
            Cuid: this.props.reducer.bankCode
        };
        let Accdata = []
        Accdata = this.props.reducer.accCallList.filter((data => data.AcctNo === this.state.accNo));
        if (Accdata.length > 0) {
            const InnerRequestJSON = {
                MobileNumber: this.props.reducer.mobileNo,
                Cuid: this.props.reducer.cuid,
                ChannelId: this.props.reducer.channelId,
                TransactionType: this.props.reducer.MiniTransactionType,
                Stan: '0',
                AccountNumber: this.state.accNo,
                ModuleType: Accdata[0].ModuleType,
            }
            const OuterRequestJSON = {
                Id: "0",
                LogInMobileNumber: this.props.reducer.mobileNo,
                LogInChannelId: this.props.reducer.channelId,
                LogInCuid: this.props.reducer.cuid,
                RequestType: this.props.reducer.MiniRequestType,
                RequestMessage: JSON.stringify(InnerRequestJSON),
                ResponseMessage: "",
            }
            const APIurl = process.env.REACT_APP_DefaultUrl + 'ProcessMBRequest?token=TOK';
            var returnAccountDetails = await SessionAPIReq(JSON.stringify(SessionData), JSON.stringify(OuterRequestJSON), APIurl);
            if (returnAccountDetails.ResponseCode === "000") {
                const InnerJsonResponse = JSON.parse(returnAccountDetails.Response);
                this.setState({
                    isViewType: true,
                    data: InnerJsonResponse.MiniTransactionList
                });
            }
            else {
                if (returnAccountDetails.ResponseCode === "E01") {
                    const InnerJsonResponse = JSON.parse(returnAccountDetails.Response);
                    if (InnerJsonResponse.SuccessFlag === "false") {
                        // swal(InnerJsonResponse.ErrorDescription);
                        swal({
                            icon: "error",
                            text: InnerJsonResponse.ErrorDescription,
                            buton: "Ok"
                        })
                    }
                    else if (returnAccountDetails.ResponseCode === "900") {
                        // swal(returnAccountDetails.Response);
                        swal({
                            icon: "error",
                            text: returnAccountDetails.Response,
                            buton: "Ok"
                        })
                    }
                }
                // else {
                //     swal(returnAccountDetails.Response)
                // }
            }
        }
        else {
            if (this.state.data.length > 0) {
                this.setState({ data: [] });
            }
        }
    }
    GetAccountNumber = (e) => {
        return (this.props.reducer.accCallList.map((data, index) => {
            return (
                <option key={index} value={data.AcctNo} id="opt">{data.AcctNo}</option>
            )
        }))
    }
    gettingDetails = (e) => {
        if (this.state.data != null)
            var i = 0;
        return this.state.data.reverse().map((data, index) => {
            i++;
            const Srno = i;
            const Trans_Date = data.TransactionDate
            let Dep_Amt = "0";
            let With_Amt = "0";

            if (data.AmountFlag === "D") {
                With_Amt = data.Amount;
            }
            else {
                Dep_Amt = data.Amount;
            }
            const Bal = data.Balance; //+ " " + ((data.BalanceFlag == "C")? "CR": "Dr");
            const CC = ((data.Currencycode === "BOB") ? "Bs" : ((data.Currencycode === "USD") ? "$us" : data.Currencycode));
            const Narration = data.Narration;
            return (
                <tr className="tblrow" key={index}>
                    <td >{Srno}</td>
                    <td>{Trans_Date}</td>
                    <td>{Dep_Amt}</td>
                    <td >{With_Amt}</td>
                    <td >{Bal}</td>
                    <td >{CC}</td>
                    <td >{Narration === null || "" ? "Mini Extracto" : Narration}</td>
                </tr>
            )
        })
    }
    clearScreen = () => {
        this.setState({
            isViewType: false,
            accNo: 'select'
        })
    }
    render() {
        return (
            <div className="full_section_dash">
                <section className="form-data">
                    <form>
                        <div className="form-group  row nb ">
                            <div className="h5 mb-2 card-header"><FontAwesomeIcon icon={faReceipt} className="mr-2" />
                                {i18next.t('ms')}
                            </div>
                            <div className="row col-lg-12 col-md-12 col-sm-12 col-12 p-0" >
                                <div className="row border col-lg-6 col-md-6 col-sm-12 col-12 p-2">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 ">
                                        <div className="row">
                                            <label className=" col-md-6 col-sm-6 col-6  col-form-label text-left">{i18next.t('an')}:-</label>
                                            <div className=" col-md-6 col-sm-6 col-6 ">
                                                <select id="select" className="form-control selectwidth" value={this.state.accNo}
                                                    onChange={this.onAccChange} >
                                                    <option value="select">--{i18next.t('sel')}--</option>
                                                    {this.GetAccountNumber()}
                                                </select>
                                            </div>
                                            <div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row text-center col-lg-6 col-md-6 col-sm-12 col-12 p-2">
                                    <div className="text-center col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="form-group nb ">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <input type="button" className="btn clickbtn " id="btn_Submit" value={i18next.t('sb')} onClick={() => this.submitHandle()} />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <input type="button" className="btn clickbtn " id="btn_Submit" value={i18next.t('clr')} onClick={this.clearScreen} />
                                                </div>
                                            </div>

                                            {/* <Link to="/Dashboard" className="btn clickbtn"> {i18next.t('clr')}  </Link> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </section>

                {
                    this.state.isViewType ?
                        <div>
                            <table className="table  table-bordered  table-md table-striped mini-table table-responsive table-hover ">
                                <thead className="table-heading ">
                                    <tr>
                                        <th >{i18next.t('sr')}</th>
                                        <th >{i18next.t('td')}</th>
                                        <th >{i18next.t('damt')}</th>
                                        <th >{i18next.t('wamt')}</th>
                                        <th >{i18next.t('bal')}</th>
                                        <th >{i18next.t('cc')}</th>
                                        <th >{i18next.t('narrate')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.gettingDetails()}
                                </tbody>
                            </table>
                        </div>
                        :
                        null
                }
            </div>
        );
    };
}
function mapStateToProps(state) {
    return state
}

// export default connect(mapStateTokenProps, mapDispatchToProps)(Login); 
export default withRouter(connect(mapStateToProps)(Ministatement))
