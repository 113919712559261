import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import EnvConfiguration from '../Environment/EnvConfiguration';
import i18next from 'i18next';
import swal from 'sweetalert';
import { userLogin } from '../actions/actions';
import SessionAPIReq from '../utils/SessionAPIReq';

class PaymentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      RemAccNo: '',
      RemModType: '',
      RemModName: '',
      BenPayeeName: '',
      BenPayeeAccno: '',
      BenModuleType: '',
      BenCuid: '',
      BenModuleName: '',
      BenCedulaId: '',
      BenCooperativeName: '',
      accNoAll: [],
      payListWithin: [],
      payListOther: [],
      Name: '',
      getCoList: [],
      clearErr: '',
      viewType: true,
      list: '',
      PayAcc: '',
      PayModTy: '',
      PBNB: '',
      TMTB: '',
      TNTB: '',
      TNRB: '',
      newAcc: '',
      ogRec: '',
      dsRec: '',
      recursoView: false,
      transactionType: "111",
      getCode: '',
      currCode: "BOB",
      newSign: '',
      dataError: {
        RemAccNo: "",
        BenPayeeName: "",
        TMTB: '',
        ogRec: "",
        dsRec: "",
        TNTB: ""
      },
    }
    this.bolBankPayee = React.createRef()
    this.envConfig = new EnvConfiguration();
  }


  componentDidMount = async (e, Name) => {
    this.bolBankPayee.current.focus()
    if (this.props.reducer.payDataReducer.length > 0) {
      let payData = [];
      payData = this.props.reducer.payDataReducer;
      let BenModuleName = this.props.reducer.moduleType.filter((data) => data.ModuleType === payData[0].ModuleType)[0].ModuleName;
      let BenCooperativeName = this.props.reducer.getCooperativeList.GetCooperativeList.filter((data) => data.Cuid === payData[0].Cuid)[0].Cooperatives;
      let BenPayeeName = payData[0].Nickname;
      let BenPayeeAccno = payData[0].AccountNo;
      let BenModuleType = payData[0].ModuleType;
      let BenCuid = payData[0].Cuid;
      let BenCedulaId = payData[0].CedulaId;
      let Viewdata = false;

      if (BenCuid === this.props.reducer.cuid) {
        Viewdata = true;
      }
      this.setState({
        accNoAll: this.props.reducer.accCallList,
        BenPayeeName: BenPayeeName,
        BenPayeeAccno: BenPayeeAccno,
        BenModuleType: BenModuleType,
        BenCuid: BenCuid,
        BenModuleName: BenModuleName,
        BenCooperativeName: BenCooperativeName,
        BenCedulaId: BenCedulaId,
        viewType: Viewdata,
      }, () => {
        this.props.dispatch(userLogin("payDataReducer", []));
        this.displayHandleChange()
      })
    }
    else {
      this.setState({
        accNoAll: this.props.reducer.accCallList,
      }, () => { this.displayHandleChange() })
    }
  }
  // getting Acct no
  GetAccountNumber = () => {
    return (
      this.props.reducer.accCallList.filter((data) => data.ModuleType === "5").map((data, index) => {
        return (
          <option key={index} value={data.AcctNo} id="opt">{data.AcctNo}</option>
        )
      })
    )
  }

  // fund transfer view
  ftwithinView = () => (
    this.setState({
      viewType: true,
      RemAccNo: '',
      RemModType: '',
      RemModName: '',
      BenPayeeName: '',
      BenPayeeAccno: '',
      BenModuleType: '',
      BenCuid: '',
      BenModuleName: '',
      BenCooperativeName: '',
      BenCedulaId: '',
      TMTB: '',
      TNTB: '',
      TNRB: '',
      ogRec: '',
      dsRec: '',
      transactionType: "111"
    })
  )

  // fund transfer other bank view
  ftOtherView = () => (
    this.setState({
      viewType: false,
      RemAccNo: '',
      RemModType: '',
      RemModName: '',
      BenPayeeName: '',
      BenPayeeAccno: '',
      BenModuleType: '',
      BenCuid: '',
      BenModuleName: '',
      BenCooperativeName: '',
      BenCedulaId: '',
      TMTB: '',
      TNTB: '',
      TNRB: '',
      ogRec: '',
      dsRec: '',
      transactionType: "113",
    })
  )
  // Api for displaying added payee 
  displayHandleChange = async (e) => {
    const SessionData = {
      MobileNo: this.props.reducer.mobileNo,
      ChannelId: this.props.reducer.channelId,
      Cuid: this.props.reducer.bankCode
    }

    // const url =  process.env.REACT_APP_DefaultUrl + '/Payee?token={token}';
    const data = {
      Nickname: "",
      AccountNo: "",
      ModuleType: "",
      Cuid: this.props.reducer.cuid,
      LoginMobileNo: this.props.reducer.mobileNo,
      loginCuid: this.props.reducer.cuid,
      Channelid: this.props.reducer.channelId,
      TransactionMode: "NA",
      CedulaId: "0",
      ProcessFlag: "4",
      Successflag: "",
    };


    const APIurl = process.env.REACT_APP_DefaultUrl + 'Payee?token=TOK';

    var returnData = await SessionAPIReq(JSON.stringify(SessionData), JSON.stringify(data), APIurl);
    if (returnData.ResponseCode === "000") {
      let addedData = JSON.parse(returnData.Response)
      var withinNewData = addedData.Payees.filter((data) => data.Cuid === this.props.reducer.cuid)
      var OtherNewData = addedData.Payees.filter((data) => data.Cuid !== this.props.reducer.cuid)

      this.setState({
        payListWithin: withinNewData,
        payListOther: OtherNewData,
        getCoList: this.props.reducer.getCooperativeList,
      });

      this.getMoney();
    }
    else if (returnData.ResponseCode === "100") {
      swal(returnData.processflag)
    }
    else {
      swal(returnData.data.Response)
    }
  }
  // getting account number

  onRemAccChange = (e) => {
    let RemModType = '';
    let RemModName = '';
    RemModType = this.props.reducer.accCallList.filter((data) => data.AcctNo === e.target.value)[0].ModuleType;
    RemModName = this.props.reducer.moduleType.filter((data) => data.ModuleType === RemModType)[0].ModuleName;
    this.setState({
      RemAccNo: e.target.value,
      RemModType: RemModType,
      RemModName: RemModName
    })
  }

  // payee details with autofill parameters
  onBenAccChange = (e) => {
    let BenPayeeName = '';
    let BenPayeeAccno = '';
    let BenModuleType = '';
    let BenModuleName = '';
    let BenCuid = '';
    let BenCooperativeName = '';
    let BenCedulaId = '';
    let payeedata

    if (this.state.viewType) {
      payeedata = this.state.payListWithin.filter((item) => item.Nickname === e.target.value)
    }
    else {
      payeedata = this.state.payListOther.filter((item) => item.Nickname === e.target.value)
    }

    if (payeedata.length > 0) {
      BenPayeeName = payeedata[0].Nickname;
      BenPayeeAccno = payeedata[0].AccountNo;
      BenModuleType = payeedata[0].ModuleType;
      BenCuid = payeedata[0].Cuid;
      BenCedulaId = payeedata[0].CedulaId;

      BenModuleName = this.props.reducer.moduleType.filter((data) => data.ModuleType === BenModuleType)[0].ModuleName;
      BenCooperativeName = this.props.reducer.getCooperativeList.GetCooperativeList.filter((data) => data.Cuid === BenCuid)[0].Cooperatives;
      this.setState({
        BenPayeeName: BenPayeeName,
        BenPayeeAccno: BenPayeeAccno,
        BenModuleType: BenModuleType,
        BenCuid: BenCuid,
        BenModuleName: BenModuleName,
        BenCooperativeName: BenCooperativeName,
        BenCedulaId: BenCedulaId,
      });
    }
  }


  // select beneficiary bolivia bank
  getBeneficiary = () => {

    return (
      this.state.payListWithin.map((data, index) => {

        return (
          <option value={data.Nickname}>{data.Nickname}</option>

        )
      })
    )
  }
  // select beneficiary other bank
  getBeneficiaryOther = () => {

    return (
      this.state.payListOther.map((data, index) => {

        return (
          <option value={data.Nickname}>{data.Nickname}</option>
        )
      })
    )
  }

  // bolivia bank user
  boliviaHandleCHange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  // clearB
  handleClearAllSelf = (e) => {
    this.setState({
      recursoView: false,
      RemAccNo: '',
      BenPayeeName: '',
      BenCooperativeName: '',
      BenPayeeAccno: '',
      BenModuleName: '',
      BenCedulaId: '',
      TMTB: '',
      TNTB: '',
      TNRB: '',
      ogRec: '',
      dsRec: '',
      currCode: '',
      dataError: []
    })
  }
  // moneylaundering API
  getMoney = async (e) => {
    let code = "7";
    if (this.state.currCode === "USD") {
      code = "6";
    }
    const SessionData = {
      MobileNo: this.props.reducer.mobileNo,
      ChannelId: this.props.reducer.channelId,
      Cuid: this.props.reducer.bankCode
    }
    // const url = process.env.REACT_APP_DefaultUrl + '/GetMoneyLaunderingAmount?token={token}';
    const data = {
      LoginMobileno: this.props.reducer.mobileNo,
      LoginChannelId: this.props.reducer.channelId,
      LoginCuid: this.props.reducer.cuid,
      Code: code
    };
    const APIurl = process.env.REACT_APP_DefaultUrl + 'GetMoneyLaunderingAmount?token=TOK';
    var returnData = await SessionAPIReq(JSON.stringify(SessionData), JSON.stringify(data), APIurl);

    const minLimitAmt = returnData.Response

    if (returnData.ResponseCode === "000") {

      this.setState({
        getCode: minLimitAmt,
      })
    }
    else if (returnData.ResponseCode === "100") {
      swal(returnData.ResponseMessage)
    }
  }

  // recurso handleEvent
  recursoHandleChange = (e) => {
    var specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

    let recursoType = false;
    let newRecurso = "";

    if (parseFloat(e.target.value) > parseFloat(this.state.getCode)) {
      recursoType = true;
    }
    else if (parseFloat(e.target.value) < parseFloat(this.state.getCode)) {
      newRecurso = ""
    }
    if (e.target.name === "TMTB" || "TNTB") {
      if (!specialChars.test(e.nativeEvent.data) && (!isNaN(parseInt(e.nativeEvent.data)) || e.nativeEvent.data === null)) {
        this.setState({
          [e.target.name]: e.target.value
        })
      }
      else {
        this.setState({
          [e.nativeEvent.data]: ""
        })
      }
    }
    else {
      this.setState({
        [e.target.name]: e.target.value,
        recursoView: recursoType,
        ogRec: newRecurso,
        dsRec: newRecurso,
      })
    }

  }
  // currency Code
  getCurrencyCode = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    }, () => { this.getMoney() });
  }

  // Api for Bolivia Bank User

  continueBoliviaHandleChange = async (e) => {

    let UpdatedDataError = { ...this.state.dataError };
    let validation = 0;
    let Message = "";

    if ((this.state.RemAccNo === "") || (this.state.RemAccNo === null)) {
      UpdatedDataError.RemAccNo = "*" + i18next.t('acctReq');
      validation = validation + 1;
      Message = Message + validation + ". " + i18next.t('acctReq') + "\n";
    }
    if ((this.state.BenPayeeName === "") || (this.state.BenPayeeName === null)) {
      UpdatedDataError.BenPayeeName = "*" + i18next.t('benReq');
      validation = validation + 1;
      Message = Message + validation + ". " + i18next.t('benReq') + "\n";
    }
    if ((this.state.TMTB === "") || (this.state.TMTB === null)) {
      UpdatedDataError.TMTB = "*" + i18next.t('amtRequired');
      validation = validation + 1;
      Message = Message + validation + ". " + i18next.t('amtRequired') + "\n";
    }
    if ((this.state.TNTB === "") || (this.state.TNTB === null)) {
      UpdatedDataError.TNTB = "*" + i18next.t('transRequired');
      validation = validation + 1;
      Message = Message + validation + ". " + i18next.t('transRequired') + "\n";
    }

    if (validation > 0) {
      this.setState({ dataError: UpdatedDataError });
      swal({ icon: "error", text: i18next.t('error') + " \n\n" + Message, button: i18next.t("ok") });
    }

    else {
      const SessionData = {
        MobileNo: this.props.reducer.mobileNo,
        ChannelId: this.props.reducer.channelId,
        Cuid: this.props.reducer.bankCode
      };

      // const url = process.env.REACT_APP_DefaultUrl + '/ProcessMBRequest?token={token}';
      let transactionType = 0;
      if (this.props.reducer.cuid === this.state.BenCuid) {
        transactionType = '111'
      }
      else {
        transactionType = '113'
      }
      const newData = {
        MobileNumber: this.props.reducer.mobileNo,
        ChannelId: this.props.reducer.channelId,
        Cuid: this.props.reducer.cuid,
        TransactionType: transactionType,
        Stan: "0",
        DebitMobileNo: this.props.reducer.mobileNo,
        DebitChannelId: this.props.reducer.channelId,
        DebitCuid: this.props.reducer.cuid,
        DebitAccountNumber: this.state.RemAccNo,
        DebitModuleType: "5",
        DebitName: this.props.reducer.welcomeUser,
        DebitNationalId: this.props.reducer.customerNumber,
        CreditChannelId: this.props.reducer.channelId,
        CreditCuid: this.state.BenCuid,
        CreditAccountNumber: this.state.BenPayeeAccno,
        CreditModuleType: this.state.BenModuleType,
        CreditName: this.state.BenPayeeName,
        CreditNationalId: this.state.BenCedulaId,
        NoofEMIs: "1",
        PaymentType: this.props.reducer.channelId,
        Amount: this.state.TMTB,
        Narration: this.state.TNRB,
        TID: this.props.reducer.ipAdd,
        OTP: this.state.TNTB,
        TranCode: "1",
        Currencycode: this.state.currCode,
        OrigenRecurso: this.state.ogRec,
        DestinoRecurso: this.state.dsRec,
      }
      const urlData = {
        Id: 0,
        LogInMobileNumber: this.props.reducer.mobileNo,
        LogInChannelId: this.props.reducer.channelId,
        LogInCuid: this.props.reducer.cuid,
        RequestType: "Fundtransfer",
        RequestMessage: JSON.stringify(newData),
        ResponseMessage: "",
      };

      const APIurl = process.env.REACT_APP_DefaultUrl + 'ProcessMBRequest?token=TOK';
      var returnAccountDetails = await SessionAPIReq(JSON.stringify(SessionData), JSON.stringify(urlData), APIurl);
      var NewDetailsList = JSON.parse(returnAccountDetails.Response)

      if (returnAccountDetails.ResponseCode === "000") {
        // swal(NewDetailsList.TransactionDetail);
        swal({
          icon: "success",
          text: NewDetailsList.TransactionDetail,
          buton: "Ok"
        })

        this.setState({
          RemAccNo: '',
          BenPayeeName: '',
          BenCooperativeName: '',
          BenPayeeAccno: '',
          BenModuleName: '',
          BenCedulaId: '',
          TMTB: '',
          TNTB: '',
          TNRB: '',
          ogRec: '',
          dsRec: '',
        })
      }
      else if (returnAccountDetails.ResponseCode === "900" || returnAccountDetails.ResponseCode === "E01") {
        // swal(NewDetailsList.ErrorDescription)
        swal({
          icon: "error",
          text: NewDetailsList.ErrorDescription,
          buton: "Ok"
        })

      }
      else {
        // swal(NewDetailsList.TransactionDetail)
        swal({
          icon: "error",
          text: NewDetailsList.TransactionDetail,
          buton: "Ok"
        })
      }
    }
  }

  validate = (propname) => {
    var specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    let UpdatedDataError = { ...this.state.dataError };
    if (propname === "RemAccNo") {
      if ((this.state.RemAccNo === "") || (this.state.RemAccNo === null)) {
        UpdatedDataError["RemAccNo"] = <strong> {"*" + i18next.t('acctReq')}</strong>;
      }
      else {
        UpdatedDataError["RemAccNo"] = "";
      }
    }

    else if (propname === "BenPayeeName") {
      if ((this.state.BenPayeeName === "") || (this.state.BenPayeeName === null)) {
        UpdatedDataError["BenPayeeName"] = <strong>{"*" + i18next.t('benReq')}</strong>;
      }
      else {
        UpdatedDataError["BenPayeeName"] = "";
      }
    }

    else if (propname === "TMTB") {
      if ((this.state.TMTB === "") || (this.state.TMTB === null)) {
        UpdatedDataError["TMTB"] = <strong>{"*" + i18next.t('amtRequired')}</strong>;
      }
      else if ((specialChars.test(this.state.TMTB))) {
        UpdatedDataError["TMTB"] = <strong>{"*" + i18next.t('amtinvalido')}</strong>;
      }
      else {
        UpdatedDataError["TMTB"] = "";
      }
    }

    else if (propname === "TNTB") {
      if ((this.state.TNTB === "") || (this.state.TNTB === null)) {
        UpdatedDataError["TNTB"] = <strong>{"*" + i18next.t('transRequired')}</strong>;
      }
      else {
        UpdatedDataError["TNTB"] = "";
      }
    }
    this.setState({ dataError: UpdatedDataError });
  }
  render() {
    return (
      <div className="container">
        <div className="h5 mb-2 card-header">
          <i className="fas fa-receipt mr-2"></i> {i18next.t('uspa')}
        </div>
        <div className="card-body col-lg-12 col-md-12 col-sm-12 col-12 border">
          <input type="button" ref={this.bolBankPayee} value={i18next.t('bbp')} className="form-inline  btn text-light mybtndgn mr-4 mt-2 col-lg-6 col-md-6 col-sm-6 col-6 "
            onClick={() => this.ftwithinView()} />
          <input type="button" value={i18next.t('obp')} className="form-inline  btn text-light mybtndgn mt-2 col-lg-6 col-md-6 col-sm-6 col-6"
            onClick={() => this.ftOtherView()} />
        </div>

        <br />
        <div className="row ">
          <section className="form-data  col-lg-12 col-md-12 col-sm-12 ">
            <div className="card">
              <form autoComplete="off" readonly onfocus="this.removeAttribute('readonly');">
                <div className="h5 mb-2 card-header ">
                  <i class="fa fa-exchange" aria-hidden="true"></i>
                  {this.state.viewType === true ? i18next.t('ftbb') : i18next.t('ftob')}</div>
                <div className="form-group row nb  ">


                  <div className="col-lg-6 col-md-6 col-sm-12 col-12">

                    <div className="form-group row nb ">

                      <label class=" col-md-6 col-sm-6 col-6 col-form-label text-left mb-2" >{i18next.t('faccn')}:-</label>
                      <div className=" col-md-6 col-sm-6 col-6">
                        <div className="row ">
                          <div>

                            <select id="select" className="form-control selectwidth" value={this.state.RemAccNo}
                              onChange={this.onRemAccChange}
                              onBlur={(propname) => this.validate("RemAccNo")} error={this.state.dataError.RemAccNo ? true : false}>
                              <option value="select">--{i18next.t('sel')}--</option>
                              {this.GetAccountNumber()}
                            </select>
                            <span style={{ color: "red", float: "left " }}>{this.state.dataError.RemAccNo}</span>
                          </div>
                        </div>
                      </div>

                      <label class=" col-md-6 col-sm-6 col-6 col-form-label text-left mb-2">{i18next.t('sebn')}-</label>
                      <div className=" col-md-6 col-sm-6 col-6">
                        <div className="row ">
                          <div>
                            <select id="select" className="form-control selectwidth" value={this.state.BenPayeeName}
                              onChange={this.onBenAccChange} ref="fieldBen"
                              onBlur={(propname) => this.validate("BenPayeeName")} error={this.state.dataError.BenPayeeName ? true : false}>
                              <option value="select">--{i18next.t('sel')}--</option>
                              {this.state.viewType ? this.getBeneficiary() : this.getBeneficiaryOther()}
                            </select>
                            <span style={{ color: "red", float: "left " }}>{this.state.dataError.BenPayeeName}</span>
                          </div>
                        </div>
                      </div>

                      <label class=" col-md-6 col-sm-6 col-6 col-form-label text-left mb-2">{i18next.t('pbn')}:-</label>
                      <div className=" col-md-6 col-sm-6 col-6">
                        <div className="row ">
                          <div>
                            <input className="form-control selectwidth text-left" name="PBNB" value={this.state.BenCooperativeName}
                              disabled="true"
                              type="text" placeholder={i18next.t('autoFill')} />
                          </div>
                        </div>
                      </div>

                      <label class=" col-md-6 col-sm-6 col-6 col-form-label text-left mb-2">{i18next.t('becid')}:-</label>
                      <div className=" col-md-6 col-sm-6 col-6">
                        <div className="row ">
                          <div>
                            <input className="form-control selectwidth text-left" name="BenCedulaId" value={this.state.BenCedulaId}
                              disabled="true"

                              type="text" placeholder={i18next.t('autoFill')} />
                          </div>
                        </div>
                      </div>

                      <label class=" col-md-6 col-sm-6 col-6 col-form-label text-left mb-2">{i18next.t('payAcc')}:-</label>
                      <div className=" col-md-6 col-sm-6 col-6">
                        <div className="row ">
                          <div>
                            <input className="form-control selectwidth text-left" name="PayAcc" value={this.state.BenPayeeAccno}
                              disabled="true"

                              type="text" placeholder={i18next.t('autoFill')} />
                          </div>
                        </div>
                      </div>

                      <label class=" col-md-6 col-sm-6 col-6 col-form-label text-left mb-2">{i18next.t('payMod')}:-</label>
                      <div className=" col-md-6 col-sm-6 col-6">
                        <div className="row ">
                          <div>
                            <input className="form-control selectwidth text-left" name="PayModTy" value={this.state.BenModuleName}
                              disabled="true"

                              type="text" placeholder={i18next.t('autoFill')} />
                          </div>
                        </div>
                      </div>

                      <label class=" col-md-6 col-sm-6 col-6 col-form-label text-left mb-2" >{i18next.t('cc')}:-</label>
                      <div className=" col-md-6 col-sm-6 col-6">
                        <div className="row ">
                          <div>
                            <select id="select" className="form-control selectwidth" name="currCode" value={this.state.currCode}
                              onChange={this.getCurrencyCode}  >
                              {/* <option value="select">--{i18next.t('sel')}--</option> */}
                              <option value="BOB">BOB</option>
                              <option value="USD">USD</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <label class=" col-md-6 col-sm-6 col-6 col-form-label text-left mb-2">{i18next.t('tramt')}:-</label>
                      <div className=" col-md-6 col-sm-6 col-6">

                        <div className="row ">
                          {this.state.currCode === "USD" ?
                            <div className="row">
                              <label class=" col-form-label text-left col-lg-2 col-md-2 col-sm-2 col-2"> $ </label>
                              <input className="form-control selectwidth text-left col-lg-10 col-md-10 col-sm-10 col-10" type="text"
                                name="TMTB" value={this.state.TMTB} onChange={this.recursoHandleChange} placeholder={i18next.t('tramt')} autoComplete="new-amount"
                                onBlur={(propname) => this.validate("TMTB")} error={this.state.dataError.TMTB ? true : false}
                              />
                              <span style={{ color: "red", float: "left " }}>{this.state.dataError.TMTB}</span>
                            </div>

                            : <div className="row">
                              <input className="form-control selectwidth text-left col-lg-12 col-md-12 col-sm-12 col-12" type="text"
                                name="TMTB" value={this.state.TMTB} onChange={this.recursoHandleChange} placeholder={i18next.t('tramt')} autoComplete="new-amount"
                                onBlur={(propname) => this.validate("TMTB")} error={this.state.dataError.TMTB ? true : false}
                              />
                              <span style={{ color: "red", float: "left " }}>{this.state.dataError.TMTB}</span>
                            </div>
                          }
                        </div>
                      </div>

                      {/* recurso */}
                      {this.state.recursoView ?
                        <div className="form-group row nb ">
                          <label className=" col-md-6 col-sm-6 col-6 col-form-label text-left mb-2">{i18next.t('ogr')}:-</label>
                          <div className=" col-md-6 col-sm-6 col-6">
                            <div className="row ">
                              <div>
                                <input className="form-control selectwidth text-left" type="text" name="ogRec" value={this.state.ogRec}
                                  onChange={this.boliviaHandleCHange} placeholder="Origin Recurso" autoComplete="new-amount"
                                />
                              </div>
                            </div>
                          </div>

                          <label className=" col-md-6 col-sm-6 col-6 col-form-label text-left mb-2">{i18next.t('dsr')}:-</label>
                          <div className=" col-md-6 col-sm-6 col-6">
                            <div className="row ">
                              <div>
                                <input className="form-control selectwidth text-left" type="text" name="dsRec" value={this.state.dsRec}
                                  onChange={this.boliviaHandleCHange} placeholder="Destination Recurso" autoComplete="new-amount"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        :
                        null
                      }

                      <label class=" col-md-6 col-sm-6 col-6 col-form-label text-left mb-2">{i18next.t('trpn')}:-</label>
                      <div className=" col-md-6 col-sm-6 col-6">
                        <div className="row ">
                          <div>
                            <input className="form-control selectwidth text-left" type="password" maxLength="4" name="TNTB" value={this.state.TNTB}
                              onChange={this.recursoHandleChange} placeholder={i18next.t('trpn')} autoComplete="new-password"
                              onBlur={(propname) => this.validate("TNTB")} error={this.state.dataError.TNTB ? true : false}
                            />
                            <span style={{ color: "red", float: "left " }}>{this.state.dataError.TNTB}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12 col-12">

                    <div className="form-group row nb ">
                      <label class=" col-md-2 col-sm-5 col-6 col-form-label text-left mb-2">{i18next.t('or')}</label>
                      <div class="col-lg-6 col-md-8  col-sm-6 col-6 tbldesign my-1 ">
                        {this.state.viewType ?
                          <Link to="/BoliviaBankPayee" className="btn add_new text-xs" >{i18next.t('addnew')}</Link>
                          :
                          <Link to="/OtherAddPayee" className="btn add_new text-xs" >{i18next.t('addnew')}</Link>
                        }
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 col-sm-12 form-group  nb">
                    <div className="row">
                      <div class="input-group row">
                        <div class="input-group-prepend  col-md-3 col-sm-6 col-12 ">
                          <label class=" col-form-label text-left   mb-2">{i18next.t('trk')}:-</label>
                        </div>
                        <textarea class="form-control  col-md-6 col-sm-6 col-12  text-left size-xs" name="TNRB"
                          value={this.state.TNRB} onChange={this.boliviaHandleCHange} placeholder={i18next.t('trk')}
                          aria-label="With textarea"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <input type="button" value={i18next.t('cntie')} className="btn clickbtn mr-2 " onClick={this.continueBoliviaHandleChange} />
                  <input type="button" value={i18next.t('clr')} onClick={this.handleClearAllSelf} className="btn clickbtn ml-2" />
                </div>
              </form>
            </div>
          </section>
        </div>
      </div>
    )
  }
}
// export default PaymentForm;
function mapStateToProps(state) {
  return state
}

export default withRouter(connect(mapStateToProps)(PaymentForm))
