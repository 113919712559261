import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import i18next from 'i18next';
import swal from 'sweetalert';
import { trackPromise } from 'react-promise-tracker';
import SessionAPIReq from '../utils/SessionAPIReq';
import Swal from 'sweetalert2';

export class LoginPin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clearErr: '',
      CLP: '',
      NLP: '',
      CnLP: '',
      dataError: {
        CLP: '',
        NLP: '',
        CnLP: '',
      },

    }

  }


  handleChange = (e) => {
    // this.setState({
    //   [e.target.name]: e.target.value
    // })
    var specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (e.target.name === "CLP" || "NLP" || "CnLP") {
      if (!specialChars.test(e.nativeEvent.data) && (!isNaN(parseInt(e.nativeEvent.data)) || e.nativeEvent.data === null)) {
        this.setState({
          [e.target.name]: e.target.value
        })
      }
      else {
        this.setState({
          [e.nativeEvent.data]: ""
        })
      }
    }
    else {
      this.setState({
        [e.target.name]: e.target.value,
      })
    }

  }

  // clear
  handleClearAll = () => {
    this.setState({
      CLP: '',
      NLP: '',
      CnLP: '',
      dataError: []
    })
  }

  // api for pinchange  
  changeLoginPass = async (e) => {
    // onsubmit validation
    let UpdatedDataError = { ...this.state.dataError };
    let validation = 0;
    let Message = "";
    if ((this.state.NLP.length > 3) || (this.state.CnLP.length > 3)) {

      if ((this.state.CLP === "") || (this.state.CLP === null)) {
        UpdatedDataError.CLP = "*" + i18next.t('loginRequired');
        validation = validation + 1;
        Message = Message + validation + ". " + i18next.t('loginRequired') + "\n";
      }

      if ((this.state.NLP === "") || (this.state.NLP === null)) {
        UpdatedDataError.NLP = "*" + i18next.t('newLoginRed');
        validation = validation + 1;
        Message = Message + validation + ". " + i18next.t('newLoginRed') + "\n";
      }

      if ((this.state.CnLP === "") || (this.state.CnLP === null)) {
        UpdatedDataError.CnLP = "*" + i18next.t('confirmLoginReq');
        validation = validation + 1;
        Message = Message + validation + ". " + i18next.t('confirmLoginReq') + "\n";
      }

      if (validation > 0) {
        this.setState({ dataError: UpdatedDataError });
        swal({ icon: "error", text: i18next.t('error') + " \n\n" + Message, button: i18next.t("ok") });
      }

      else {
        // checking
        if (this.state.NLP === this.state.CnLP) {
          try {
            const SessionData = {
              MobileNo: this.props.reducer.mobileNo,
              ChannelId: this.props.reducer.channelId,
              Cuid: this.props.reducer.bankCode
            }
            // const url =  process.env.REACT_APP_DefaultUrl + '/MChangePin?token={token}';
            const pinApidata = {
              MobileNumber: this.props.reducer.mobileNo,
              BankCode: this.props.reducer.bankCode,
              OldPin: this.state.CLP,
              NewPin: this.state.NLP,
              ChannelId: this.props.reducer.channelId,
            };

            const APIurl = process.env.REACT_APP_DefaultUrl + 'MChangePin?token=TOK';
            var returnData = await trackPromise(SessionAPIReq(JSON.stringify(SessionData), JSON.stringify(pinApidata), APIurl));

            if (returnData.ResponseCode === "000") {
              Swal.fire({
                icon: "success",
                text: i18next.t("changeMPIN"),
                button: i18next.t("ok"),
                confirmButtonColor: "#2e5f8c",
                allowOutsideClick: false,
                allowEscapeKey: false
              }).then((result) => {

                if (result.isConfirmed) {
                  this.props.history.push('/TransPin')
                }

              })

            }
            else if (returnData.ResponseCode === "100") {
              // swal(returnData.ResponseMessage)
              swal({
                icon: "error",
                text: returnData.ResponseMessage,
                buton: "Ok"
              })
            }
            else {
              // swal(returnData.ResponseMessage)
              swal({
                icon: "error",
                text: returnData.ResponseMessage,
                buton: "Ok"
              })
            }
          }
          catch (e) {
            swal({ icon: "error", text: i18next.t('notFound'), button: i18next.t("OK") });
          }
        }
        else {
          swal({ icon: "error", text: i18next.t('notFound'), button: i18next.t("OK") });
        }
      }
    }
    else {
      swal(i18next.t('passLengthError'))
    }
  }

  validate = (propname) => {
    let UpdatedDataError = { ...this.state.dataError };
    if (propname === "CLP") {
      if ((this.state.CLP === "") || (this.state.CLP === null)) {
        UpdatedDataError["CLP"] = <strong> {"*" + i18next.t('loginRequired')}</strong>;
      }
      else {
        UpdatedDataError["CLP"] = "";
      }
    }

    else if (propname === "NLP") {
      if ((this.state.NLP === "") || (this.state.NLP === null)) {
        UpdatedDataError["NLP"] = <strong>{"*" + i18next.t('newLoginRed')}</strong>;
      }
      else {
        UpdatedDataError["NLP"] = "";
      }
    }

    else if (propname === "CnLP") {
      if ((this.state.CnLP === "") || (this.state.CnLP === null)) {
        UpdatedDataError["CnLP"] = <strong>{"*" + i18next.t('confirmLoginReq')}</strong>;
      }
      else {
        UpdatedDataError["CnLP"] = "";
      }
    }

    this.setState({ dataError: UpdatedDataError });
  }


  // maxLength
  maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(0, object.target.maxLength)
    }
  }

  render() {
    return (
      <div className="full_section_dash">
        <div className="row ">
          <section className="form-data  col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="card">
              <form autoComplete="off" readonly onfocus="this.removeAttribute('readonly');" >
                <div className="h5 mb-2 card-header ">
                  <i class="fas fa-exchange"></i>
                  {i18next.t('clp')}
                </div>
                <div className="form-group row nb  ">
                  <div className="col-lg-8 col-md-8 col-sm-12 col-12">
                    <div className="form-group row nb ">

                      <label class=" col-md-6 col-sm-6 col-6  col-form-label text-left mb-2" >{i18next.t('clps')}</label>
                      <div className=" col-md-6 col-sm-6 col-6 ">
                        <div className="row ">
                          <div>
                            <input className="form-control selectwidth full_width text-left" type="password"
                              name="CLP" value={this.state.CLP} onChange={this.handleChange}
                              placeholder="" maxLength="4" onInput={this.maxLengthCheck}
                              onBlur={(propname) => this.validate("CLP")} error={this.state.dataError.CLP ? true : false}
                            />
                          </div>
                          <span style={{ color: "red", float: "left " }}>{this.state.dataError.CLP}</span>
                        </div>
                      </div>

                      <label class=" col-md-6 col-sm-6 col-6 col-form-label text-left mb-2">{i18next.t('nlp')}</label>
                      <div className=" col-md-6 col-sm-6 col-6">
                        <div className="row ">
                          <div>
                            <input className="form-control selectwidth full_width text-left" type="password"
                              name="NLP" value={this.state.NLP} onChange={this.handleChange}
                              placeholder="" maxLength="4" onInput={this.maxLengthCheck}
                              onBlur={(propname) => this.validate("NLP")} error={this.state.dataError.NLP ? true : false}
                            />
                          </div>
                          <span style={{ color: "red", float: "left " }}>{this.state.dataError.NLP}</span>
                        </div>
                      </div>

                      <label class=" col-md-6 col-sm-6 col-6 col-form-label text-left mb-2">{i18next.t('colps')}</label>
                      <div className=" col-md-6 col-sm-6 col-6">
                        <div className="row ">
                          <div>
                            <input className="form-control selectwidth full_width text-left" type="password"
                              name="CnLP" value={this.state.CnLP} onChange={this.handleChange}
                              placeholder="" maxLength="4" onInput={this.maxLengthCheck}
                              onBlur={(propname) => this.validate("CnLP")} error={this.state.dataError.CnLP ? true : false}
                            />
                          </div>
                          <span style={{ color: "red", float: "left " }}>{this.state.dataError.CnLP}</span>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="card-body">
                  <input type="button" value={i18next.t('cntie')} className="btn clickbtn mr-2" onClick={this.changeLoginPass} />
                  <input type="button" value={i18next.t('clr')} className="btn clickbtn ml-2 " onClick={this.handleClearAll} />
                </div>
              </form>
            </div>

          </section>

        </div>

      </div>
    )

  }
}



// export default LoginPin; 
function mapStateToProps(state) {
  return state
}
export default withRouter(connect(mapStateToProps)(LoginPin))




























