import React from 'react'
import * as CryptoJS from "crypto-js"

var md5 = require('md5');
function fetchHashToken (Plaintext, Tokentext){
    if (Tokentext === "" || Tokentext === null)
    {
        return  md5(Plaintext + "Fin@cus$the$digit@l$p@yment$co.$");
    }
    else
    {
        return  md5(Plaintext + Tokentext);
    }
  }
  export default fetchHashToken;
