import React, { Component } from 'react'
import Avatar from './avatar.png'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';



class PayeeAdded extends Component {

  render() {
    return (
      <div className="container">
        {/* <div class="confirm">
          <div class="img-div">
            <img src={Avatar}/>
         
          </div>
          <div class="content"><h3>New Payee Added</h3></div>
          <div class="button" />
          <Link to="/ManagePayee">
            <button class="btnss btn clickbtn">Add new payee</button>
          </Link>
          </div> */}
        </div>
    
      )
  }
}
export default PayeeAdded;