import React, { Component, Suspense } from 'react';
import Login from './Login/Login';
import { BrowserRouter as Router, Switch, Redirect, Route } from 'react-router-dom';
import NewUserRegister from './UserRegistration/NewUserRegistration'
import IdleTimer from 'react-idle-timer'
import EnvConfiguration from './Environment/EnvConfiguration';
import { connect } from 'react-redux'
import UserSideNav from './Component/UserDashboard/UserSideNav';
import Footer from './Component/Footer';
import Urnp from '../src/UserRegistration/UserRegistrationNpage';
import Lurp from '../src/UserRegistration/LastPageUserReg';
import UserDash from '../src/Component/UserDashboard/UserDashboard';
import Faq from '../src/Component/TopLayout/Faq';
import About from '../src/Component/TopLayout/About';
import './i18n';
import ErrorBoundary from './ErrorBoundary/ErrorBoundary';
import Lost from './PageNotFound';

class App extends Component {

     constructor(props) {
          super(props)

          this.state = {
               IdleTime: '',
               logout: [],
               lang: 'en',

          }
          this.idleTimer = null
          this.handleOnIdle = this.handleOnIdle.bind(this)
          this.envConfig = new EnvConfiguration();
          this.user = this.props.User
     }
     unload = async () => {
          alert("unloading");
          this.props.history.push("/ElProgreso");
          // this.props.history.push("/BoliviaIB");

     }

     // Token Expiry 

     scheduleTimer = () => {
          setTimeout(this.executeTimer, 60 * 1000 * 3);
     };

     executeTimer = () => {
          alert("The session timed out...");
          window.location = "/Login";

     };
     render() {
          if (this.state.logout.result === "00") {
               sessionStorage.clear();
               localStorage.clear();
               alert("Your session has expired due to inactivity...")
               return <Redirect to="/ElProgreso" />;

          }
          return (
               <ErrorBoundary>
                    <Suspense fallback="loading">
                         <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ padding: '0px' }}>
                              <IdleTimer
                                   ref={ref => { this.idleTimer = ref }}
                                   element={document}
                                   onIdle={this.handleOnIdle}
                                   timeout={1000 * 60 * 3} />
                              <Router basename="/ElProgreso">
                                   <Switch>
                                        <Route exact path="/">
                                             <Login timer={this.scheduleTimer} />
                                        </Route>
                                        <Route path="/Login">
                                             <Login />
                                        </Route>

                                        <Route path="/Dashboard" >
                                             <UserSideNav />
                                        </Route>
                                        <Route path="/NewDashBol">
                                             <UserSideNav />
                                        </Route>
                                        <Route path="/AccountDetails">
                                             <UserSideNav />
                                        </Route>

                                        <Route path="/Ministatement">
                                             <UserSideNav />
                                        </Route>

                                        <Route path="/BalanceStatment">
                                             <UserSideNav />
                                        </Route>
                                        <Route path="/ManagePayee">
                                             <UserSideNav />
                                        </Route>
                                        <Route path="/BoliviaBankPayee">
                                             <UserSideNav />
                                        </Route>
                                        <Route path="/OtherAddPayee">
                                             <UserSideNav />
                                        </Route>
                                        <Route path="/ViewPayee">
                                             <UserSideNav />
                                        </Route>
                                        <Route path="/ImpsViewpayee">
                                             <UserSideNav />
                                        </Route>
                                        <Route path="/FundTransUSP">
                                             <UserSideNav />
                                        </Route>
                                        <Route path="/Manual">
                                             <UserSideNav />
                                        </Route>
                                        <Route path="/WithinBank">
                                             <UserSideNav />
                                        </Route>
                                        <Route path="/OutsideBank">
                                             <UserSideNav />
                                        </Route>
                                        <Route path="/LoginPin">
                                             <UserSideNav />
                                        </Route>
                                        <Route path="/TransPin">
                                             <UserSideNav />
                                        </Route>
                                        <Route path="/WithinUsingPayee">
                                             <UserSideNav />
                                        </Route>

                                        <Route path="/LangChange">
                                             <UserSideNav />
                                        </Route>

                                        <Route path="/GenPDf">
                                             <UserSideNav />
                                        </Route>
                                        {/* updated Routing ends */}
                                        <Route path="/NewUserRegister">
                                             <NewUserRegister />
                                        </Route>
                                        <Route path="/Urnp">
                                             <Urnp />
                                        </Route>
                                        <Route path="/Lurp">
                                             <Lurp />
                                        </Route>
                                        {/* <Route path="/UserDash">
                                             <UserDash />
                                        </Route> */}

                                        {/* header */}
                                        <Route path="/Faq">
                                             <Faq />
                                        </Route>
                                        <Route path="/About">
                                             <About />
                                        </Route>
                                        <Route exact path="">
                                             <Lost />
                                        </Route>
                                        {/* <Route path="/Contact">
                                   <Contact />
                              </Route> */}
                                        {/* <Route path="" render={() => window.location = "https://www.cactri.com.bo/index.php/tconsulta/createConsulta"}>
                                   <Contact />
                              </Route>  */}
                                        <Route exact path="">
                                             <ErrorBoundary />
                                        </Route>
                                   </Switch>
                                   <Footer />
                              </Router>
                         </div>
                    </Suspense>
               </ErrorBoundary>
          );
     }

     handleOnIdle(event) {
          this.setState({
               IdleTime: this.idleTimer.isIdle()
          })
          if (this.state.IdleTime === true) {
               sessionStorage.clear();
               localStorage.clear();
               alert('Your session has expired due to inactivity...!!');
               window.location = "/ElProgreso";
               return <Redirect to="/ElProgreso" />;
               // window.location = "/BoliviaIB";
               // return <Redirect to="/BoliviaIB" />;
          }

     }
}

function mapStateToProps(state) {
     return state
}
// export default connect(mapStateTokenProps, mapDispatchToProps)(Login); 
export default connect(mapStateToProps)(App)


