import React from 'react';
import axios from 'axios';
import {trackPromise} from 'react-promise-tracker';
import EncryptString from './Encrypt';
import DecryptString from './Decrypt';
import fetchHashToken from './HashToken';

async function SessionDirectAPIReq ( ApiBody, URL){
    let token = fetchHashToken(ApiBody,"");
    const url = URL + token;
    let encryptedData = EncryptString(ApiBody,token);
    
    let decryptedAPIData;
    await trackPromise (axios.post(url,encryptedData,
    {
        headers:{
            'Content-Type':'text/plain'
        }
    }
    )).then(async response=>{
        let decryptedData = DecryptString(response.data,token)
        decryptedAPIData = JSON.parse(decryptedData);
    });
    return decryptedAPIData;
}
export default SessionDirectAPIReq;
