import React from 'react'
import CryptoJS from 'crypto-js';
import md5 from 'md5';


function wordToByteArray (wordArray){
  var byteArray = [], word, i, j;
  for (i = 0; i < wordArray.length; ++i) {
      word = wordArray[i];
      for (j = 3; j >= 0; --j) {
          byteArray.push((word >> 8 * j) & 0xFF);
      }
  }
  return byteArray;
}

function byteArrayToWordArray (ba){
  var wa = [],
      i;
  for (i = 0; i < ba.length; i++) {
      wa[(i / 4) | 0] |= ba[i] << (24 - 8 * i);
  }
  return CryptoJS.lib.WordArray.create(wa, ba.length);
}

function EncryptString  (Plaintext, TokenString)  {
  if (Plaintext === null || Plaintext === "") return "";
  if (TokenString === null) TokenString = "";
  var bytesToBeEncrypted = CryptoJS.enc.Utf8.parse(Plaintext);
  var passwordBytes = CryptoJS.enc.Utf8.parse(TokenString);
  passwordBytes = CryptoJS.SHA256(passwordBytes);
  var bytesEncrypted = EncryptBytes(bytesToBeEncrypted, passwordBytes);
  return bytesEncrypted.toString();
}

function EncryptBytes  (bytesToBeEncrypted, passwordBytes)  {
  var salt = CryptoJS.lib.WordArray.create(new Uint8Array([1, 2, 3, 4, 5, 6, 7, 8]));
  //*********** */
  // Code to generate derived Key using PBKDF2
  let Key = CryptoJS.PBKDF2(passwordBytes, salt, { iterations: 1000, keySize: 256 });
  //*********** */

  //*********** */
  //  Code To Bifercate key and iv from the derived key 
  var newKey = wordToByteArray(Key.words);
  let keydata = newKey.splice(0, 32);
  let wordArraykey = byteArrayToWordArray(keydata)

  let ivdata = newKey.splice(0, 16);
  let wordArrayiv = byteArrayToWordArray(ivdata);
  //*********** */

  //*********** */
  //  Encryption Block
  let cfg = { iv: wordArrayiv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 };
  let encrypteddata = CryptoJS.AES.encrypt(bytesToBeEncrypted, wordArraykey, cfg);
  //*********** */

  return encrypteddata;
}

export default EncryptString;

 