import React, { Component } from 'react'
import AddPayee from './AddPayee';
import Confirm from './Confirm';
// import AccountStatement from './AccountStatement';


class UserForm extends Component {
  constructor(props) {
    super(props);
      this.state = {
      step: 1, 
      PAccNo : '',
      RPAccNo :'',
      PName : '',
      PNickName : '',
          PMobile: '',
          otp: '',
          regexp: /^[0-9\b]+$/,
    }
  }
    //Next Step
    // nextStep = () => {
    //     const { step } = this.state;
    //     this.setState({
    //         step: step + 1
    //     });
    // }

    // //Prev Step

    // prevStep = () => {
    //     const { step } = this.state;
    //     this.setState({
    //         step: step - 1
    //     });
    // }

    // handle fields

    // handleChange = input => e => {
    //     if (input == "OTP") {
    //         let otpno = e.target.value;

    //         if (otpno != '') {
    //             if (this.state.regexp.test(otpno)) {
    //                 this.setState({
    //                     otp: otpno,

    //                 })
    //             }
    //         }
    //         else {
    //             this.setState({
    //                 otp: otpno,

    //             })
    //         }
    //     }
    //     else{
    //         this.setState({ [input]: e.target.value });
    //     }
    // };

    render() {
        
        const { step } = this.state;
        const { PAccNo, RPAccNo, PName, PNickName, PMobile, otp} = this.state;
        const values = { PAccNo, RPAccNo, PName, PNickName, PMobile, otp}

        // eslint-disable-next-line default-case
        switch (step) {
            case 1:
                return (
                    <AddPayee
                        nextStep={this.nextStep}
                        handleChange={this.handleChange}
                        values={values}
                    />
                    
                )
          
            case 2:
                return (
                    <Confirm />
                )
        }

    return (
      <div className="container">
        
      </div>
      )
  }
}

export default UserForm;