import React, { Component } from 'react'
import { BrowserRouter as Router, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { trackPromise } from 'react-promise-tracker';
import swal from 'sweetalert';
import i18next from 'i18next';
import SessionAPIReq from '../utils/SessionAPIReq';

// other bank payeee
class AddPayee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isloading: false,
      moduleType: [],
      data: [],
      nickName: '',
      acctNo: '',
      modTy: '',
      coop: '',
      docID: '',
      displayPayee: [],
      dataError: {
        nickName: "",
        acctNo: "",
        modTy: '',
        coop: '',
        docID: '',
      },
    }
  }

  async componentDidMount() {
    this.setState({ data: this.props.reducer.moduleType })

    this.setState({ isloading: false })
  }

  HandleChange = (e) => {
    if (e.target.name === "nickName") {
      if (isNaN(parseInt(e.nativeEvent.data))) {
        this.setState({
          [e.target.name]: e.target.value
        })
      }
      else {
        this.setState({
          [e.nativeEvent.data]: ""
        })
      }
    }

    else if (e.target.name === "docID" || e.target.name === "acctNo") {
      if (!isNaN(parseInt(e.nativeEvent.data)) || e.nativeEvent.data === null) {
        this.setState({
          [e.target.name]: e.target.value
        })
      }
      else {
        this.setState({
          [e.nativeEvent.data]: ""
        })
      }
    }
    else {
      this.setState({
        [e.target.name]: e.target.value

      })
    }
  }

  // getting cooperative list
  GetCoopList = () => {

    if (this.props.reducer.getCooperativeList !== null) {
      if (this.props.reducer.getCooperativeList.GetCooperativeList.length > 0) {

        return (

          this.props.reducer.getCooperativeList.GetCooperativeList.filter((data) => data.Cuid !== this.props.reducer.cuid)
            .map((data, index) => {
              return (
                <option key={index} value={data.Cuid} id="opt">{data.Cooperatives.toUpperCase()} </option>
              )
            })
        );
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  // getting Acct Type
  GetModuleType = () => {
    return (
      this.props.reducer.moduleType.map((data, index) => {
        return (
          <option value={data.ModuleType} id="opt" >{data.ModuleName}</option>
        )
      })
    )
  }

  // Api for adding Payee
  continueHandleChange = async (e) => {

    let UpdatedDataError = { ...this.state.dataError };
    let validation = 0;
    let Message = "";

    if ((this.state.nickName === "") || (this.state.nickName === null)) {

      UpdatedDataError.nickName = "*" + i18next.t('e_id');
      validation = validation + 1;
      Message = Message + validation + ". " + i18next.t('e_id') + "\n";
    }

    if ((this.state.acctNo === "") || (this.state.acctNo === null)) {

      UpdatedDataError.acctNo = "*" + i18next.t('benNumber');
      validation = validation + 1;
      Message = Message + validation + ". " + i18next.t('benNumber') + "\n";
    }

    if ((this.state.modTy === "") || (this.state.modTy === null)) {

      UpdatedDataError.modTy = "*" + i18next.t('modType');
      validation = validation + 1;
      Message = Message + validation + ". " + i18next.t('modType') + "\n";
    }

    if ((this.state.coop === "") || (this.state.coop === null)) {

      UpdatedDataError.coop = "*" + i18next.t('benReq');
      validation = validation + 1;
      Message = Message + validation + ". " + i18next.t('benReq') + "\n";
    }

    if ((this.state.docID === "") || (this.state.docID === null)) {

      UpdatedDataError.docID = "*" + i18next.t('CedulaIdErr');
      validation = validation + 1;
      Message = Message + validation + ". " + i18next.t('CedulaIdErr') + "\n";
    }
    if (validation > 0) {
      this.setState({ dataError: UpdatedDataError });
      swal({ icon: "error", text: i18next.t('error') + " \n\n" + Message, button: i18next.t("ok") });
    }
    else {
      const SessionData = {
        MobileNo: this.props.reducer.mobileNo,
        ChannelId: this.props.reducer.channelId,
        Cuid: this.props.reducer.bankCode
      }
      // const url =  process.env.REACT_APP_DefaultUrl + '/Payee?token={token}';
      const data = {
        Nickname: this.state.nickName,
        AccountNo: this.state.acctNo,
        ModuleType: this.state.modTy,
        Cuid: this.state.coop,
        LoginMobileNo: this.props.reducer.mobileNo,
        loginCuid: this.props.reducer.cuid,
        Channelid: this.props.reducer.channelId,
        TransactionMode: "NA",
        CedulaId: this.state.docID,
        ProcessFlag: "1",
        Successflag: "",
      };

      const APIurl = process.env.REACT_APP_DefaultUrl + 'Payee?token=TOK';

      var returnData = await trackPromise(SessionAPIReq(JSON.stringify(SessionData), JSON.stringify(data), APIurl));
      if (returnData.ResponseCode === "000") {
        const addedData = JSON.parse(returnData.Response)

        swal({
          icon: "success",
          text: addedData.processflag,
          buton: "Ok"
        })
        this.setState({
          nickName: '',
          acctNo: '',
          modTy: '',
          coop: '',
          docID: '',
          displayPayee: addedData,
        })
      }
      else if (returnData.ResponseCode === "100") {
        // swal(returnData.ResponseMessage)
        swal({
          icon: "error",
          text: returnData.ResponseMessage,
          buton: "Ok"
        })
      }
      else {
        // swal(returnData.ResponseMessage)
        swal({
          icon: "error",
          text: returnData.ResponseMessage,
          buton: "Ok"
        })
      }
    }
  }

  validate = (propname) => {

    let UpdatedDataError = { ...this.state.dataError };
    if (propname === "nickName") {
      if ((this.state.nickName === "") || (this.state.nickName === null)) {
        UpdatedDataError["nickName"] = <strong> {"*" + i18next.t('e_id')}</strong>;
      }
      else {
        UpdatedDataError["nickName"] = "";
      }
    }
    else if (propname === "acctNo") {
      if ((this.state.acctNo === "") || (this.state.acctNo === null)) {
        UpdatedDataError["acctNo"] = <strong>{"*" + i18next.t('benNumber')}</strong>;
      }
      else {
        UpdatedDataError["acctNo"] = "";
      }
    }

    else if (propname === "modTy") {
      if ((this.state.modTy === "") || (this.state.modTy === null)) {
        UpdatedDataError["modTy"] = <strong>{"*" + i18next.t('modType')}</strong>;
      }
      else {
        UpdatedDataError["modTy"] = "";
      }
    }

    else if (propname === "coop") {
      if ((this.state.coop === "") || (this.state.coop === null)) {
        UpdatedDataError["coop"] = <strong>{"*" + i18next.t('benReq')}</strong>;
      }
      else {
        UpdatedDataError["coop"] = "";
      }
    }
    else if (propname === "docID") {
      if ((this.state.docID === "") || (this.state.docID === null)) {
        UpdatedDataError["docID"] = <strong>{"*" + i18next.t('CedulaIdErr')}</strong>;
      }
      else {
        UpdatedDataError["docID"] = "";
      }
    }
    this.setState({ dataError: UpdatedDataError });
  }
  render() {

    return (
      <div className="container">
        <div className="addpayee">

          <div className=" h5 mb-4 card-header">
            <i className="fas fa-user-plus mr-2"></i>
            {i18next.t('aobp')}
          </div>

          <div className="form-data border">
            <div className="col-md-8 col-sm-12 col-12 forms">
              <form>
                <div className="form-group  row mb-2">
                  <label class=" col-md-6 col-sm-6 col-12 col-form-label text-left">{i18next.t('bfnn')}</label>
                  <div class="col-md-6 col-sm-6 col-12 ">
                    <div className="row">
                      <input type="text" autoComplete="off" class="form-control selectwidth text-left"
                        onChange={this.HandleChange} name="nickName" value={this.state.nickName}
                        onBlur={(propname) => this.validate("nickName")} error={this.state.dataError.nickName ? true : false}
                      />
                      <span style={{ color: "red", float: "left " }}>{this.state.dataError.nickName}</span>
                    </div>
                  </div>
                </div>
                <div className="form-group  row mb-2">
                  <label className=" col-md-6 col-sm-6 col-12 col-form-label text-left">{i18next.t('selectCo')}</label>
                  <div className="row col-md-6 col-sm-6 col-12">
                    <div className="row">
                      <select id="select1" className="form-control selectwidth" name="coop" value={this.state.coop}
                        onChange={this.HandleChange}
                        onBlur={(propname) => this.validate("coop")} error={this.state.dataError.coop ? true : false}
                      >
                        <option value="select">--{i18next.t('sel')}--</option>
                        {this.GetCoopList()}
                      </select>
                      <span style={{ color: "red", float: "left " }}>{this.state.dataError.coop}</span>
                    </div>
                  </div>
                </div>

                <div className="form-group  row mb-2">
                  <label className=" col-md-6 col-sm-6 col-12 col-form-label text-left">{i18next.t('aty')}</label>
                  <div className=" col-md-6 col-sm-6 col-12">
                    <div className="row">
                      <select id="select2" className="form-control selectwidth" name="modTy" value={this.state.modTy} onChange={this.HandleChange}
                        onBlur={(propname) => this.validate("modTy")} error={this.state.dataError.modTy ? true : false}
                      >
                        <option value="select">--{i18next.t('sel')}--</option>
                        {this.GetModuleType()}
                      </select>
                      <span style={{ color: "red", float: "left " }}>{this.state.dataError.modTy}</span>
                    </div>
                  </div>
                </div>

                <div className="form-group row   mb-2">
                  <label class=" col-md-6 col-sm-6 col-12 col-form-label text-left">{i18next.t('bano')}</label>
                  <div class=" col-md-6 col-sm-6 col-12">
                    <div className="row">
                      <input type="text" maxLength="15" autoComplete="off" class="form-control selectwidth text-left "
                        onChange={this.HandleChange} value={this.state.acctNo} name="acctNo"
                        onBlur={(propname) => this.validate("acctNo")} error={this.state.dataError.acctNo ? true : false}
                      />
                      <span style={{ color: "red", float: "left " }}>{this.state.dataError.acctNo}</span>
                    </div>
                  </div>
                </div>

                <div className="form-group row mb-2">
                  <label class=" col-md-6 col-sm-6 col-12 col-form-label text-left">{i18next.t('CedulaId')}</label>
                  <div class=" col-md-6 col-sm-6 col-12">
                    <div className="row">
                      <input type="text" maxLength="7" autoComplete="off" class="form-control selectwidth text-left "
                        onChange={this.HandleChange} value={this.state.docID} name="docID"
                        onBlur={(propname) => this.validate("docID")} error={this.state.dataError.docID ? true : false}
                      />
                      <span style={{ color: "red", float: "left " }}>{this.state.dataError.docID}</span>
                    </div>
                  </div>
                </div>

                <div className="btns  mt-2 mb-2">
                  <input type="button" className="btn clickbtn mr-2 " onClick={this.continueHandleChange} value={i18next.t('add')}
                  />
                  <Link to="/ManagePayee" className="btn clickbtn ">{i18next.t('back')}</Link>
                </div>
              </form>

            </div>
          </div>
        </div>
      </div>


    );
  }
}



// export default AddPayee;
function mapStateToProps(state) {
  return state
}
export default withRouter(connect(mapStateToProps)(AddPayee))
