import React, { Component } from 'react'
import BoliviaBankPayee from '../../ThirdPartyAddpayee/UserForm';
import OtherAddPayee from '../../AddPayee/ImpsUserForm';
import BalanceStatment from '../../Account_summary//BalanceStatement';
import Ministatement from '../../Account_summary/MiniStatement';
import AccountDetails from '../../Account_summary/AccountDetails'
import Manual from '../../FundTransfer/Manual';
import ManagePayee from '../../ManagePayee'
import Dashboard from '../../Dashboard';
import $, { event } from 'jquery';
import FundTransUSP from '../../FundTransfer/Form';
import Header_Common from '../Header_Common';
import { BrowserRouter as Router, Switch, Route, NavLink } from 'react-router-dom';
import MenuSearch from './MenuSearch';
import WithinBank from '../../Manage/Withinbank';
import OutsideBank from '../../Manage/Outsidebank';
import LoginPin from '../../PinChange/LoginPin';
import TransPin from '../../PinChange/TransPin';
import GenPDf from '../GenPdf';
import { connect } from 'react-redux';
import WithinUsingPayee from '../../Manage/WithinUsingPayee';
import { withRouter } from 'react-router-dom';
import i18next from 'i18next';
import Logo from '../../images/logoOne_256.jpg';
class sidenav extends Component {
     constructor(props) {
          super(props);
          this.closeNav = this.closeNav.bind(this);
          this.state = {
               current: "active"
          }
     }
     // mobile menu
     closeNav = async (e) => {

          var size = $(window).width();
          if (size < 800) { $("#wrapper").toggleClass("toggled"); }
          this.setState({
               current: ""
          })
     }

     componentDidMount = async (e) => {

          $("#menu-toggle").click(function (e) {
               e.preventDefault();
               $("#wrapper").toggleClass("toggled");
          });
          if (!this.props.reducer.mobileNo) {
               this.props.history.push("/")
          }

     }
     render() {

          return (
               <>
                    <div className='container-fluid p-0'>
                         <div className="row">
                              <div className="col-2 pr-0 shadow rounded">
                                   <div className="wrapper">

                                        {/* <!-- Sidebar --> */}
                                        <nav id="sidebar pt-0">
                                             <div className='image-head'>
                                                  <img className='h-100' src={Logo} alt="logo" />
                                             </div>
                                        </nav>

                                        {/* <!-- Page Content --> */}
                                        <MenuSearch />

                                        <div className="sidebar-nav" style={{ marginBottom: '5%' }}>

                                             <div className="sidenav-top">
                                                  <i className="far fa-window-close close" onClick={this.closeNav}></i>
                                             </div>
                                             <div className="accordion customAccordi" id="accordionExample">
                                                  {/* Dashboard */}
                                                  <div className="">
                                                       <div className="card-header bg-white" id="headingzero">
                                                            <h2 className="mb-0">
                                                                 <NavLink
                                                                      activeClassName="active"
                                                                      to="/Dashboard"
                                                                 // onClick={thisclickLink}
                                                                 >
                                                                      <button
                                                                           className='btn btn-link btn-block text-left text-decoration-none text-dark orange-btn '
                                                                           // className={this.state.current === "active" ? "btn btn-link btn-block text-left text-decoration-none text-dark firsthit acord_btn" : "btn btn-link btn-block text-left text-decoration-none text-dark acord_btn"}
                                                                           type="button" data-toggle="collapse" data-target="#collapsezero" aria-expanded="true" aria-controls="collapsezero">
                                                                           <i className="icon-011 icon pr-2"></i> {i18next.t('dshb')}
                                                                      </button>
                                                                      <div id="collapsezero" className="collapse d-none" aria-labelledby="headingzero" data-parent="#accordionExample">
                                                                      </div>
                                                                 </NavLink>
                                                            </h2>
                                                       </div>
                                                  </div>

                                                  {/* Enquiry */}
                                                  <div className="card">
                                                       <div className="card-header bg-white" id="headingOne">
                                                            <h2 className="mb-0 ">
                                                                 <button className="btn btn-link btn-block text-left accordion-button text-decoration-none text-dark acord_btn" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"><i className='icon-0111 icon pr-2'></i>
                                                                      {i18next.t('enq')}
                                                                      <i className="fas fa-chevron-down dwnarr" ></i>
                                                                 </button>
                                                            </h2>
                                                       </div>

                                                       <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                                                            <div className="card-body">

                                                                 <NavLink to="/BalanceStatment" className="d-flex p-0" activeClassName="active" onClick={this.closeNav}>

                                                                      <button className="btn btn-link btn-block text-left text-decoration-none text-dark acord_btn orange-btn"
                                                                           type="button" >
                                                                           <i className="fas fa-server pr-2"></i> {i18next.t('bs')}
                                                                      </button>
                                                                 </NavLink>
                                                                 <NavLink to="/Ministatement" activeClassName="active" className="d-flex p-0 " onClick={this.closeNav}>
                                                                      <button className="btn btn-link btn-block text-left text-decoration-none text-dark acord_btn orange-btn" type="button">
                                                                           <i className="fas fa-server pr-2"></i> {i18next.t('ms')}
                                                                      </button>
                                                                 </NavLink>

                                                                 <NavLink to="/AccountDetails" activeClassName="active" className="d-flex p-0 " onClick={this.closeNav}>
                                                                      <button className="btn btn-link btn-block text-left text-decoration-none text-dark acord_btn orange-btn" type="button">
                                                                           <i className="fas fa-server pr-2"></i> {i18next.t('ad')}
                                                                      </button>
                                                                 </NavLink>


                                                            </div>
                                                       </div>
                                                  </div>

                                                  {/* FundTransfer */}
                                                  <div className="card">
                                                       <div className="card-header bg-white" id="headingTwo">
                                                            <h2 className="mb-0">
                                                                 <button className="btn btn-link btn-block text-left collapsed text-decoration-none text-dark acord_btn" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"> <i className="icon-0142 icon pr-2 "></i>
                                                                      {i18next.t('fts')}
                                                                      <i className="fas fa-chevron-down dwnarr"></i>
                                                                 </button>
                                                            </h2>
                                                       </div>
                                                       <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                                            <div className="card-body">
                                                                 <NavLink to="/FundTransUSP" className="d-flex p-0 " onClick={this.closeNav} activeClassName="active">
                                                                      <button className="btn btn-link btn-block text-left text-decoration-none text-dark acord_btn orange-btn" type="button">
                                                                           <i className="fas fa-server pr-2"></i> {i18next.t('uspa')}
                                                                      </button>
                                                                 </NavLink>
                                                                 <NavLink to="/Manual" className="d-flex p-0 " onClick={this.closeNav} activeClassName="active">
                                                                      <button className="btn btn-link btn-block text-left text-decoration-none text-dark acord_btn orange-btn" type="button">
                                                                           <i className="fas fa-server pr-2"></i> {i18next.t('manual')}
                                                                      </button>
                                                                 </NavLink>
                                                            </div>
                                                       </div>
                                                  </div>


                                                  {/* ManagePayee */}
                                                  <div className="card">
                                                       <div className="card-header bg-white" id="headingThree">
                                                            <h2 className="mb-0">
                                                                 <NavLink 
                                                                       activeClassName="active" to="/ManagePayee" onClick={this.closeNav}>
                                                                      <button className="btn btn-link btn-block text-left text-decoration-none text-dark acord_btn orange-btn" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                                                                           <i className="icon-011 icon pr-2"></i> {i18next.t('mgpye')}
                                                                      </button>
                                                                      <div id="collapseThree" className="collapse d-none" aria-labelledby="headingThree" data-parent="#accordionExample">

                                                                      </div>
                                                                 </NavLink>
                                                            </h2>
                                                       </div>
                                                  </div>

                                                  {/* GenPDF */}
                                                  <div className="card">
                                                       <div className="card-header bg-white" id="headingFour">
                                                            <h2 className="mb-0">
                                                                 <NavLink
                                                                      activeClassName="active" to="/GenPDf" onClick={this.closeNav}>
                                                                      <button className="btn btn-link btn-block text-left text-decoration-none text-dark acord_btn orange-btn" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                                                                           <i className="icon-011 icon pr-2"></i> {i18next.t('Generate PDF')}
                                                                      </button>
                                                                      <div id="collapseFour" className="collapse d-none" aria-labelledby="headingFour" data-parent="#accordionExample">
                                                                      </div>
                                                                 </NavLink>
                                                            </h2>
                                                       </div>
                                                  </div>

                                                  {/* passwordChange */}
                                                  <div className="card">
                                                       <div className="card-header bg-white" id="headingFive">
                                                            <h2 className="mb-0">
                                                                 <button className="btn btn-link btn-block text-left collapsed text-decoration-none text-dark acord_btn" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive"> <i className="icon-017 icon pr-2 "></i>
                                                                      {i18next.t('pchng')}
                                                                      <i className="fas fa-chevron-down dwnarr"></i>
                                                                 </button>
                                                            </h2>
                                                       </div>
                                                       <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                                                            <div className="card-body">
                                                                 <NavLink to="/LoginPin" className="d-flex p-0 " onClick={this.closeNav} activeClassName="active">
                                                                      <button className="btn btn-link btn-block text-left text-decoration-none text-dark acord_btn orange-btn" type="button">
                                                                           <i className="fas fa-server pr-2"></i> {i18next.t('clogp')}
                                                                      </button>
                                                                 </NavLink>
                                                                 <NavLink to="/TransPin" className="d-flex p-0 " onClick={this.closeNav} activeClassName="active">
                                                                      <button className="btn btn-link btn-block text-left text-decoration-none text-dark acord_btn orange-btn" type="button">
                                                                           <i className="fas fa-server pr-2"></i> {i18next.t('ctnsp')}
                                                                      </button>
                                                                 </NavLink>
                                                            </div>
                                                       </div>
                                                  </div>

                                             </div>
                                        </div>
                                   </div>
                              </div>

                              <div className="col-10 pl-0 newNav ">
                                   <Header_Common />
                                   <div className="row main_Dash switchClass">
                                        <div className="p-5 container">
                                             <Switch>
                                                  <Route path="/Dashboard" component={Dashboard} />
                                                  <Route path="/BalanceStatment" component={BalanceStatment} />
                                                  <Route path="/Ministatement">
                                                       <Ministatement />
                                                  </Route>
                                                  <Route path="/AccountDetails">
                                                       <AccountDetails />
                                                  </Route>
                                                  <Route path="/ManagePayee">
                                                       <ManagePayee />
                                                  </Route>
                                                  <Route path="/BoliviaBankPayee">
                                                       <BoliviaBankPayee />
                                                  </Route>
                                                  <Route path="/OtherAddPayee">
                                                       <OtherAddPayee />
                                                  </Route>
                                                  <Route path="/FundTransUSP">
                                                       <FundTransUSP />
                                                  </Route>
                                                  <Route path="/Manual">
                                                       <Manual />
                                                  </Route>
                                                  <Route path="/WithinBank">
                                                       <WithinBank />
                                                  </Route>
                                                  <Route path="/OutsideBank">
                                                       <OutsideBank />
                                                  </Route>
                                                  <Route path="/LoginPin">
                                                       <LoginPin />
                                                  </Route>
                                                  <Route path="/TransPin">
                                                       <TransPin />
                                                  </Route>
                                                  <Route path="/WithinUsingPayee">
                                                       <WithinUsingPayee />
                                                  </Route>
                                                  <Route path="/GenPDf">
                                                       <GenPDf />
                                                  </Route>
                                             </Switch>
                                        </div>
                                   </div>


                              </div>
                         </div>
                    </div>
               </>
          )
     }
}

// export default sidenav;
function mapStateToProps(state) {
     return state
}

// export default connect(mapStateTokenProps, mapDispatchToProps)(Login); 
export default withRouter(connect(mapStateToProps)(sidenav))