import React from "react";
import { usePromiseTracker } from "react-promise-tracker";
import "./spinner.css";

export const Spinner = props => {
  const { promiseInProgress } = usePromiseTracker();

  return (
    promiseInProgress && (
      // <div className="" >
      //   <div className="loader-wrapper-shawn">
      //     <div className="loader-shawn">
      //       <div className="loader-shawn loader-inner-shawn"></div>
      //     </div>
      //   </div>
      // </div >
      <div className="fp-container spinner d-flex justify-content-center h-100 align-items-center" >
        <div className="spinner-border text-primary" style={{ width: "5rem", height: "5rem" }} role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    )
  );
};