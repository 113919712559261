import React, { Component } from 'react'
import Avatar from '../ThirdPartyAddpayee/avatar.png'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'

class PayeeAdded extends Component {

  render() {
    return (
      <div className="container">
        <div className="confirm">
          <div className="img-div">
            <img src={Avatar}/>
    		 
      	  </div>
          <div className="content">
              <h3>New Payee Added</h3>

          </div>
          <div className="button" />
          <Link to="/ManagePayee">
            {/* <button className="btnss"> */}
            <button className="btnss btn clickbtn">
                Add new payee
    	   	  </button>
             </Link>
          </div>
        </div>
      )
  }
}
export default PayeeAdded;
