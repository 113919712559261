import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { connect } from 'react-redux';
import './userDash.css';
import { withRouter } from 'react-router-dom';
import Globe from '../../ChangeLang/Globe';

function Yellow_line() {
    // let time = new Date().toLocaleString();

    return (

        <div className="yellow_line_user">
            <ul>
                <li>
                    <div className="tag_left my-2 ">


                      
                    </div>
                    <div className="tag_right my-2 ">
                        <div className="left_right">
                            {/* <p>{i18next.t('ll')}: {time}</p> */}
                            <Globe menu="Dashboard" />

                        </div>
                        {/* <div className="right_right">
                                     <Link to="/"><i className="icon-0145 icon "></i></Link>
                                 </div> */}
                    </div>
                </li>
            </ul>
        </div>
    )
}

// export default Yellow_line
const mapStateToProps = state => {
    return state
};

export default withRouter(connect(mapStateToProps)(Yellow_line))
