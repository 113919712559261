import React, { Component } from 'react';
import { trackPromise } from 'react-promise-tracker';
import i18next from 'i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import SessionAPIReq from '../utils/SessionAPIReq';
import Logo from '../images/logoOne_256.jpg';
import moment from 'moment';


export class GenPdf extends Component {
    constructor(props) {
        super(props)
        this.state = {
            stan: '',
            myList: [],
            isViewType: false
        }
    }
    async componentDidMount() {

        const SessionData = {
            MobileNo: this.props.reducer.mobileNo,
            ChannelId: this.props.reducer.channelId,
            Cuid: this.props.reducer.bankCode
        }

        const pdfData = {
            LogInMobileNumber: this.props.reducer.mobileNo,
            LogInCuid: this.props.reducer.bankCode,
            LogInChannelId: this.props.reducer.channelId,
        };

        const data = JSON.stringify(pdfData);
        const pdfUrl = process.env.REACT_APP_DefaultUrl + '/CustFetchTransactionList?token=TOK';
        let returnPdfDetails;
        returnPdfDetails = await trackPromise(SessionAPIReq(JSON.stringify(SessionData), data, pdfUrl));
        if (returnPdfDetails.ResponseCode === "000") {
            const newList = JSON.parse(returnPdfDetails.Response)
            this.setState(
                {
                    myList: newList
                });
        }
    }
    stanhandleChange = (e) => {
        this.setState({ stan: e.target.value })
    }

    GetStan = () => {

    }

    SearchStan = () => {

        // if (this.state.myList !== null) {
        //     return (
        //         this.state.myList.map(data => {
        //             return (
        //                 <option value={data.Stan} id="opt" key={data.Stan}>{data.Stan}</option>
        //             )
        //         })
        //     )
        // }
        // return null;

        return (this.props.reducer.accCallList.map((data, index) => {
            return (
                <option key={index} value={data.AcctNo} id="opt">{data.AcctNo}</option>
            )
        }))

    }

    displayDetails = () => {


        if (this.state.myList !== null) {
            let asd = this.state.stan
            this.state.myList = this.state.myList.filter(channelId);
            function channelId(chan) {
                return chan.BeneficiaryChannelId === "INET" && chan.RemitterAccNo === asd
            }

            return (
                this.state.myList.map(data => {

                    return (
                        <tr>
                            <td>{data.Stan}</td>
                            <td>{moment(data.TransactionDate).format("MM-DD-YYYY")}</td>
                            <td>{data.Amount}</td>
                            <td>{data.BeneficiaryAccNo}</td>
                            <td>{data.action}
                                <div>
                                    <input type="button" value={i18next.t('Download')} className="btn clickbtn"
                                        onClick={(event, rowdata) => this.GenPdfHandle(event, data.Stan)} />
                                </div>
                            </td>
                        </tr>
                    )
                })
            )
        }
    }

    GenPdfHandle = (e, rowdata) => {

        const doc = new jsPDF();
        let pdfdata = this.state.myList.filter(data => data.Stan === rowdata)
        doc.addImage(Logo, "png", 20, 10, 15, 15);
        doc.setFont("times", "", "bold");
        doc.text("EL Progreso E.F.V Internet banking", 70, 20);
        doc.setDrawColor(28, 95, 140);

        doc.text(i18next.t('TransactionReciept'), 105, 40, null, null, "center");
        doc.setFont("courier", "normal");
        doc.setFontSize(12);

        doc.rect(10, 50, 190, 110)
        doc.text(i18next.t('RemitterName'), 20, 60);
        doc.text(":", 110, 60)
        doc.text(this.props.reducer.welcomeUser, 120, 60);
        // doc.text(pdfdata[0].RemitterName, 105, 40);
        doc.line(10, 63, 200, 63);

        doc.text(i18next.t('RemitterMobileNo'), 20, 70);
        doc.text(":", 110, 70)
        doc.text(this.props.reducer.mobileNo, 120, 70);
        // doc.text(pdfdata[0].BeneficiaryName, 105, 40);
        doc.line(10, 73, 200, 73);

        doc.text(i18next.t('Cuenta Origen'), 20, 80);
        doc.text(":", 110, 80)
        doc.text(pdfdata[0].RemitterAccNo, 120, 80);
        doc.line(10, 83, 200, 83);

        doc.text(i18next.t('Documento de Identidad'), 20, 90);
        doc.text(":", 110, 90)
        doc.text(pdfdata[0].RemitterSSN, 120, 90);
        doc.line(10, 93, 200, 93);

        // doc.text(i18next.t('BeneficiaryName'), 20, 100);
        // // doc.text(pdfdata[0].BeneficiaryName, 105, 40);
        // doc.text(":" , 110, 100)
        // doc.text("Dilip" , 120, 100);
        // doc.line(10, 103, 200, 103);

        doc.text(i18next.t('BeneficiaryModuleType'), 20, 100);
        doc.text(":", 110, 100)
        doc.text(pdfdata[0].BeneficiaryModuleType, 120, 100);
        doc.line(10, 103, 200, 103);

        doc.text(i18next.t('Codigo Institucion Destino'), 20, 110);
        doc.text(":", 110, 110)
        doc.text(pdfdata[0].BeneficiaryCuid, 120, 110);
        doc.line(10, 113, 200, 113);

        doc.text(i18next.t('Amount'), 20, 120);
        doc.text(":", 110, 120)
        doc.text(pdfdata[0].Amount, 120, 120);
        doc.line(10, 123, 200, 123);

        doc.text(i18next.t('CurrencyCode'), 20, 130);
        doc.text(":", 110, 130)
        doc.text(pdfdata[0].CurrencyCode, 120, 130);
        doc.line(10, 133, 200, 133);

        doc.text(i18next.t('TransactionDate'), 20, 140);
        doc.text(":", 110, 140)
        let dates = moment(pdfdata[0].TransactionDate).format("MM-DD-YYYY");
        doc.text(dates, 120, 140);
        doc.line(10, 143, 200, 143);

        doc.text(i18next.t('ogr'), 20, 150);
        doc.text(":", 110, 150)
        doc.text(pdfdata[0].OrigenRecurso, 120, 150);
        doc.line(10, 153, 200, 153);

        doc.text(i18next.t('dsr'), 20, 160);
        doc.text(":", 110, 160)
        doc.text(pdfdata[0].DestinoRecurso, 120, 160);

        doc.setTextColor(28, 95, 140);
        doc.text(`Status `, 20, 173);
        doc.text(":", 110, 173)
        if (pdfdata[0].ErrorDescription !== 'Success') {
            doc.setTextColor(198, 48, 48);
            doc.text(pdfdata[0].ErrorDescription, 115, 173);
        }
        else {
            doc.setTextColor(34, 153, 84);

            doc.text(pdfdata[0].ErrorDescription, 120, 173);
        }
        doc.setTextColor(0, 0, 0);
        doc.setFont("times", "", "bold");
        doc.text(`Este es un recibo provisional, para obtener un recibo oficial, visitenos en nuestras oficinas de El Progreso`, 10, 185)
        doc.save("Reciept.pdf");
    }
    clearScreen = () => {
        this.setState({
            isViewType: false,
            stan: ''
        })
    }

    render() {
        return (
            <div className="form-data ">
                <div className="row col-lg-12 col-md-12 col-sm-12 col-12">
                    <form style={{ width: '100%' }}>
                        <div className="form-group  row nb ">
                            <div className="h5 mb-2 card-header">{i18next.t('Transaction Details')} </div>
                            <div className="row col-lg-12 col-md-12 col-sm-12 col-12 p-0" >
                                <div className="row border col-lg-6 col-md-6 col-sm-12 col-12 p-2">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12  ">
                                        <div className="row d-flex justify-content-start  ">
                                            <label className=" col-md-6 col-sm-6 col-6  col-form-label text-left">{i18next.t('accno')}:-</label>
                                            <div className=" col-md-6 col-sm-6 col-6 ">
                                                <select id="select" className="form-control selectwidth" value={this.state.stan} onChange={this.stanhandleChange}>
                                                    <option value="select">--{i18next.t('sel')}--</option>
                                                    {this.SearchStan()}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row text-center col-lg-6 col-md-6 col-sm-12 col-12 p-2">
                                    <div className="text-center col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="form-group nb ">
                                            <input type="button" className="btn clickbtn " id="btn_Submit" value={i18next.t('Search')} onClick={() => this.setState({
                                                isViewType: true
                                            }, this.displayDetails)} />
                                            <input type="button" className="btn clickbtn " id="btn_Submit" value={i18next.t('clr')} onClick={() => this.clearScreen()} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                {
                    this.state.isViewType === true ?
                        <div>
                            <table className="table  table-bordered  table-md table-striped mini-table table-responsive table-hover ">
                                <thead className="table-heading ">
                                    <tr>
                                        <th style={{ width: "25%" }}>{i18next.t('Stan')}</th>
                                        <th style={{ width: "25%" }}>{i18next.t('TransactionDate')}</th>
                                        <th style={{ width: "25%" }}>{i18next.t('Amount')}</th>
                                        <th style={{ width: "25%" }}>{i18next.t('BeneficiaryAccNo')}</th>
                                        <th style={{ width: "25%" }}>{i18next.t('actn')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.displayDetails()}
                                </tbody>
                            </table>
                        </div>
                        :
                        undefined
                }
                {/* <div>
                    <table className="table  table-bordered  table-md table-striped mini-table table-responsive table-hover ">
                        <thead className="table-heading ">
                            <tr>
                                <th style={{ width: "25%" }}>{i18next.t('Stan')}</th>
                                <th style={{ width: "25%" }}>{i18next.t('TransactionDate')}</th>
                                <th style={{ width: "25%" }}>{i18next.t('Amount')}</th>
                                <th style={{ width: "25%" }}>{i18next.t('RemitterAccNo')}</th>
                                <th style={{ width: "25%" }}>{i18next.t('actn')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.displayDetails()}
                        </tbody>
                    </table>
                </div> */}

            </div>
        )
    }
}

function mapStateToProps(state) {
    return state
}

export default withRouter(connect(mapStateToProps)(GenPdf))