import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import { trackPromise } from 'react-promise-tracker';
import swal from 'sweetalert';
import SessionAPIReq from './utils/SessionAPIReq';
import Switch from "react-switch";
class GetBalance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      totalBalance: "",
      getBalDetails: '',
      accNo: ''
    }
  }

  onAccChange = async (e) => {
    const SessionData = {
      MobileNo: this.props.reducer.mobileNo,
      ChannelId: this.props.reducer.channelId,
      Cuid: this.props.reducer.bankCode,
    }

    const a = {
      MobileNumber: this.props.reducer.mobileNo,
      CUID: this.props.reducer.cuid,
      ChannelId: this.props.reducer.channelId,
      TransactionType: "101",
      AccountNumber: this.props.acc,
      ModuleType: this.props.Mtype,
      STAN: "0",
    };

    const bsData = {
      Id: 0,
      LogInMobileNumber: this.props.reducer.mobileNo,
      LogInChannelId: this.props.reducer.channelId,
      LogInCuid: this.props.reducer.cuid,
      RequestType: "Bal",
      RequestMessage: JSON.stringify(a),
      ResponseMessage: "",
    };


    const accUrl = process.env.REACT_APP_DefaultUrl + '/ProcessMBRequest?token=TOK';
    var returnAccountDetails = await trackPromise(SessionAPIReq(JSON.stringify(SessionData), JSON.stringify(bsData), accUrl));
    if (returnAccountDetails.ResponseCode === "000") {
      if (returnAccountDetails.ResponseCode === "000") {
        const bsdetails = JSON.parse(returnAccountDetails.Response)
        this.setState({
          getBalDetails: bsdetails,
          totalBalance: bsdetails.Balance
        });
      }
      else {
        if (returnAccountDetails.ResponseCode === "E01") {
          const InnerJsonResponse = JSON.parse(returnAccountDetails.Response);
          if (InnerJsonResponse.SuccessFlag === "false") {
            // swal(InnerJsonResponse.ErrorDescription);
            swal({
              icon: "error",
              text: InnerJsonResponse.ErrorDescription,
              buton: "Ok"
            })
          }
          else {
            // swal(returnAccountDetails.Response);
            swal({
              icon: "error",
              text: returnAccountDetails.Response,
              buton: "Ok"
            })
          }
        }
        // else
        // {
        //     swal(returnAccountDetails.Response)
        // }
      }
    }
  }

  handleChange = (propBalance) => {
    this.setState({ checked: !this.state.checked })
    this.onAccChange(this.props.getBalDetails);
  };

  render() {
    return (
      <div>
        <label>
          <Switch
            checked={this.state.checked}
            onChange={this.handleChange}
            onColor="#86d3ff"
            onHandleColor="#2693e6"
            handleDiameter={18}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 1px rgba(0, 0, 0, 0.2)"
            height={15}
            width={45}
            className="react-switch"
            id="material-switch"
          />
        </label>
        {!this.state.checked ?
          <>
            <label className="ml-2 mb-0">******</label>
          </>
          :
          <>
            <label className="ml-2 mb-0">{this.state.totalBalance}</label>
            {/* <ImageLoader /> */}
          </>
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state
}
export default withRouter(connect(mapStateToProps)(GetBalance))
