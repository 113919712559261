import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import i18next from 'i18next';
import swal from 'sweetalert';
import { trackPromise } from 'react-promise-tracker';
import SessionAPIReq from '../utils/SessionAPIReq';
import Swal from 'sweetalert2';

export class TransPin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clearErr: '',
      CTP: '',
      NTP: '',
      CnTP: '',
      dataError: {
        CTP: '',
        NTP: '',
        CnTP: '',
      },
    }
  }

  handleChange = (e) => {
    // this.setState({
    //   [e.target.name]: e.target.value
    // })
    var specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (e.target.name === "CTP" || "NTP" || "CnTP") {
      if (!specialChars.test(e.nativeEvent.data) && (!isNaN(parseInt(e.nativeEvent.data)) || e.nativeEvent.data === null)) {
        this.setState({
          [e.target.name]: e.target.value
        })
      }
      else {
        this.setState({
          [e.nativeEvent.data]: ""
        })
      }
    }
    else {
      this.setState({
        [e.target.name]: e.target.value,
      })
    }

  }

  // clear
  handleClearAll = () => {
    this.setState({
      CTP: '',
      NTP: '',
      CnTP: '',
      dataError: []
    })
  }

  // api for pinchange
  changeTransPass = async (e) => {
    let UpdatedDataError = { ...this.state.dataError };
    let validation = 0;
    let Message = "";
    if ((this.state.NTP.length > 3) || (this.state.CnTP.length > 3)) {

      if ((this.state.CTP === "") || (this.state.CTP === null)) {
        UpdatedDataError.CTP = "*" + i18next.t('transReq');
        validation = validation + 1;
        Message = Message + validation + ". " + i18next.t('transReq') + "\n";
      }

      if ((this.state.NTP === "") || (this.state.NTP === null)) {
        UpdatedDataError.NTP = "*" + i18next.t('newTransReq');
        validation = validation + 1;
        Message = Message + validation + ". " + i18next.t('newTransReq') + "\n";
      }

      if ((this.state.CnTP === "") || (this.state.CnTP === null)) {
        UpdatedDataError.CnTP = "*" + i18next.t('confirmTransReq');
        validation = validation + 1;
        Message = Message + validation + ". " + i18next.t('confirmTransReq') + "\n";
      }

      if (validation > 0) {
        this.setState({ dataError: UpdatedDataError });
        swal({ icon: "error", text: i18next.t('error') + " \n\n" + Message, button: i18next.t("ok") });
      }
      else {
        // checking
        if (this.state.NTP === this.state.CnTP) {
          try {
            const SessionData = {
              MobileNo: this.props.reducer.mobileNo,
              ChannelId: this.props.reducer.channelId,
              Cuid: this.props.reducer.bankCode
            }
            // const url = process.env.REACT_APP_DefaultUrl + '/TChangePin?token={token}'; 
            const pinApidata = {
              MobileNumber: this.props.reducer.mobileNo,
              BankCode: this.props.reducer.bankCode,
              OldPin: this.state.CTP,
              NewPin: this.state.NTP,
              ChannelId: this.props.reducer.channelId,
            };

            const APIurl = process.env.REACT_APP_DefaultUrl + 'TChangePin?token=TOK';
            var returnData = await trackPromise(SessionAPIReq(JSON.stringify(SessionData), JSON.stringify(pinApidata), APIurl));
            if (returnData.ResponseCode === "000") {
              Swal.fire({
                icon: "success",
                text: i18next.t("changeTPIN"),
                button: i18next.t("ok"),
                confirmButtonColor: "#2e5f8c",
                allowOutsideClick: false,
                allowEscapeKey: false
              }).then((result) => {

                if (result.isConfirmed) {
                  this.props.history.push('/Login')
                }

              })
            }
            else if (returnData.ResponseCode === "100") {
              // swal(returnData.ResponseMessage)
              swal({
                icon: "error",
                text: returnData.ResponseMessage,
                buton: "Ok"
              })
            }
            else {
              // swal(returnData.ResponseMessage)
              swal({
                icon: "error",
                text: returnData.ResponseMessage,
                buton: "Ok"
              })
            }
          }
          catch (e) {
            swal({ icon: "error", text: i18next.t('notFound'), button: i18next.t("OK") });
          }
        }
        else {
          swal({ icon: "error", text: i18next.t('notFound'), button: i18next.t("OK") });
        }
      }
    }
    else {
      swal(i18next.t('passLengthError'))
    }
  }

  //validation 
  validate = (propname) => {
    let UpdatedDataError = { ...this.state.dataError };

    if (propname === "CTP") {
      if ((this.state.CTP === "") || (this.state.CTP === null)) {
        UpdatedDataError["CTP"] = <strong> {"*" + i18next.t('transReq')}</strong>;
      }
      else {
        UpdatedDataError["CTP"] = "";
      }
    }

    else if (propname === "NTP") {
      if ((this.state.NTP === "") || (this.state.NTP === null)) {
        UpdatedDataError["NTP"] = <strong>{"*" + i18next.t('newTransReq')}</strong>;
      }
      else {
        UpdatedDataError["NTP"] = "";
      }
    }

    else if (propname === "CnTP") {
      if ((this.state.CnTP === "") || (this.state.CnTP === null)) {
        UpdatedDataError["CnTP"] = <strong>{"*" + i18next.t('confirmTransReq')}</strong>;
      }
      else {
        UpdatedDataError["CnTP"] = "";
      }
    }

    this.setState({ dataError: UpdatedDataError });
  }

  // maxLength
  maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(0, object.target.maxLength)
    }
  }

  render() {
    return (
      <div className="full_section_dash">
        <div className="row ">
          <section className="form-data  col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="card">
              <form autoComplete="off">
                <div className="h5 mb-2 card-header ">
                  <i class="fas fa-exchange"></i>
                  {i18next.t('ctp')}
                </div>
                <div className="form-group row nb  ">
                  <div className="col-lg-8 col-md-8 col-sm-12 col-12">
                    <div className="form-group row nb ">

                      <label class=" col-md-6 col-sm-6 col-6  col-form-label text-left mb-2" >{i18next.t('cutp')}</label>
                      <div className=" col-md-6 col-sm-6 col-6 ">
                        <div className="row ">
                          <div>
                            <input className="form-control selectwidth full_width text-left" type="password"
                              name="CTP" value={this.state.CTP} onChange={this.handleChange}
                              placeholder="" maxLength="4" onInput={this.maxLengthCheck}
                              onBlur={(propname) => this.validate("CTP")} error={this.state.dataError.CTP ? true : false}
                            />
                          </div>
                          <span style={{ color: "red", float: "left " }}>{this.state.dataError.CTP}</span>
                        </div>
                      </div>

                      <label class=" col-md-6 col-sm-6 col-6 col-form-label text-left mb-2">{i18next.t('ntp')}</label>
                      <div className=" col-md-6 col-sm-6 col-6">
                        <div className="row ">
                          <div>
                            <input className="form-control selectwidth full_width text-left" type="password"
                              name="NTP" value={this.state.NTP} onChange={this.handleChange}
                              placeholder="" maxLength="4" onInput={this.maxLengthCheck}
                              onBlur={(propname) => this.validate("NTP")} error={this.state.dataError.NTP ? true : false}
                            />
                          </div>
                          <span style={{ color: "red", float: "left " }}>{this.state.dataError.NTP}</span>
                        </div>
                      </div>

                      <label class=" col-md-6 col-sm-6 col-6 col-form-label text-left mb-2">{i18next.t('ctps')}</label>
                      <div className=" col-md-6 col-sm-6 col-6">
                        <div className="row ">
                          <div>
                            <input className="form-control selectwidth full_width text-left" type="password"
                              name="CnTP" value={this.state.CnTP} onChange={this.handleChange}
                              placeholder="" maxLength="4" onInput={this.maxLengthCheck}
                              onBlur={(propname) => this.validate("CnTP")} error={this.state.dataError.CnTP ? true : false}
                            />
                          </div>
                          <span style={{ color: "red", float: "left " }}>{this.state.dataError.CnTP}</span>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="card-body">
                  <input type="button" value={i18next.t('cntie')} className="btn clickbtn mr-2" onClick={this.changeTransPass} />
                  <input type="button" value={i18next.t('clr')} onClick={this.handleClearAll} className="btn clickbtn ml-2 " />
                </div>
              </form>
            </div>
          </section>
        </div>
      </div>
    )
  }
}

// export default TransPin; 
function mapStateToProps(state) {
  return state
}
export default withRouter(connect(mapStateToProps)(TransPin))




























