import React from 'react';
import LoaderImage from '../images/Loader.gif';

const Loader = () => {
    return (
        <>
            <div className="fp-container">
                <img src={LoaderImage} className="fp-loader" alt="loading" />

            </div>

        </>
    )
}

export default Loader;
