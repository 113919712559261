import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import translationEN from '../src/locales/en/translationEN.json';
import translationES from '../src/locales/es/translationES.json';




// setting translation 
const resources ={
    
    en:{
        translation:translationEN
    },
    es:{
        translation:translationES
    }
};

i18n
.use(initReactI18next)
.init({
    resources,
    lng:'es',
    fallbackLng: "es",
    keySeparator:false,

    interpolation:{
        escapeValue:false
    }
    
});

export default i18n;

