import React, { Component } from 'react'
import axios from 'axios'
import { BrowserRouter as Redirect, Link } from 'react-router-dom';
import swal from 'sweetalert';
import "react-simple-keyboard/build/css/index.css";
import { connect } from 'react-redux';
import ImageLoader from '../Loader/Loader';
import './Style.scss';
import { userLogin, publicIp } from '../actions/actions';
import { withRouter } from 'react-router-dom';
import i18next from 'i18next';
import { trackPromise } from 'react-promise-tracker';
import SessionAPIReq from '../utils/SessionAPIReq';
import SessionDirectAPIReq from '../utils/SessionDirectAPIReq';
import LoginBg from '../images/login-bg.png';
import circleLogo from '../images/circle-logo.png';
import ClientCaptcha from "react-client-captcha";
import Globe from '../ChangeLang/Globe';
import Swal from 'sweetalert2';
import * as Validator from '../Validator/Validator';
import 'react-toastify/dist/ReactToastify.min.css';
import { toast, ToastContainer } from "react-toastify";
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flagCaptcha: "",
      username: "",
      pass: "",
      capy: "",
      login: '',
      toDashboard: false,
      layoutName: "default",
      input: "",
      captcha: '',
      vkchk: '',
      isloading: false,
      drpActionType: '0',
      LoginFirst: false,
      passbtn: false,
      user_login: '',
      ip_Add: '',
      acctList: [],
      coopList: [],
      getModType: [],
      chanID: '',
      ModuleType: '',
      MobileNo: '',
      dataError: {
        MobileNo: "",
        username: "",
        pass: "",
        capy: ""
      },
    }

    this.handleAll = this.handleAll.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.result = this.result.bind(this)
    this.handleCheck = this.handleCheck.bind(this)
    this.captchaEnter = React.createRef();
    this.account = React.createRef()
    this.password = React.createRef()
  }
  componentDidMount = async (e) => {
    this.account.current.focus();
    this.props.dispatch(userLogin("welcomeUser", ""))
    this.props.dispatch(userLogin("customerNumber", ""))
    this.props.dispatch(userLogin("mobileNo", this.state.MobileNo))
    this.props.dispatch(userLogin("Stepper", (0)))
    this.getIP()
    this.getCoopList()
    this.getModuleTypeRed()
  }

  // getIp Api
  getIP = async (e) => {
    const ipUrl = 'https://api.ipify.org';
    const ipData = '';
    await trackPromise(axios.get(ipUrl, ipData,

    )).then(response => {
      // redux
      this.props.dispatch(publicIp("ipAdd", response.data))
      this.setState({ ip_Add: response.data });
    });

  }

  // GetCooperative Api
  getCoopList = async (e) => {

    const coopData = {
      "Cuid": "",
      "ChannelId": this.props.reducer.channelId
    };

    const data = JSON.stringify(coopData);

    const CoopUrl = process.env.REACT_APP_DefaultUrl + '/GetCooperative?token=TOK';
    let returnCoopDetails;


    try {
      returnCoopDetails = await trackPromise(SessionDirectAPIReq(data, CoopUrl));
    }
    catch (e) {

    }

    if (returnCoopDetails.ResponseCode === "000") {
      const newList = JSON.parse(returnCoopDetails.Response)

      this.setState(
        {
          coopList: newList
        });
    }
    // redux
    this.props.dispatch(userLogin("getCooperativeList", this.state.coopList));
  }

  // getModuleType Api
  getModuleTypeRed = async (e) => {
    const modUrl = process.env.REACT_APP_DefaultUrl + '/getModuleType';
    const modData = "";
    await trackPromise(axios.post(modUrl, modData,
      {
        headers: {
          'Content-Type': 'text/plain',
        }
      }
    )).then(response => {
      const newType = JSON.parse(response.data.Response);

      this.setState({
        getModType: newType.ModuleList
      })

      // redux
      this.props.dispatch(userLogin("moduleType", this.state.getModType));
      this.props.dispatch(userLogin("ModuleType", this.state.ModuleType));
    });
  }

  focusOnCapcha() {
    this.captchaEnter.current.focus();
  }

  handleCheck() {
    if (this.state.vkchk === "") {
      this.setState({
        vkchk: 1,
        passbtn: true
      })
    }
    else {
      this.setState({
        vkchk: "",
        passbtn: false
      })
    }
  }

  result(text) {
    this.setState({
      captcha: text
    })
  }

  onChange = input => {
    this.setState({
      pass: input,

    });
  };

  // onChange
  handleAll = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
    this.props.dispatch(userLogin("username", this.state.MobileNo))
  }

  // password
  passHandle = (e) => {
    this.setState({ pass: e.target.value });
  }

  // acctList
  callAccountList = async () => {
    const SessionData = {
      MobileNo: this.state.MobileNo,
      ChannelId: this.props.reducer.channelId,
      Cuid: this.props.reducer.bankCode,
    }
    let accUrl = process.env.REACT_APP_DefaultUrl + 'GetAccountList?token=TOK';

    var returnAccountDetails = await trackPromise(SessionAPIReq(JSON.stringify(SessionData), JSON.stringify(SessionData), accUrl));
    if (returnAccountDetails.ResponseCode === "000") {
      const acclst = JSON.parse(returnAccountDetails.Response)
      this.setState({
        acctList: acclst.AccountList
      });
      this.props.dispatch(userLogin("accCallList", this.state.acctList));

      this.props.history.push("/Dashboard");
    }
  }

  //Onsubmit Event
  handleSubmit = async (e) => {
    e.preventDefault();
    Validator.add(this.account, i18next.t('e_id'))
    Validator.add(this.password, i18next.t('e_pss'))
    Validator.add(this.captchaEnter, i18next.t("Captcha"))
    Validator.vision()

    let asd = Validator.errorFoc()

    if (asd) {
      this.props.dispatch(userLogin("username", this.state.username))
      this.props.dispatch(userLogin("accountlist", this.state.accountlist))
      if ((this.state.capy).length > 0) {
        if (this.state.capy === this.state.captcha) {
          const SessionData = {
            MobileNo: this.state.MobileNo,
            ChannelId: this.props.reducer.channelId,
            Cuid: this.props.reducer.bankCode
          }

          const LoginApidata = {
            MobileNo: this.state.MobileNo,
            BankCode: this.props.reducer.bankCode,
            ChannelId: this.props.reducer.channelId,
            DeviceID: this.props.reducer.ipAdd,
            Imei: this.props.reducer.ipAdd,
            Pin1: this.state.pass,
            Version: "10",
          };
          const APIurl = process.env.REACT_APP_DefaultUrl + 'ValidateLogin?token=TOK';

          var returnlogindata = await trackPromise(SessionAPIReq(JSON.stringify(SessionData), JSON.stringify(LoginApidata), APIurl));
          if (returnlogindata.ResponseCode === "000") {
            var name = returnlogindata.Response;
            var welcomeName = name.split('~')
            var customerNo = name.split('~')

            this.props.dispatch(userLogin("welcomeUser", welcomeName[1]));
            this.props.dispatch(userLogin("customerNumber", customerNo[3]));
            this.props.dispatch(userLogin("mobileNo", this.state.MobileNo))

            this.callAccountList();
          }
          else if (returnlogindata.ResponseCode === "100") {
            // swal(returnlogindata.Response)
            Swal.fire({
              icon: "error",
              text: returnlogindata.Response,
              button: i18next.t("ok"),
              confirmButtonColor: "#2e5f8c",
              allowOutsideClick: false,
              allowEscapeKey: false
            }).then((result) => {

              if (result.isConfirmed) {
                this.setState({
                  MobileNo: "",
                  pass: "",
                  captcha: "",
                  capy: ""
                })
                // this.captchaEnter.value = "";
                setTimeout(() => {
                  this.account.current.focus()
                }, 100);

              }
            })
          }
          else {
            // swal(returnlogindata.ResponseMessage);
            swal({
              icon: "error",
              text: returnlogindata.ResponseMessage,
              buton: "Ok"
            })
          }
        }
        else {
          toast.error(i18next.t('Captcha does not match'), {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            closeButton: false,
          });
          this.captchaEnter.current.focus()
          // document.getElementById('captcha-err').innerHTML = "Captcha does not match"
        }
      }

    }


  }

  refresh = () => {
    this.setState({ flagCaptcha: this.state.captcha })
  };

  validate = (propname) => {
    let UpdatedDataError = { ...this.state.dataError };

    if (propname === "MobileNo") {
      if ((this.state.MobileNo === "") || (this.state.MobileNo === null)) {
        UpdatedDataError["MobileNo"] = <strong> {"*" + i18next.t('e_id')}</strong>;
      }
      else {
        UpdatedDataError["MobileNo"] = "";
      }
    }

    else if (propname === "pass") {
      if ((this.state.pass === "") || (this.state.pass === null)) {
        UpdatedDataError["pass"] = <strong>{"*" + i18next.t('e_pss')}</strong>;
      }
      else {
        UpdatedDataError["pass"] = "";
      }
    }
    this.setState({ dataError: UpdatedDataError });
  }

  maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(0, object.target.maxLength)
    }
  }
  render() {
    if (this.state.toDashboard === true) {
      return <Redirect to="/Dashboard" />
    }
    return (
      <>
        <div className="login-main login-large-scale">
          <img src={LoginBg} alt="test" className='img-fluid w-100 login-scale-img'></img>
          <div className="wrapper-main row w-100 h-100">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
              <div className="d-flex justify-content-center">
                <img src={circleLogo} alt="logo" className='login-logo-img' />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
              <div className='mainStuff my-5'>
                <div className='d-flex justify-content-end '>
                  <Globe />
                </div>
                <div className="d-flex p-2 justify-content-center align-middle">
                  <div className="login-container w-100">
                    <div className="login-wrapper">

                      <form className="m_height" autoComplete="off">
                        <div className="form-group form1">
                          <h2>{i18next.t('lgn')}</h2>
                        </div>
                        <div className="form-group form1">
                          <label>{i18next.t('u_id')}</label>

                          <input type="number" className="form-control" id="MobileNo"
                            placeholder={i18next.t('e_id')} name="MobileNo"
                            ref={this.account}
                            value={this.state.MobileNo} onChange={this.handleAll} maxLength="8" onInput={this.maxLengthCheck}
                            onBlur={(propname) => this.validate("MobileNo")}
                            // error={this.state.dataError.MobileNo ? (true).toString() : undefined}
                            autoComplete="off"
                          />
                          {/* <span style={{ color: "red", }}>{this.state.dataError.MobileNo}</span> */}
                        </div>
                        <div className="form-group form1">
                          <label>{i18next.t('pass')}</label>
                          <input type="password" className="form-control" id="pass"
                            placeholder={i18next.t('e_pss')} name="pass"
                            value={this.state.pass}
                            ref={this.password}
                            // ref={ele => this.password = ele}
                            maxLength="4"
                            onChange={this.handleAll} disabled={this.state.passbtn}
                            onBlur={(propname) => this.validate("pass")}
                            //  error={this.state.dataError.pass ? true : undefined}
                            autoComplete="off"
                          />
                          {/* <span style={{ color: "red", }}>{this.state.dataError.pass}</span> */}

                        </div>

                        <div className="form-group form1">
                          <div className="d-flex p-2 justify-content-center align-middle" style={{ background: "none" }} >
                            <ClientCaptcha width={280} fontFamily={"Cucciolo Typeface"} captchaCode={code => this.setState({
                              captcha: code
                            })} />
                          </div>
                          <label>{i18next.t('e_cp')}</label>
                          <input type='text' onChange={this.handleAll} placeholder={i18next.t('e_cp')}
                            ref={this.captchaEnter}
                            name='capy'
                            value={this.state.capy}
                            // ref={ref => this.captchaEnter = ref}
                            // eslint-disable-next-line react/jsx-no-duplicate-props
                            className="mb-1 form-control" maxLength="4" />
                          {/* <span style={{ color: "red", }}>{this.state.dataError.captcha}</span> */}

                        </div>

                        {/* <div className="form-group form1">
                          <span id="captcha-err" className="error"></span>
                        </div> */}

                        <div className="form-group form1 ml-3 mr-3">
                          {this.state.isloading === true ?
                            <ImageLoader />
                            : <Link to="/" type="button" name="submit" value="Login" className="btn log-btn" onClick={this.handleSubmit} >{i18next.t('lgn')}</Link>

                          }
                          <ToastContainer
                            position="bottom-center"
                            autoClose={false}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                          />
                        </div>
                        <div className="form-group form1 pb-4 last-anchor "  >
                          <Link style={{ fontWeight: "500" }} to="/NewUserRegister">{i18next.t('nurpass')}</Link>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

            </div>


          </div>
        </div>

        <ToastContainer />
      </>

    )
  }
}

function mapStateToProps(state) {
  return state
}

// export default connect(mapStateTokenProps, mapDispatchToProps)(Login); 
export default withRouter(connect(mapStateToProps)(Login))
