import{USER_LOGIN,PUBLIC_IP} from '../constant';



// user login
export const userLogin = (key, value) => ({
    type: USER_LOGIN,
    key:key,
    value:value,
});


// user ipadd
export const publicIp = (key, value) => ({
    type: PUBLIC_IP,
    key:key,
    value:value,
});
